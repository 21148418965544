import { CreateDrug } from './../../models/create-drug';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { DropDown, DropDownDosageForm, DropDownTwo } from './../../models/dropdown';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { RpoToolService } from '../../services/rpo-tool.service';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import * as constants from 'src/app/config/app-constants';
import { Observable, catchError, debounceTime, switchMap, of } from 'rxjs';



import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ElementRef, ViewChild } from '@angular/core';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-import-wizard-add-new-drug',
  templateUrl: './import-wizard-add-new-drug.component.html',
  styleUrls: ['./import-wizard-add-new-drug.component.scss']
})
export class ImportWizardAddNewDrugComponent implements OnInit {
  listRouteOfAdmistration: DropDown[] = [];
  filteredRouteOfAdministration: DropDown[] = [];
  listOfPackageType: DropDown[] = [];
  listOfPackageSize: DropDown[] = [];
  listOfCoating: DropDownTwo[] = [];
  listOfScoaring: DropDownTwo[] = [];
  listOfDrugStatus: DropDown[] = [];
  listOfSchedule: DropDown[] = [];
  listOfNiosh: DropDown[] = [];
  listofUnit: DropDown[] = [];
  listofManufacture: DropDown[] = [];
  createDrugModel: CreateDrug;
  listOfColor: DropDown[] = [];
  listOfClassificationShape: DropDown[] = [];
  countryList: DropDown[] = [];
  pillShapeList: DropDown[] = [];

  listOfDrugClassificationType: DropDown[] = [];
  searchText = '';
  isLoading = false;
  saveDrugForm: FormGroup;
  defaultImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHkAAAB5CAMAAAAqJH57AAAAMFBMVEX///+/v7+9vb26urrl5eXHx8fw8PDz8/PNzc3q6urCwsL5+fn8/Pzi4uLa2trW1tZO13xoAAACCUlEQVRoge3Z23aDIBAFUBlUEJH8/9/WS1UQSuMwpCvNnLd2me4KByXaNBwOh8N549iaycGthIrpsrKoGJZZ/nsZJGXghqwVZSa4IWeOuZ9Bsswyyyx/qGzHMbuBqyVbZ+Y7zkO9XB7N+muA4dVyt99gZf9a+dyowC/NsxOtrMWZ/FQ7SNYQK1tzwpAdbiVlsgnVZash/cdJRnvMfNjNRZSpA2o3rF+PeFDKjd5XVa5gtluPMImO4WXbbVcSkZtlt/17sqWUm2bSxnQuN8n9PiyJCal7xzgXQDwjpHdJdWnSdLYw7hiprGUAqKODc8eiSaGUWxAB3XlLPu4YoTwus+rRzjvlxIcJ5QcEM9oHsIgWH508fEvg1h+tDuWoY3TyMasb7S6nHF3dyeTplBZaXV0Blw1CkTyc14fRf5YiXdBrkSRK5Pl2cIxgOKugo7GO9mslcguwXyAGGUvRcIcdK5GX9QvrgPs7lEyCjhXI7TqiZqHjIqcig44VyNt5wkyrp+ALgpeP7ZBRqT4lT9rvWMF3jOc0P0HHCHaAd+J1DC0/1+ZL/I5hZeTzX49ByhYxy0u8r7xIGfvI2+sYTsaesvA6hpMn9Bss6crkoUXnmOg3fSrFMsv/RO7wqzgRd+NNmaB81Q533g7WCcsss8wyyyyzzDLLLLP8eXLNmIw89lWTkTkcDofD+SlfIsYv/LXKcNQAAAAASUVORK5CYII=';
  frontImage: string = null;
  sideImage: string = null;
  backImage: string = null;
  topImage: string = null;
  listOfDrugNames: DropDown[] = [];
  filteredDrugNames: any[] = [];

  listOfDosageForm: DropDownDosageForm[] = [];
  filteredDosageForm: DropDownDosageForm[] = [];

  // Specific descriptions we want at the top of the list (Tablet, Capsule, Gel cap)
  topDosageFormDropdownList: string[] = ['TABLET', 'CAPSULE', 'CAPSULE, GELATIN COATED'];

  listOfDrugNumberFormat: DropDown[] = [];
  filteredDrugNumberFormat: DropDown[] = [];

  packageType = new FormControl('', Validators.required);
  niosh = new FormControl();
  routeOfAdministration = new FormControl('', Validators.required);
  unit = new FormControl('', Validators.required);
  manufacturer = new FormControl('', Validators.required);
  color = new FormControl('', Validators.required);
  packageSize = new FormControl();
  dosageForm = new FormControl('', Validators.required);
  drugName = new FormControl('', Validators.required);
  genericNameIndicator = new FormControl('', Validators.required);
  drugClassificationType = new FormControl('', Validators.required)

  @ViewChild('colorInput') colorInput: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedColors: string[] = [];
  allColors: string[] = [];
  filteredColors: Observable<string[]>;



  constructor(
    private rpoService: RpoToolService,
    public dialogRef: MatDialogRef<ImportWizardAddNewDrugComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {


  }
  positiveRealNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const isValid = /^[1-9]\d*(\.\d+)?$/.test(value) || /^[1-9]\d*(\.\d+)?(\s*\/\s*[1-9]\d*(\.\d+)?)*$/.test(value);
      return isValid ? null : { positiveRealNumber: true };
    };
  }
  // Custom validator to ensure at most 2 colors are selected
  maxTwoColorsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedColors = (control.value as string || '').split(',').filter(color => color.trim() !== '');
      return selectedColors.length > 2 ? { maxColors: true } : null;
    };
  }

  // Custom validator to ensure at least 1 color is selected
  requiredColorValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedColors = (control.value as string || '').split(',').filter(color => color.trim() !== '');
      return selectedColors.length === 0 ? { colorRequired: true } : null;
    };
  }

  ngOnInit(): void {
    this.loadUnits();  // Load units on page load
    // get all dosage form data on load
    this.loadDosageFormOnInit();
    this.getColor(' ');
    this.loadRouteOfAdministrationOnLoad();
    this.getCoating();
    this.getSchedule();
    this.getClassificationShape();
    this.getScoring();
    this.getCommonDrugStatus();
    this.getNiosh();
    this.getCountryList();
    this.getpillShapeList();

    this.getDrugClassificationType();
    this.loadPackageTypeOnInit();

    // List all Drug Number Format
    this.getDrugNumberFormat();

    // route of admin value initialization by setting validator of closed suggestive search
    this.routeOfAdministration = new FormControl('', [
      Validators.required,
      this.closedSuggestiveSearchForRouteOfAdminValidator(this.listRouteOfAdmistration)
    ]),

    this.routeOfAdministration.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchTerm => {
        if (searchTerm && typeof searchTerm === 'string') { this.filteredRouteOfAdministration = this.listRouteOfAdmistration.filter(eachRouteOfAdmin => eachRouteOfAdmin.label.toString().toLowerCase().includes(searchTerm.toLowerCase())); }
      });


    this.unit = new FormControl('', [
      Validators.required,
      this.validUnitValidator(this.listofUnit)
    ]);

    this.color = new FormControl('', [
      this.requiredColorValidator(), // Check if at least one color is selected
      this.maxTwoColorsValidator()   // Ensure no more than 2 colors are selected
    ]);

    this.filteredColors = this.color.valueChanges.pipe(
      startWith(null),
      map((color: string | null) => (color ? this._filter(color) : this.allColors.slice()))
    );

    // dosage form value initialization by setting validator of closed suggestive search
    this.dosageForm = new FormControl('', [
      Validators.required,
      this.closedSuggestiveSearchForDosageFormValidator(this.listOfDosageForm)
    ]),

      // suggestive search of dosage form (using filter)
      this.dosageForm.valueChanges
        .pipe(debounceTime(300))
        .subscribe(searchTerm => {
          if (searchTerm && typeof searchTerm === 'string') { this.filteredDosageForm = this.listOfDosageForm.filter(eachDosageForm => eachDosageForm.description.toLowerCase().includes(searchTerm.toLowerCase())); }
        });


    this.manufacturer.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchTerm => {

        this.getManufacture(searchTerm);

      });

    this.drugName.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(value => {
      if (value && value.length > 2) {
        this.getDrugNames(value);
      }
      else {
        this.filteredDrugNames = []; // Clear the list if input is too short
      }
    });

    this.drugClassificationType.valueChanges
    .subscribe(value => {
      this.updateDrugIdValidators(value)
    })


    this.saveDrugForm = new FormGroup({
      drugName: this.drugName,
      genericNameIndicator: this.genericNameIndicator,
      antibiotic: new FormControl('', Validators.required),
      barcode: new FormControl('', [Validators.required, Validators.minLength(10),
      Validators.maxLength(12),
      Validators.pattern(/^\d{10,12}$/)]),
      classificationShape: new FormControl(),
      coating: new FormControl(),
      diagonal: new FormControl(),
      dosageForm: this.dosageForm,
      drugId: new FormControl('', [
        Validators.required
      ], [this.drugIdExistsValidator()]),
      drugStatus: new FormControl('', Validators.required),
      drugClassificationType: this.drugClassificationType,
      drugNumberFormat: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      pillShape: new FormControl('', Validators.required),
      imprint1: new FormControl(),
      imprint2: new FormControl(),
      length: new FormControl(),
      manufacturer: this.manufacturer,
      niosh: this.niosh,
      packageSize: this.packageSize,
      packageType: this.packageType,
      pieceWeight: new FormControl(),
      qtyClassified: new FormControl(),
      qtyWeighted: new FormControl(),
      routeOfAdministration: this.routeOfAdministration,
      schedule: new FormControl('', Validators.required),
      scoring: new FormControl(),
      storageConditions: new FormControl('', Validators.required),
      strength: new FormControl('', [Validators.required, this.positiveRealNumberValidator()]),
      color: this.color,
      thickness: new FormControl(),
      thirtyDramCapacity: new FormControl('', Validators.required),
      unit: this.unit,
      unitDose: new FormControl('', Validators.required),
      unitOfUse: new FormControl('', Validators.required),
      width: new FormControl(),
    });
  }
  percentage = 0;
  fielName = '';
  fieleSelect = false;
  backgroundcolor = '#053AB2 0% 0% no-repeat padding-box';
  selectedFile: File = null;
  responseMessage: String = '';
  responseMessageError = '';
  onFileDropped(event: any, imageNumber: number) {
    this.prepareFile(event, imageNumber);
  }

  onFileSelected(event: any, imageNumber: number) {
    this.selectedFile = <File>event.target.files[0];
    this.fielName = this.selectedFile.name;
    this.fieleSelect = true;
    this.prepareFile(event, imageNumber);
  }

  selectedFrontFileRemove() {
    this.frontImage = null;

  }
  selectedBackFileRemove() {

    this.backImage = null;
    this.fielName = '';
    this.fieleSelect = false;
  }
  selectedSideFileRemove() {

    this.sideImage = null;
    this.fielName = '';
    this.fieleSelect = false;
  }
  selectedTopFileRemove() {

    this.topImage = null;
    this.fielName = '';
    this.fieleSelect = false;
  }

  drugIdExistsValidator(): (control: AbstractControl) => Observable<ValidationErrors | null> {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      return this.rpoService.getDrugId(control.value).pipe(
        debounceTime(300), // Add debounce to reduce the number of API calls
        switchMap((response) => {
          if (response.data.statusCode === 202) {
            return of({ drugIdExists: true }); // Return the error if drug ID exists
          }
          return of(null); // Return null if no error
        }),
        catchError(() => of(null))
      );
    };
  }



  updateDrugIdValidators(classificationValueId) {
    const drugIdControl = this.saveDrugForm.controls.drugId;

    // Clear previous validators
    drugIdControl?.clearValidators();
    
    // Define a mapping of classification to required length
    const classificationLengthMap: { [key: number]: number } = {
      3: 10, // NDC 10
      4: 11, // NDC 11
      6: 10, // NDC 10
      7: 12  // NDC 12
    };

    // Get the required length based on the classification
    const requiredLength = classificationLengthMap[classificationValueId] || null;
    
    // Set validators
    if (requiredLength) {
      drugIdControl?.setValidators([
        Validators.required,
        Validators.minLength(requiredLength),
        Validators.maxLength(requiredLength)
      ]);
    } else {
      drugIdControl?.setValidators([
        Validators.required
      ]);
    }
    
    // Re-apply the async validator
    drugIdControl?.setAsyncValidators([this.drugIdExistsValidator()]);


    // Update the validity of the control
    drugIdControl?.updateValueAndValidity();
  }


  prepareFile(event: any, imageNumber: number) {
    this.fielName = event.target.files[0].name;
    this.fieleSelect = true;
    const reader = new FileReader();
    const fileExtension = this.fielName.split('.').pop()?.toLowerCase();

    if (!fileExtension || !['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      this.toastService.openToast('Invalid file format. Please select an image file (jpg, jpeg, png, gif)',
        constants.ToastPanelType.error,
        2
      );
      return;
    }
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (readerEvent: any) => {
      const splitVal = reader.result.toString().split(',');
      if (splitVal.length > 0) {

        if (imageNumber === 1) { this.frontImage = `${splitVal[1]}`; }
        else if (imageNumber === 2) { this.backImage = `${splitVal[1]}`; }
        else if (imageNumber === 3) { this.sideImage = `${splitVal[1]}`; }
        else if (imageNumber === 4) { this.topImage = `${splitVal[1]}`; }
      }
    };
  }
  getBase64ImageSrc(photo) {
    if (photo == null) { return null; }
    else {
      return 'data:image/jpeg;base64,' + photo;
    }

  }



  displayFnPacakgeType(value?: any) {
    return value ? this.listOfPackageType.find(_ => _.value === value).label : undefined;
  }

  displayFnColor(value?: any) {
    return value ? this.listOfColor.find(_ => _.value === value).label : undefined;
  }
  displayFnRoutOfAddministration(value?: any) {
    return value ? this.listRouteOfAdmistration.find(_ => _.value === value).label : undefined;
  }
  displayFnDosageForm(value?: any) {
    if (value) {
      const data = this.listOfDosageForm.find(_ => _.id === value);
      return data.description;
    }
    else { return undefined; }
  }
  validUnitValidator(validUnits: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedUnitId = control.value;
      const isValid = validUnits.some(unit => unit.value === selectedUnitId);
      return isValid ? null : { invalidUnit: true };
    };
  }
  displayFnUnit(value?: any) {
    return value ? this.listofUnit.find(_ => _.value === value).label : undefined;
  }

  displayFnGenericName(drug: any): string {
    return drug ? drug.label : '';
  }

  displayFnDrugName(drug: any): string {
    return drug ? drug.label : '';
  }

  displayFnManufacturer(value?: any) {
    return value ? this.listofManufacture.find(_ => _.value === value).label : undefined;
  }

  closedSuggestiveSearchForDosageFormValidator(options: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedDosageForm = control.value;
      const selectedOption = options.some(option => option.id === selectedDosageForm);
      return selectedOption ? null : { invalidDosageForm: true };
    };
  }

  closedSuggestiveSearchForRouteOfAdminValidator(options: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedRouteOfAdmin = control.value;
      const selectedOption = options.some(option => option.value === selectedRouteOfAdmin);
      return selectedOption ? null : { invalidRouteOfAdmin: true };
    };
  }

  closeDialog(response: boolean) {
    this.dialogRef.close({ event: 'close', data: response });
  }
  getCoating() {
    this.ngxLoader.start();
    this.rpoService
      .getCoating()
      .subscribe(
        (result) => {
          this.listOfCoating = result.data;
          this.ngxLoader.stop();

        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getClassificationShape() {
    this.ngxLoader.start();
    this.rpoService
      .getClassificationShape()
      .subscribe(
        (result) => {
          this.listOfClassificationShape = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getSchedule() {
    this.ngxLoader.start();
    this.rpoService
      .getSchedule()
      .subscribe(
        (result) => {
          this.listOfSchedule = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getColor(search) {
    this.isLoading = true;
    this.rpoService
      .getColor(search)
      .subscribe(
        (result) => {
          this.listOfColor = result.data;
          // Convert all labels to strings and populate allColors
          this.allColors = this.listOfColor.map(color => String(color.label));
          console.log(this.allColors);
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(
            error.error.message === undefined
              ? 'Something went wrong!!'
              : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }


  // Method to add a color chip when the user presses Enter or selects from the list
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Prevent adding more than 2 colors
    if (value && this.allColors.includes(value) && !this.selectedColors.includes(value)) {
      if (this.selectedColors.length < 2) {
        this.selectedColors.push(value);
      } else {
        this.toastService.openToast(
          'You can select a maximum of 2 colors only',
          constants.ToastPanelType.error,
          2
        );
      }
    }

    // Clear the input value
    event.chipInput!.clear();
    this.color.setValue(this.selectedColors.join(',')); // Update form control with selected colors
    this.color.updateValueAndValidity(); // Trigger validation update
  }

  // Method to remove a color chip
  remove(color: string): void {
    const index = this.selectedColors.indexOf(color);
    if (index >= 0) {
      this.selectedColors.splice(index, 1);
    }

    this.color.setValue(this.selectedColors.join(',')); // Update form control with selected colors
    this.color.updateValueAndValidity(); // Trigger validation update
  }

  // Method to handle selection from autocomplete dropdown
  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;

    // Prevent adding more than 2 colors
    if (!this.selectedColors.includes(value)) {
      if (this.selectedColors.length < 2) {
        this.selectedColors.push(value);
      }
    }

    this.colorInput.nativeElement.value = '';
    this.color.setValue(this.selectedColors.join(',')); // Update form control with selected colors
    this.color.updateValueAndValidity(); // Trigger validation update
  }




  getDrugId(search) {
    this.isLoading = true;
    this.rpoService
      .getDrugId(search)
      .subscribe(
        (result) => {

        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  loadUnits() {
    this.getUnit(' ');  // Pass a space or an empty string to load all units
  }
  getUnit(search) {
    this.isLoading = true;
    this.rpoService
      .getUnit(search)
      .subscribe(
        (result) => {
          this.listofUnit = result.data;
          console.log(this.listofUnit, this.unit);
          // Apply the validator with the loaded list
          this.unit.setValidators([
            Validators.required,
            this.validUnitValidator(this.listofUnit)
          ]);
          this.unit.updateValueAndValidity();

          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  getManufacture(search) {
    this.isLoading = true;
    this.rpoService
      .getManufacture(search)
      .subscribe(
        (result) => {
          this.listofManufacture = result.data;
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }


  getScoring() {
    this.ngxLoader.start();
    this.rpoService
      .getScoring()
      .subscribe(
        (result) => {
          this.listOfScoaring = result.data;
          // Set default value to the Not Score option as value
          this.saveDrugForm.controls.scoring.setValue(
            this.listOfScoaring.find(eachScoring => eachScoring.value.toString().toLowerCase() === 'not score')?.key
          );
          this.ngxLoader.stop();

        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  // get all dosage form data in dropdown on load
  loadDosageFormOnInit() {
    this.getDosageForm(' ');
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allColors.filter(color => color.toLowerCase().includes(filterValue));
  }

  private organizeDosageFormDropdownOptions(fetchedDosageForm: any[]): DropDownDosageForm[] {
    // Create a Map to store the objects by dosage form description for quick lookup
    const dosageFormDescriptionsMap = new Map<string, DropDownDosageForm>();

    // Populate the Map with the fetched data of dosage form
    fetchedDosageForm.forEach(item => {
      if (item.description) {
        dosageFormDescriptionsMap.set(item.description, item);
      }
    });

    // Create Set of top dosage form description list for efficient lookups
    const topDosageFormDescriptionsSet = new Set(this.topDosageFormDropdownList);

    // Find exact matches from topDosageFormDescriptionsSet in the fetched dosage form descriptions
    const topMatchedDosageForm = Array.from(topDosageFormDescriptionsSet).map(desc =>
      dosageFormDescriptionsMap.get(desc)
    ).filter(item => item !== undefined);

    // Find other dosage form descriptions that are not in topDosageFormDescriptionsSet
    const otherDosageForm = Array.from(dosageFormDescriptionsMap.values()).filter(item =>
      !topDosageFormDescriptionsSet.has(item.description)
    );

    // Combine top matched dosage form (among Tablet, Capsule, Gel) with other dosage form data
    return [
      ...topMatchedDosageForm,
      ...otherDosageForm
    ];
  }


  getDosageForm(search) {
    this.isLoading = true;
    this.rpoService
      .getDosageForm(search)
      .subscribe(
        (result) => {
          // Organizes dosage form dropdown options by prioritizing specific descriptions (Tablet, Capsule, Gel) at the top
          this.listOfDosageForm = this.organizeDosageFormDropdownOptions(result.data);

          this.isLoading = false;
          this.filteredDosageForm = this.listOfDosageForm;

          // add validator to check if dosage form state is present in list of dosage form data (closed suggestive search)
          this.saveDrugForm.controls.dosageForm.setValidators([
            Validators.required,
            this.closedSuggestiveSearchForDosageFormValidator(this.listOfDosageForm)
          ]);
          this.saveDrugForm.controls.dosageForm.updateValueAndValidity();
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getCommonDrugStatus() {
    this.ngxLoader.start();
    this.rpoService
      .getCommonDrugStatus()
      .subscribe(
        (result) => {
          this.listOfDrugStatus = result.data;
          this.saveDrugForm.controls.drugStatus.setValue(
            this.listOfDrugStatus.filter(eachDrugStatus => eachDrugStatus.label.toString().toLowerCase() === 'active')[0].value
          ); // Set default value to the Active option id value
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  // get all route of administration data in dropdown on load
  loadRouteOfAdministrationOnLoad() {
    this.getRouteOfAdministration(' ');
  }

  getRouteOfAdministration(searchText) {
    this.isLoading = true;
    this.rpoService
      .getRouteOfAdministration(searchText)
      .subscribe(
        (result) => {
          console.log(result.data);

          this.listRouteOfAdmistration = result.data;
          this.filteredRouteOfAdministration = this.listRouteOfAdmistration;
          this.isLoading = false;

          // add validator to check if route of admin state is present in list of route of admin data (closed suggestive search)
          this.saveDrugForm.controls.routeOfAdministration.setValidators([
            Validators.required,
            this.closedSuggestiveSearchForRouteOfAdminValidator(this.listRouteOfAdmistration)
          ]);
          this.saveDrugForm.controls.routeOfAdministration.updateValueAndValidity();

          // Set default value to the Oral option id as value
          this.saveDrugForm.controls.routeOfAdministration.setValue(
            this.listRouteOfAdmistration.filter(eachRouteOfAdmin => eachRouteOfAdmin.label.toString().toLowerCase() === 'oral')[0].value
          );
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getNiosh() {
    this.rpoService
      .getNiosh()
      .subscribe(
        (result) => {
          this.listOfNiosh = result.data;
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  loadPackageTypeOnInit() {
    this.getPackageType(' ');
  }

  getPackageType(value) {
    this.isLoading = true;
    this.rpoService
      .getPackageType(value)
      .subscribe(
        (result) => {
          this.listOfPackageType = result.data;
          // Set default value to the Bottle option as value
          this.saveDrugForm.controls.packageType.setValue(
            this.listOfPackageType.filter(eachPackageType => eachPackageType.label.toString().toLowerCase() === 'bottle')[0].value
          );
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getCountryList() {
    this.ngxLoader.start();
    this.rpoService
      .getCountryList()
      .subscribe(
        (result) => {
          this.countryList = result.data;
          // Set default value to the USA option id as value
          this.saveDrugForm.controls.country.setValue(
            this.countryList.filter(eachCountry => eachCountry.label.toString().toLowerCase() === 'usa')[0].value
          );
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getpillShapeList() {
    this.ngxLoader.start();
    this.rpoService
      .getPillShapeList()
      .subscribe(
        (result) => {
          this.pillShapeList = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  // Update the drug number format dropdown based on drug numbner type dropdown selection
  updateDrugNumberFormat(): void {
    this.filteredDrugNumberFormat = this.listOfDrugNumberFormat.filter(dnfOption => {
      switch (this.saveDrugForm.controls.drugClassificationType.value) {
        case 4: //"NDC 11"
          return dnfOption.label === '5-4-2';
        case 3: //'NDC 10'
          return ['4-4-2', '5-3-2', '5-4-1'].includes(dnfOption.label as string);
        case 6: //'UPC 10
          return dnfOption.label === '5-5';
        case 7: //'UPC 12'
          return dnfOption.label === '1-5-5-1';
        default:
          return true;
      }
    });

    // If we get filtered drug number format data and also if there is only 1 filtered drug number format data then set default data in the input field
    if (this.filteredDrugNumberFormat.length > 0 && this.filteredDrugNumberFormat.length === 1) {
      this.saveDrugForm.controls.drugNumberFormat.setValue(
        this.filteredDrugNumberFormat[0].value as string
      );
    };
  }


  getDrugClassificationType() {
    this.ngxLoader.start();
    this.rpoService
      .getDrugClassificationType()
      .subscribe(
        (result) => {
          this.listOfDrugClassificationType = result.data;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }


  getDrugNumberFormat() {
    this.ngxLoader.start();
    this.rpoService
      .getDrugNumberFormat()
      .subscribe(
        (result) => {
          this.listOfDrugNumberFormat = result.data;
          this.filteredDrugNumberFormat = this.listOfDrugNumberFormat;
          this.ngxLoader.stop();
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getDrugNames(searchTerm: string): void {
    this.isLoading = true;
    this.rpoService.getDrugName(searchTerm).subscribe(
      result => {
        this.listOfDrugNames = result.data;
        this.isLoading = false;
        this.filteredDrugNames = this.listOfDrugNames;
        this.isLoading = false;
      },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        console.error('Failed to fetch drug names:', error);
        this.toastService.openToast(
          error.error.message === undefined ? 'Something went wrong!!' : error.error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }


  save() {    
    const formValue = this.saveDrugForm.value;
    const drugName = formValue.drugName;
    let drugNameValue = typeof drugName === 'object' && drugName !== null ? drugName.label :
      typeof drugName === 'string' ? drugName : '';
    console.log(drugNameValue);
    

    // Save Generic Name data based on generic name indicator selection 
    // (if GNI -> generic (1) then drug name will be saved to generic name column)
    // (if GNI -> brand (2) then drug name will be saved to drug name column)
    const genericNameValue = (formValue.genericNameIndicator == '1') ? drugNameValue : null;
    drugNameValue = (formValue.genericNameIndicator == '2') ? drugNameValue : null

    this.createDrugModel = {
      antibiotic: formValue.antibiotic,
      barcode: formValue.barcode,
      classificationShapeId: formValue.classificationShape,
      coating: formValue.coating,
      color: formValue.color,
      diagonal: formValue.diagonal,
      dosageForm: formValue.dosageForm,
      drugId: formValue.drugId,
      drugStatus: formValue.drugStatus == null ? 0 : formValue.drugStatus,
      drugName: drugNameValue,
      genericName: genericNameValue,
      genericNameIndicator: formValue.genericNameIndicator,
      imprint1: formValue.imprint1,
      imprint2: formValue.imprint2,
      length: formValue.length,
      manufacturer: formValue.manufacturer,
      niosh: formValue.niosh,
      packageSize: formValue.packageSize,
      packageType: formValue.packageType,
      pieceWeight: formValue.pieceWeight,
      qtyClassified: formValue.qtyClassified,
      qtyWeighted: formValue.qtyWeighted,
      routeOfAdministration: formValue.routeOfAdministration,
      schedule: formValue.schedule,
      scoring: formValue.scoring,
      storageConditions: formValue.storageConditions,
      strength: formValue.strength,
      thickness: formValue.thickness,
      thirtyDramCapacity: formValue.thirtyDramCapacity,
      unit: formValue.unit,
      unitDose: formValue.unitDose,
      unitOfUse: formValue.unitOfUse,
      width: formValue.width,
      countryCodeId: formValue.country,
      pillShapeId: formValue.pillShape,
      drugNumberTypeId: formValue.drugClassificationType,
      drugNumberFormatId: formValue.drugNumberFormat,
      images: [{ serialImage: this.frontImage, viewId: 1 }, { serialImage: this.backImage, viewId: 4 }, { serialImage: this.sideImage, viewId: 2 }, { serialImage: this.topImage, viewId: 3 }]
    };
    this.ngxLoader.start();
    this.rpoService
      .saveDrug(this.createDrugModel)
      .subscribe(
        (result) => {
          this.ngxLoader.stop();
          if (result.statusCode === 200) {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.done,
              2
            );
            this.clearFormData();
          } else {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.error,
              2
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined ?
            'Something went wrong!!' : error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  clearFormData() {
    this.saveDrugForm.reset();
    this.closeDialog(true);
  }

}
