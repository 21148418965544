export class DeploymentTypeMetadata {
  name: string;
  friendlyName: string;
  description: string;
  enableByDefault: boolean;
  deploymentLevelRequiredParameters: UserInputPropertySource[];
  deploymentLevelOptionalParameters: UserInputPropertySource[];
  requiredParameters: UserInputPropertySource[];
  optionalParameters: UserInputPropertySource[];
  optionalFeatures: DeploymentTypeMetadata[];
}

export class UserInputPropertySource {
  name: string;
  friendlyName: string;
  userInputType: InputType;
  description: string;
  secret: boolean;
  defaultValue: any;
  required: boolean;
  options: string[];
  referencedByFeatures: string[];
}

export enum InputType {
  TextField = "TextField",
  PasswordField = "PasswordField",
  MultiSelect = "MultiSelect",
  KeyValuePairs = "KeyValuePairs",
  OrganizationDropdown = "OrganizationDropdown",
  SiteDropdown = "SiteDropdown",
  SystemDropdown = "SystemDropdown",
  CheckBox = "CheckBox"
}
