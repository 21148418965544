import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DeploymentTypeMetadata } from '../../models/deploymentForm.model';
import { AuthService } from 'src/app/auth/auth.service';
import { SystemFormService } from '../../services/system-form.service';

@Component({
  selector: 'app-system-form',
  templateUrl: './system-form.component.html',
  styleUrls: ['./system-form.component.scss']
})
export class SystemFormComponent implements OnInit {
  @Input() organizationId: string;
  @Input() form: FormGroup;
  @Input() systemFormMetadata: DeploymentTypeMetadata;
  @Input() recursionDepth?: number = 0;

  canEditAdvancedOptions: boolean;
  isExpanded: boolean = true;
  selectedFeatures: string[];

  get featureName(): string { return this.systemFormMetadata.friendlyName; }
  get optionalFeatures(): FormArray { return this.form.get('optionalFeatures') as FormArray; }
  get optionalFeatureNames(): string[] { return this.systemFormMetadata?.optionalFeatures?.map(feature => feature.name); }
  get isParentForm(): boolean { return this.recursionDepth === 0; }

  constructor(
    private authService: AuthService,
    private systemFormService: SystemFormService
  ) { }

  ngOnInit(): void {
    this.authService.profile$.subscribe({
      next: (profile) => {
        this.canEditAdvancedOptions = profile.hasRole("Parata Dev");
      },
      error: (err) => {
        console.error('Could not determine if current user has Parata Dev role, removing advanced options.');
        console.error(err);
        this.canEditAdvancedOptions = false;
      }
    });

    this.selectedFeatures = this.form.value.optionalFeatures?.map(feature => feature.name) || [];
  }

  onFeatureSelect(event: any) {
    if (!event.isUserInput) {
      return;
    }

    let featureName = event.source.value;
    let selected = event.source.selected;

    if (selected) {
      let featureTemplate = this.systemFormMetadata.optionalFeatures.find(feature => feature.name === featureName);
      let feature = this.form.value.optionalFeatures.find(feature => feature.name === featureName);

      if (!feature) {
        this.optionalFeatures.push(this.getFeatureFormGroup(featureTemplate));
      }
    }
    else {
      let featureIndex = this.optionalFeatures.value.findIndex(feature => feature.name === featureName);
      this.optionalFeatures.removeAt(featureIndex);
    }
  }

  getOptionalFeature(featureName) {
    return this.systemFormMetadata.optionalFeatures.find(feature => feature.name === featureName);
  }

  getFeatureFormGroup(feature: DeploymentTypeMetadata): FormGroup {
    return this.systemFormService.getSystemForm(feature);
  }
}
