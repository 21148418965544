<div [formGroup]="form" class="flex justify-stretch h-full *:flex-1" [ngSwitch]="controlMetadata.userInputType">
  <!-- Lots of duplicated label HTML here to keep *ngSwitchCase on mat-form-field. 
    Bad things happen if a mat-form-field is nested or created without a Material form input due to composition issues.-->
  <mat-form-field *ngSwitchCase="InputType.TextField" floatLabel="never"
    class="xs:basis-1/5 sm:basis-1/5 gt-sm:basis-[30%]">
    <label>
      <app-icon *ngIf="required" icon="asterisk" aria-hidden="true" size="10"></app-icon>
      {{ controlMetadata.friendlyName }}
      <custom-tooltip [text]="controlMetadata.description"></custom-tooltip>
    </label>
    <input type="text" matInput appNoHtmlTags trim [formControlName]="controlMetadata.name">
    <mat-error>
      <span *ngIf="formControl.errors?.['required']">{{ controlMetadata.friendlyName }} required. </span>
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngSwitchCase="InputType.PasswordField" floatLabel="never"
    class="xs:basis-1/5 sm:basis-1/5 gt-sm:basis-[30%]">
    <label>
      <app-icon *ngIf="required" icon="asterisk" aria-hidden="true" size="10"></app-icon>
      {{ controlMetadata.friendlyName }}
      <custom-tooltip [text]="controlMetadata.description"></custom-tooltip>
    </label>
    <input type="password" matInput appNoHtmlTags trim [formControlName]="controlMetadata.name">
    <mat-error>
      <span *ngIf="formControl.errors?.['required']">{{ controlMetadata.friendlyName }} required. </span>
    </mat-error>
  </mat-form-field>

  <div *ngSwitchCase="InputType.CheckBox" floatLabel="never" class="xs:basis-1/5 sm:basis-1/5 gt-sm:basis-[30%]">
    <mat-form-field>
      <label>
        <app-icon *ngIf="required" icon="asterisk" aria-hidden="true" size="10"></app-icon>
        {{ controlMetadata.friendlyName }}
        <custom-tooltip [text]="controlMetadata.description"></custom-tooltip>
      </label>
      <input class="!hidden" matInput>
    </mat-form-field>
    <mat-slide-toggle class="mt-3 w-full" [formControlName]="controlMetadata.name"></mat-slide-toggle>
  </div>

  <mat-form-field *ngSwitchCase="InputType.MultiSelect" floatLabel="never"
    class="xs:basis-1/5 sm:basis-1/5 gt-sm:basis-[30%]">
    <label>
      <app-icon *ngIf="required" icon="asterisk" aria-hidden="true" size="10"></app-icon>
      {{ controlMetadata.friendlyName }}
      <custom-tooltip [text]="controlMetadata.description"></custom-tooltip>
    </label>
    <mat-select [formControlName]="controlMetadata.name">
      <mat-option *ngFor="let option of controlMetadata.options" [value]="option">{{ option }}</mat-option>
    </mat-select>
    <mat-error>
      <span *ngIf="formControl.errors?.['required']">{{ controlMetadata.friendlyName }} required. </span>
    </mat-error>
  </mat-form-field>

  <app-site-select *ngSwitchCase="InputType.SiteDropdown" class="basis-1/5" [form]="form"
    [controlName]="controlMetadata.name" [organizationId]="organizationId"></app-site-select>

  <app-system-select *ngSwitchCase="InputType.SystemDropdown" class="basis-1/5" [form]="form"
    [controlName]="controlMetadata.name" [siteId]="form.get('siteId').value"></app-system-select>

  <span *ngSwitchDefault class="flex justify-center self-center italic text-red-600">{{controlMetadata.userInputType}} not
    found</span>
</div>