<mat-form-field class="flex flex-row xs:flex-1 gt-xs:basis-1/4" floatLabel="never" [formGroup]="form">
  <label (click)="$event.stopPropagation()">
    <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
    Customer Number
    <custom-tooltip text="Salesforce Customer Number"></custom-tooltip>
  </label>
  <div class="autocomplete-input flex flex-row">
    <input type="text" matInput appNoHtmlTags trim [formControlName]="controlName" [matAutocomplete]="auto">
    <button mat-button [ngStyle]="{'visibility': (siteSelectControl.value) ? 'visible' : 'hidden'}" mat-icon-button
      aria-label="" (click)="siteSelectControl.setValue('')" [disabled]="siteSelectControl.disabled">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySite.bind(this)">
    <mat-option *ngFor="let site of filteredSites$ | async" [value]="site.customerNo" matTooltip="{{site.name}}"
      matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
      {{site.name}} ({{site.customerNo}})
    </mat-option>
  </mat-autocomplete>
  <mat-error>
    <span *ngIf="siteSelectControl.errors?.['NoOptionsError']">No customer number to provision. </span>
    <span *ngIf="siteSelectControl.errors?.['required']">Customer number required. </span>
    <span *ngIf="siteSelectControl.errors?.['InvalidOptionError']">Customer number is invalid. </span>
  </mat-error>
</mat-form-field>