<app-card title="">
  <div title class="inventory_ctrl xs:flex xs:justify-start xs:content-start xs:items-start flex flex-row justify-between content-center items-center">
      <h5 class="ucase m-0 text-primaryblue xs:basis-full basis-1/2" tabindex="0">COMMON DRUG DATABASE MANAGEMENT V2.0.0</h5>

  </div>
  <section class="main-section">
      <div class="sticky_search">
          <div class="p-16 bg-white">
              <div class="flex justify-between content-center items-center xs:flex xs:flex-col sm:flex sm:flex-row md:flex md:flex-row lg:flex lg:flex-row">
                  <div  class="search-wrapper"  style="padding: 9px">
                      <div class="search-container">
                          <app-icon icon="search" class="icon_search"></app-icon>
                          <input class="search-input" inputId="searchcustomers-input" (change)="updateSearchWizard()" minLengthToSearch="1" placeholder="Scan or search by Drug Number, Drug Name or UPC" [(ngModel)]="searchText" id="search_customers">

                      </div>
                  </div>
                
                  <div class="flex justify-between content-center items-center flex-row basis-[60%]">
                   
                    <div class="basis-[37%] flex flex-row">
                      <label style="padding: 5px 8px 9px 3px; color: #6e6565;">Hide Permanent Quarantine</label>
                      <mat-form-field>
                        <mat-select style="margin-top: 0px !important;" [(ngModel)]="permanentQuarantineValue"  (selectionChange)="updateSearchWizard()" ngDefaultControl>
                          <mat-option *ngFor="let data of permanentQuarantine" [value]="data.value">{{data?.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>

                    <div class="basis-[20%] flex flex-row" style="margin-left: 1.3rem;">
                      <label  style="padding: 5px 8px 9px 3px; color: #6e6565;">Data Source</label>
                      <mat-form-field>
                        <mat-select style="margin-top: 0px !important;" [(ngModel)]="dataSourceId"  (selectionChange)="updateSearchWizard()" ngDefaultControl>
                          <mat-option *ngFor="let data of listOfDataSourceFilter" [value]="data.key">{{data?.value}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>

                    <div class="basis-[20%] flex flex-row" style="margin-left: 1.3rem;">
                      <label  style="padding: 5px 8px 9px 3px; color: #6e6565;">Country</label>
                      <mat-form-field>
                        <mat-select style="margin-top: 0px !important;" [(ngModel)]="countryId"  (selectionChange)="updateSearchWizard()" ngDefaultControl>
                          <mat-option *ngFor="let data of listOfCountry" [value]="data.key">{{data?.value}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                   
                    <button class="btn-blue basis-[20%]" (click)="openImportWizardNewDrug()">Add New Drug</button>
                    <button class="btn-danger basis-[20%]" (click)="openImportWizardFileUpload()">Advance</button>
                  </div>


              </div>
          </div>
      </div>


      <table mat-table  [dataSource]="dataSource" multiTemplateDataRows  class="mat-elevation-z8 main-table" >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef width ="30%"> Name </th>
          <td mat-cell *matCellDef="let element" width ="30%"><span style="font-weight: bold;">
            <span *ngIf="element.isExpanded">
              <mat-icon class="cross-wizard">
              <img src="/assets/img/icons/ico.16.arwup.svg" alt="">
          </mat-icon>
        </span>
        <span *ngIf="!element.isExpanded">
          <mat-icon class="cross-wizard">
          <img src="/assets/img/icons/ico.16.arwdown.svg" alt="">
      </mat-icon>
    </span>
            {{element.name}}</span> </td>
      </ng-container>
        <ng-container matColumnDef="ndc">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Drug Number </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element.ndc}} </td>
        </ng-container>
        <ng-container matColumnDef="countryCode">
          <th mat-header-cell *matHeaderCellDef width ="10%"> Country </th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element.countryCode}} </td>
          </ng-container>
          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef width ="10%"> Source  </th>
            <td mat-cell *matCellDef="let element" width ="10%"> {{element.source}} </td>
            </ng-container>
        <ng-container matColumnDef="system">
        <th mat-header-cell *matHeaderCellDef width ="10%"> System </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element.system}} </td>
        </ng-container>
        <ng-container matColumnDef="strength">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Strength </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element.strength}} </td>
        </ng-container>
        <ng-container matColumnDef="manufacturer">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Manufacturer </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element.manufacturer}} </td>
        </ng-container>
        <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef  width ="10%"> Priority </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element.priority}} </td>
        </ng-container>
        <ng-container matColumnDef="requestor">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Requestor </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element.requestor}} </td>
        </ng-container>
        <ng-container matColumnDef="age">
          <th mat-header-cell *matHeaderCellDef width ="10%">  </th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element.age}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef width ="5%">  </th>
            <td mat-cell *matCellDef="let element" width ="5%"> {{element.action}} </td>
            </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" style="padding:0px" [attr.colspan]="columnsToDisplay.length">
              <div class="row queue-element-detail" [@detailExpand]="(element.isExpanded || searchText.length>0 || this.countryId >=0) ? 'expanded' : 'collapsed'">

                <div *ngIf="element.name.split('(')[0].trim()==='SEARCH RESULTS' && (element.isExpanded || searchText.length>0 || this.countryId >=0 || this.dataSourceId >=0 )" style=" padding-bottom: .8rem;">
                  <app-search-drug-list [dataSourceId]="dataSourceId" [dataSourceId]="dataSourceId" [searchText]="searchText" [permanentQuarantine]="permanentQuarantineValue"  [countryId]="countryId"></app-search-drug-list>
                </div>
                <div *ngIf="element.name.split('(')[0].trim()==='LOW SUCCESS RATE'" style=" padding-bottom: .8rem;">
                  <span>Coming soon.... </span>
                </div>
                <div *ngIf="element.name.split('(')[0].trim()==='DRUG CLASSIFICATION REQUEST'">
                  <span>Coming soon.... </span>
                </div>
                <div *ngIf="element.name.split('(')[0].trim()==='QUARANTINED' && (element.isExpanded || searchText.length>0 || this.countryId >=0 || this.dataSourceId >=0)">
                 <app-quarantined-drug-list [dataSourceId]="dataSourceId" [searchText]="searchText" [permanentQuarantine]="permanentQuarantineValue" [countryId]="countryId"></app-quarantined-drug-list>
                </div>
              </div>
          </td>
      </ng-container>

   <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
   <tr  mat-row *matRowDef="let element; columns: columnsToDisplay;" 
   class="queue-element-row" 
   [class.queue-expanded-row]="element.isExpanded" (click)="element.isExpanded = !element.isExpanded;"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" 
   class="queue-detail-row"></tr>


      </table>
      <table class="no-result" *ngIf="dataSource.data.length==0">
        <tr>
            <td [attr.colspan]="columnsToDisplay.length" style="text-align: center; padding:1rem;">
              <span>No results found</span>
              <br>
              <a (click)="openImportWizardNewDrug()">Add New Drug ></a>
            </td>
        </tr>

    </table>

  </section>
</app-card>
