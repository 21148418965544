<!---START: Wizard First Screen-->

  <div>
    <div style="padding: 0px 15px 0px 15px;" class="flex justify-start content-stretch items-stretch flex-row">
      <div appDragDrop class="fileuploaddiv" *ngIf="!fieleSelect" (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" (change)="onFileSelected($event)" 
          title="please select only xls and csv files" accept=".xlsx, .xls, .csv" />
        <div class="flex justify-start content-center items-center flex-row">
          <div class="basis-[5%]">
            <img src="../../../../assets/img/icons/ico.24.export.svg" alt="">
          </div>
          <div class="basis-[35%]">
            <p>Drag and drop the file you would like to upload</p>
            <label for="fileDropRef">Accepted file formats are .xlsx, .xls, and .csv.</label>
          </div>
          <div style="padding: 15px 0px 0px 0px;" class="flex flex-row justify-center content-stretch items-stretch h-full basis-1/4">
            <b> OR</b>
          </div>
          <div style="padding: 12px 0px 0px 0px;" class="flex flex-row justify-end content-stretch items-stretch h-full basis-[35%]">
            <button mat-button class="btn-light btn-md btn-done m-l-16" id="OrgChoosen" type="file" 
              aria-label="Next">Browse My Computer</button>
          </div>
        </div>


      </div>


      <div appDragDrop class="fileselecteddiv flex" *ngIf="fieleSelect && percentage==0">

        <div class="selected-file flex flex-1 justify-start content-stretch items-stretch max-w-[32%]">
          <mat-icon style="padding-bottom: 11px;">
            <img src="/assets/img/icons/ico.24.document.svg" alt="" style="width: 34px; margin-top: 15px;">
          </mat-icon>
          <span class="selected-file-name">{{fielName}}</span>
          <div style="padding: 12px 0px 0px 0px;">
            <mat-icon (click)="onRemoveFile()" id="OrgChoosen">
              <img src="/assets/img/icons/ico.16.trash.svg" alt="" style="color: red;">
            </mat-icon>
          </div>

        </div>

        <div *ngIf="fileUploadButtonShow" style="padding: 12px 0px 0px 0px;" class="flex flex-1 justify-start content-stretch items-stretch max-w-[48%]">
          <button mat-button class="btn-blue btn-sm btn-done m-l-16" (click)="fileUpload()" id="OrgChoosen" type="file" 
            aria-label="Next">Upload</button>
        </div>

      </div>

      <div appDragDrop class="fileselecteddiv" *ngIf="fieleSelect && percentage==100">

        <div class="meter" *ngIf="fieleSelect && percentage>=0">
          <p class="meter-filename"><b>Uploading </b>({{fielName}})</p>
          <span [style.width]="percentage+'%'" [style.background-color]="backgroundcolor"><b 
              style="padding-right: 50%; color: white !important;">{{percentage}}%</b></span>
        </div>

      </div>
    </div>

    <form [formGroup]="drugFileInfoForm">

      <mat-dialog-content style="margin: 19px 16px 0px !important; height: 21vw;">

        <div class="drug-checkbox-group">
          <mat-radio-group formControlName="deviceId" (change)="updateTheForm($event.value)" aria-label="Select a device">
            <mat-radio-button *ngFor="let device of devices" [disabled]="this.columnNameList.length ===
            0" [value]="device.id"  [checked]="device.id == deviceId.value" class="radio-button-padding">
              {{ device.deviceName }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <h6 class="m-t-0 m-b-0">Map imported columns to [Tool Name] format</h6>

        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 1 || this.deviceId.value === 2 || this.deviceId.value === 3 || this.deviceId.value === 4">
          <span>
            <mat-icon class="cross-wizard">
              <img src="/assets/img/icons/ico.asterisk.svg" alt="">

            </mat-icon>
          </span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedDrugName">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mappedDrugName.hasError('required')">Drug Name required</mat-error>
          </mat-form-field>

          <span style="padding-left: 6px"> = Drug Name
          </span>
          <span class="help_icon" matTooltip=" This is the name the customer assigns to the drug. this is used as a reference only." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 1 || this.deviceId.value === 2 || this.deviceId.value === 3 || this.deviceId.value === 4">
          <span>
            <mat-icon class="cross-wizard">
              <img src="/assets/img/icons/ico.asterisk.svg" alt="">
            </mat-icon>
          </span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedNDC">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mappedNDC.hasError('required')">NDC required</mat-error>
          </mat-form-field>
        <span style="padding-left: 6px">= </span>
          <span style="padding-left: 6px">   
              <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select  formControlName="mappedNdcLabel">
              <mat-option *ngFor="let label of ndcLabel" [value]="label.id">{{label.title}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mappedUsage.hasError('required')">Number of required</mat-error>
          </mat-form-field>
        </span>
          <span class="help_icon" matTooltip="National Drug Code - Used to Uniquely Identify a Drug. Requirements: 11-digit drug codes are required, if the number provided is less than 11 digits a Leading zero will be added to the front of the drug number to compensate for any leading zero's removed by Excel. There is option for input of 10-Digit Hyphenated format in 4-4-2, 5-3-2 and 5-4-1, which will be converted to 11-digit based on sequence." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 1 || this.deviceId.value === 2 || this.deviceId.value === 3 || this.deviceId.value === 4">
          <span>
            <mat-icon class="cross-wizard">
              <img *ngIf="drugFileInfoForm.get('noUsage').value == false || drugFileInfoForm.get('noUsage').value == null" src="/assets/img/icons/ico.asterisk.svg" 
                alt="">
            </mat-icon>
          </span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select (selectionChange)="updateNoUsage()" formControlName="mappedUsage">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="mappedUsage.hasError('required')">Number of required</mat-error>
          </mat-form-field>

          <span style="padding-left: 6px; margin-top: 1px;">
            <mat-radio-group [disabled]="drugFileInfoForm.get('noUsage').value==true" formControlName="orderOrUsage" 
              aria-label="Number of Orders">
              <mat-radio-button [value]='1' [checked]="drugFileInfoForm.get('usageValue').value  == 2">
                Prescriptions Usage</mat-radio-button>
              <mat-radio-button style="padding-left: 6px" [checked]="drugFileInfoForm.get('usageValue').value == 1" 
                [value]="2" aria-label="Unit Usage"> Pill Usage</mat-radio-button>

            </mat-radio-group>
          </span>
          <span class="help_icon" matTooltip="This is the total number of RXs the pharmacy has filled. Requirements - numeric values only." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>


        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 1 || this.deviceId.value === 2 || this.deviceId.value === 3 || this.deviceId.value === 4">
          <span>
            <mat-icon class="cross-wizard">

            </mat-icon>
          </span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select (selectionChange)="updateNoUsage()" formControlName="mappedNoOfOrders">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>

          </mat-form-field>
          <span style="padding-left: 6px">
            <mat-radio-group [disabled]="drugFileInfoForm.get('noUsage').value==true" formControlName="usageValue" 
              aria-label="Number of Orders">
              <mat-radio-button [value]="1" [checked]="drugFileInfoForm.get('orderOrUsage').value  == 2">
                Prescriptions Usage</mat-radio-button>
              <mat-radio-button style="padding-left: 6px" [value]="2" aria-label="Unit Usage" 
                [checked]="drugFileInfoForm.get('orderOrUsage').value  == 1"> Pill
                Usage</mat-radio-button>

            </mat-radio-group></span>
          <span class="help_icon" matTooltip="This is the total number of Pills the pharmacy has dispensed. Requirements - numeric values only" matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 1 || this.deviceId.value === 4">
          <span>
            <mat-icon class="cross-wizard">

            </mat-icon>
          </span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedMnemonic" (selectionChange)="isSelecteMappedMnomonic()">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug?.name">{{drug?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span style="padding-left: 6px"> = Customer Mnemonic </span>
          <span class="help_icon" matTooltip="a unique number for a drug assigned by a specific customer." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        

        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 4">
          <span>
            <mat-icon class="cross-wizard">

            </mat-icon>
          </span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedExternalDrugFlag">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug?.name">{{drug?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span style="padding-left: 6px"> = External Drug Flag </span>
          <span class="help_icon" matTooltip="This Field is used to define drugs that should be considered for use outside a Counting Device. 
          a Y or Yes in this column tells the PM tool you do not want the drug to be in a container it will instead be placed in a tray.  
          a X in this field tells the PM tool that this drug should not be placed in the device at all. 
          " matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 1 ">
          <span>
            <mat-icon class="cross-wizard">
              <img *ngIf="drugFileInfoForm.get('noUsage').value == true" src="/assets/img/icons/ico.asterisk.svg" 
                alt="">
            </mat-icon>
          </span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select [disabled]="drugFileInfoForm.get('noUsage').value==false" 
              formControlName="mappedSuperCellSelect">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug?.name">{{drug?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <span style="padding-left: 6px"> = Super Cell Select </span>
          <span class="help_icon" matTooltip="This option is for no usage Max implementations when the user manually assigns super cells to specific locations in the Max. • Requirements - create a new column with a Y next to any drug the user wants a super cell for." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 1">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedLockingCell">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Locking Cell Select</span>
          <span class="help_icon" matTooltip="This option is for no usage Max implementations when the user manually assigns locking cells to specific regular and super locations in the Max. • Requirements - create a new column with a Y next to any drug the user wants a locking cell for." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 1 || this.deviceId.value === 2 || this.deviceId.value === 3 || this.deviceId.value === 4">
          <span>
            <mat-icon class="cross-wizard">
              <img *ngIf="drugFileInfoForm.get('noUsage').value == true" src="/assets/img/icons/ico.asterisk.svg" 
                alt="">
            </mat-icon>
          </span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedRanking">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>

          </mat-form-field>

          <span style="padding-left: 6px"> = Ranking</span>
          <span class="help_icon" matTooltip="this field is for Pharmacies that have no usage information to determine what their most preferred drugs are. • Requirements - • the user must add and map a column. • with the Ranking of each drug the most preferred marked with 1 ascending to the least preferred." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3 || this.deviceId.value === 4">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpLot">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>

          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Lot#</span>
          <span class="help_icon" matTooltip="an Inventory control number assigned by the customer. This will be exported directly to the appropriate Documents." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3">

          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpDrugExpirationDate">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Drug Expiration Date</span>
          <span class="help_icon" matTooltip="a drug use by date assigned by the customer. This will be exported directly to the appropriate Documents.Format: MM/DD/YYYY" matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3 || this.deviceId.value == 4">

          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerQuantity">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Quantity</span>
          <span class="help_icon" matTooltip="The Number of Pills the Customer has loaded into the Canisters." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpDeviceLocation">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Trade In Device Location</span>
          <span class="help_icon" matTooltip="This number assigns a drug directly to a specific location inside a device. This will be exported directly to the appropriate Documents." matTooltipPosition="after"
             matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3 || this.deviceId.value === 4">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpPillDescription">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Description</span>
          <span class="help_icon" matTooltip="a customer assigned pill description, This will be exported directly to the appropriate Documents." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpPillColor">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Color</span>
          <span class="help_icon" matTooltip="A customer provided Drug color - This will be exported directly to the appropriate Documents. " matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3 || this.deviceId.value === 4">

          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>
          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpPillShape">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Shape</span>
          <span class="help_icon" matTooltip="A customer provided Pill Shape - This will be exported directly to the appropriate Documents. " matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpImp1">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>

          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Imprint 1</span>
          <span class="help_icon" matTooltip="A customer provided Imprint - This will be exported directly to the appropriate Documents." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedAtpImp2">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Pill Imprint 2</span>
          <span class="help_icon" matTooltip="A customer provided Imprint - This will be exported directly to the appropriate Documents." matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>
        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerBarcode">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> =Customer Barcode</span>
          <span class="help_icon" matTooltip="A customer provided Barcode to uniquely Identify a Drug  - This will be exported directly to the appropriate fields & Documents. " matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>


        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 1 || this.deviceId.value === 3 || this.deviceId.value === 4">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerOtc">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer OTC</span>
          <span class="help_icon" matTooltip="a customer assigned OTC drug. • Requirements - create a new column with a Y next to any drug the user wants to assign as OTC drug." matTooltipPosition="after" matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerComment">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Comment</span>
          <span class="help_icon" matTooltip="A customer provided Comment - This will be exported directly to the appropriate Documents. " matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>

        <div class="flex justify-start content-center items-center flex-row" *ngIf="this.deviceId.value === 3">
          <span> <mat-icon class="cross-wizard">

            </mat-icon></span>

          <mat-form-field floatLabel="never" class="sm:basis-1/4 basis-1/4">
            <mat-select formControlName="mappedCustomerInventoryNumber">
              <mat-option *ngFor="let drug of columnNameList" [value]="drug.name">{{drug.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="padding-left: 6px"> = Customer Inventory Number</span>
          <span class="help_icon" matTooltip="A customer provided Inventory Number - This will be exported directly to the appropriate fields & Documents. " matTooltipPosition="after" 
            matTooltipClass="custom_tooltip">
            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
          </span>
        </div>



      </mat-dialog-content>

      <mat-card *ngIf="errorMessages.length>0" style="height: 150px;">
        <h5 style="margin: 10px 25px 1px 37px;">
          <div class="flex justify-start content-center items-center flex-row">
            <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
              Error Messages
            </div>
            <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
              <mat-icon class="cross-wizard error-icon flex flex-row justify-end content-end items-end h-full" 
                (click)="this.errorMessages=[]" aria-hidden="true">cancel</mat-icon>
            </div>
          </div>

        </h5>
        <mat-card-content style="text-align: center; height: 120px; overflow: scroll;">

          <mat-error *ngFor="let item of errorMessages">
            <span *ngIf="item.length>0">{{ item }}</span>
          </mat-error>

        </mat-card-content>
      </mat-card>

      <mat-dialog-actions class="flex justify-end content-end items-end flex-row">
        <button mat-button class="btn-danger  btn-md" (click)="drugFileInfoForm.reset(); valueSetAfterResetForm()" 
          id="Reset" type="button" aria-label="Reset">Reset All</button>
        <button mat-button class="btn-primary  btn-md" (click)="closeWizard.emit(true)" id="Cancel" 
          type="button" aria-label="Cancel">Cancel</button>
        <button mat-button [ngClass]="{'btn-blue':drugFileInfoForm.valid, 'btn-disabled':!drugFileInfoForm.valid}" 
          [disabled]="!drugFileInfoForm.valid" class=" btn-md btn-done m-l-16" (click)="customerAndFileInfoSave()" type="button" 
          aria-label="Next">Next</button>
      </mat-dialog-actions>
    </form>


  </div>




<!---END: Wizard First Screen-->


