import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CustomerInfo } from '../../models/customer-info';
import { ProjectStatus, TeamAssist } from '../../models/org-status';
import { IMToolService } from '../../services/im-tool.service';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
import * as constants from 'src/app/config/app-constants';
import { Router } from '@angular/router';
import { ImportWizardComponent } from '../import-wizard/import-wizard.component';
import { FormControl } from '@angular/forms';
import { DropDownTwo } from 'src/app/internal-user/drug-management/models/dropdown';

@Component({
  selector: "app-pm-customer-management",
  templateUrl: "./pm-customer-management.component.html",
  styleUrls: ["./pm-customer-management.component.scss"],
})
export class PmCustomerManagementComponent implements OnInit {
  customers: CustomerInfo[] = [];
  dataSource = new MatTableDataSource<CustomerInfo>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;



  displayedColumns: string[] = [
    "sl",
    "customerName",
    "caseNumber",
    "customerNumber",
    "subDeviceTypeName",
    "lastEdited",
    "statusDescription",
    "targetInstallDate",
    "action",
  ];
  projectStatus: ProjectStatus[] = [];
  teamAssists: TeamAssist[] = [];
  customerData: CustomerInfo[] = [];
  salesforceStatusList:DropDownTwo[]= [];
  salesforceStatus:FormControl = new FormControl('');
  selected: string = "1";
  selectedProjectStatus: number = 0;
  selectedTeamAssists: number = 1;

  //#region Pagination properties
  totalRows: number = 0;
  currentPage: number = 0;
  pageSize: number = 50;
  pageSizeOptions: number[] = [50, 100, 200, 500];

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getCustomerData();
  }
  //#endregion

  //#region Sorting Properties
    sortDir:string="asc";
    sortColumnName:string="";
    @HostListener("matSortChange", ["$event"])
    sortChange(event) {
      this.sortColumnName = event.active;
      this.sortDir = event.direction;
      this.getCustomerData();
    }
    searchText: string="";
    statusId:number=0;
  //#endregion


  // Selected Row Color Change key
  selectedRow;

  @HostListener("window:resize")
  onResize() {
    if (window.innerWidth > 900) {
    }
  }
  constructor(
    private dialog: MatDialog,
    private imtoolService: IMToolService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.getCustomerData();
    this.getFileStatusData();
    this.getSalesForceStatus();
    this.teamAssists.push({
      id: 1,
      value: "Your Worklist",
    });


  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  getCustomerData() {
    const CUSTOMERDATA_KEY = 'Customerdatakey';
    this.ngxLoader.start(CUSTOMERDATA_KEY);
    this.imtoolService
      .getCustomerData(
        this.searchText,
        this.currentPage == 0 ? 1 : this.currentPage,
        this.pageSize,
        this.sortColumnName,
        this.sortDir,
        this.statusId,
        this.salesforceStatus.value.toString()
      )
      .subscribe(
        (result) => {
          let response = result.data;
          if(response == null){
            this.ngxLoader.stop(CUSTOMERDATA_KEY);
            this.toastService.openToast(
              response.message,
              constants.ToastPanelType.error,
              2
            );
            return
            } ;
          this.customers = [];
          if(response.data != null || response.data != undefined){
            response?.data.forEach((x) =>
            this.customers.push({
              sl:x.sl,
              customerId: x.id,
              customerName: x.customerName,
              caseNumber: x.caseNumber,
              caseDetailsLink: x.caseDetailsLink??"",
              customerNumber: x.customerNumber,
              deviceType: x.deviceType,
              lastEdited: x.modifiedDate,
              deviceId:x.deviceId,
              status: {
                id: x.status.id == undefined || x.status.id  == null ? 0: x.status.id,
                value: x.status.description == undefined  || x.status.description  == null? '': x.status.description,
              },
              subDeviceTypeName:
                x.deviceTypeName == null ? "" : x.deviceTypeName,
              statusDescription: x.status.description,
              targetInstallDate: x.targetInstallDate,
            })
          );

          }
          this.dataSource.data = this.customers;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = response.totalRows;
          },0);
          this.ngxLoader.stop(CUSTOMERDATA_KEY);
        },

        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(CUSTOMERDATA_KEY);
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getFileStatusData(){
    const FILESTATUS_KEY = 'Filestatuskey'
    this.ngxLoader.start(FILESTATUS_KEY);
    this.imtoolService.getfileStatus().subscribe((result)=>{
    this.projectStatus = result.data;
    this.ngxLoader.stop(FILESTATUS_KEY);
    },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(FILESTATUS_KEY);
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        })
  }


  getSalesForceStatus(){
    const SALESFORCESTATUS_KEY = 'Salesforcesstatuskey'
    this.ngxLoader.start(SALESFORCESTATUS_KEY);
    this.imtoolService.getSalesForceStatus().subscribe((result)=>{
    this.salesforceStatusList = result.data;
    this.ngxLoader.stop(SALESFORCESTATUS_KEY);
    },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(SALESFORCESTATUS_KEY);
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        })
  }
  public doFilter = (value: any, isTextSearch:boolean) => {
    if(isTextSearch)
    {
     this.searchText = value;
    }
    else{
    this.statusId = value;
    }
    this.getCustomerData();
  };

  getRow(row: any) {
    this.customerData.push(row);
  }

  openAlertWindow(row: any) {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Warning",
        bodyMessage: "Are you sure you want to remove?",
        bodyMessage2: "",
        cancelBtnText: "Go Back",
        confirmBtnText: "Continue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.customerRemove(row.customerId);
      } else {
        console.log("Cancle", result);
      }
    });
  }

  customerRemove(customerId: string) {
    const CUSTOMERREMOVE_KEY = 'Customeremovekey'
    this.ngxLoader.start(CUSTOMERREMOVE_KEY);
    this.imtoolService.customerRemove(customerId).subscribe(
      (result) => {
        this.ngxLoader.stop(CUSTOMERREMOVE_KEY);
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );
          this.getCustomerData();
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
            this.ngxLoader.stop(CUSTOMERREMOVE_KEY)
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(CUSTOMERREMOVE_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }
  openAddSite(row: any) {
    const dialogSiteRef = this.dialog.open(ImportWizardComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: { customer: row },
    });
    dialogSiteRef.afterClosed().subscribe((result) => {
      this.getCustomerData();
    });
  }
  redirectPageBasedOnStatus(row: CustomerInfo) {
    if (row.status.id === 3 || row.status.id===5 || row.status.id === 4) {
      if(row?.deviceId===3){
        this.route.navigate(["/im-tool/atp-export", row.customerId]);
      }
      else if(row?.deviceId===2){
        this.route.navigate(["/im-tool/pass-export", row.customerId]);
      }
      else if(row?.deviceId===4){
        this.route.navigate(["/im-tool/synmed-export", row.customerId]);
      }
      else{
        this.route.navigate(["/im-tool/export", row.customerId]);
      }

    } else if (row.status.id === 1 || row.status.id === 2) {
      this.openAddSite(row);
    }
  }




}
