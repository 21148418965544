<app-card title="">
    <div title class="flex justify-between content-start items-start">

            <mat-icon (click)="redirectCustomerList()" style="margin-bottom: 6px;">
                <img src="/assets/img/icons/ico.16.arwlft.svg" alt="">
            </mat-icon>
            <h5 style="padding-top: 5px;" class="ucase m-0 text-primaryblue xs:basis-full basis-[81%]" tabindex="0">{{customerName}} <span *ngIf="subDeviceTypeName !== null">({{subDeviceTypeName}}  {{targetInstallDate}})</span></h5>
            <div class="flex justify-between content-center items-center flex-row">
                <mat-icon (click)="onAllUserPaginateChange()" style="padding-right: 14px; ">
                    <img style="padding: 4px;" src="/assets/img/icons/ico.24.scrolltotop.svg" alt="">
                </mat-icon>
            </div>
            <div class="basis-[9%]">
              <button id="btn_cddb_add" (click)="openImportWizardCDDB()" class="btn-light btn-sm add_site">
                CDDB Add
                </button>
            </div>
        <div class="basis-[11%]">
            <button id="btn_change_device" class="bg-red btn-sm" (click)="openImportWizard()" >
                Change Device
            </button>
        </div>

            <mat-form-field floatLabel="never" class="basis-[11%]">
                <mat-select style="margin-top: 0rem !important;" matInput [value]="0"  (selectionChange)="downloadFile($event.value)">
                    <mat-option [disabled]="true"  [value]="0" style="text-align: center; background-color: rgb(202 198 198);"><span style="font-weight: bolder;">Export</span></mat-option>
                    <mat-option  [value]="1">Customer Drug XLSX</mat-option>
                    <mat-option  [value]="2">Canister Order XLSX</mat-option>
                    <mat-option  [value]="3"> QSQR Druglist CSV</mat-option>                 
                    <mat-option  [value]="4"> SQR MedExport XML</mat-option>
                </mat-select>
            </mat-form-field>
    </div>
    <section class="table-section">
        <div class="sticky_search">
            <div class="bg-white" style="padding-bottom: 6px; padding-top: 6px;">
                <div class="flex justify-start content-center items-center xs:flex xs:flex-col sm:flex sm:flex-row">

                    <div class="search-wrapper" style="padding: 9px">
                      <div class="search-container">
                          <app-icon icon="search" class="icon_search"></app-icon>
                          <input class="search-input" appNoHtmlTags inputId="searchcustomers-input" (keyup)="doFilter($event.target.value)"  minLengthToSearch="1" placeholder="Search" [ngModel]="searchText"  id="search_customers">
                          <app-icon icon="clear" *ngIf="searchText.length>0" (click)="doFilter('')" id="data-clear" class="pointer"></app-icon>
                      </div>
                  </div>

                   <div class="project-stuats-div flex justify-between content-start items-start basis-1/5">
                        <button id="btn_add" (click)="openAlertWindowForDrugAdd()" [ngClass]="{'btn-light':drugCellLocationList.length>0 && this.tabSelected === 0, 'mat-button-disabled':drugCellLocationList.length==0}" [disabled]="drugCellLocationList.length==0 || this.tabSelected !== 0" class=" btn-sm add_site">
                            Add
                        </button>
                        <button id="remove_celllocation" [ngClass]="{'btn-primary':drugCellLocationList.length>0 && this.tabSelected === 0, 'mat-button-disabled':drugCellLocationList.length==0}" [disabled]="drugCellLocationList.length==0 || this.tabSelected !== 0" (click)="openAlertWindowForDrugRemove()" class=" btn-sm add_site">
                            Remove
                        </button>
                        <button id="move_celllocation" 
                        [ngClass]="{'btn-danger':drugCellLocationList.length>0, 'mat-button-disabled':drugCellLocationList.length==0}" 
                        [disabled]="drugCellLocationList.length==0" (click)="openImportWizardDrugMovingComponent()" 
                        class=" btn-sm add_site" >
                        Move
                    </button>

                  </div>

                    <div class="cell-count-div flex justify-between content-start items-start basis-[15%]">

                        <button class="mat-button-disabled  btn-sm"> <span>Remaining HCC : {{remainingHighCapCount}}</span></button>

                    </div>

                    <div class="super-cell-count-div flex justify-between content-start items-start basis-[13%]">

                        <button class="mat-button-disabled  btn-sm"> <span>Remaining SC : {{remainingSmartCount}}</span></button>

                    </div>

                    <div class="super-cell-count-div flex justify-between content-start items-start basis-[13%]">

                        <button class="mat-button-disabled  btn-sm"> <span>Remaining RC : {{remainingRegularCount}}</span></button>

                    </div>

                    <div class="flex justify-start content-center items-center basis-[30%]">

                        <span class="flex justify-center content-stretch items-stretch basis-[31.3%]">Drawer Type</span>
                        <mat-form-field floatLabel="never" class="flex justify-start content-stretch items-stretch sm:basis-1/2 basis-[50.3%]">
                            <mat-select matInput  [value]="0" (selectionChange)="getRecommendedDrugData(customerId, $event.value)">
                                <mat-option  [value]="0">All</mat-option>
                                <mat-option  [value]="1">Smart : {{smartCount}}</mat-option>
                                <mat-option  [value]="2">HighCap : {{highCapCount}}</mat-option>
                                <mat-option  [value]="3">Regular: {{regularCount}}</mat-option>
                                <mat-option  [value]="4">NoDrawer : {{noDrawerCount}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="setup_scroll" >
            <mat-tab-group (selectedTabChange)="getCurrentTabSelect($event)">
                <mat-tab label="Analysis({{dataSource.data.length}})">
                    <div class="mat-elevation-z8" #TABLE>
                        <table  mat-table #table [dataSource]="dataSource" id ="matTable" matSort>

                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected" [(ngModel)]="isAnyDrugSelected"></mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- topOrder Column -->
                            <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order</th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                            </ng-container>

                            <!-- ndc Name Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC</th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>
                            

                            <!-- customerDrugNdc column-->
                            <ng-container matColumnDef="customerDrugNdc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Drug Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugNdc}} </td>
                            </ng-container>

                            <!-- barcode Column -->
                            <ng-container matColumnDef="barcode">
                                <th mat-header-cell [hidden]="!isBarCodeShow" *matHeaderCellDef mat-sort-header>Barcode </th>
                                <td mat-cell [hidden]="!isBarCodeShow" *matCellDef="let element"> {{element.barcode}} </td>
                            </ng-container>

                            <!-- primaryDrugName Column -->
                            <ng-container matColumnDef="primaryDrugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{primaryLabelName}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.primaryDrugName}} </td>
                            </ng-container>

                            <!-- secondaryDrugName Column -->
                            <ng-container matColumnDef="secondaryDrugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{secondaryLabelName}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.secondaryDrugName}} </td>
                            </ng-container>

                            <!-- strength Column -->
                            <ng-container matColumnDef="strength">
                                <th mat-header-cell [hidden]="!isStrengthShow" *matHeaderCellDef mat-sort-header>Strength </th>
                                <td mat-cell [hidden]="!isStrengthShow" *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>

                            <!-- location Column -->
                            <ng-container matColumnDef="location">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="selectedRow != element && element.checked">{{element.locationAnalysis}}</span>
                                    <span *ngIf="selectedRow != element && !element.checked">{{element.locationAnalysis}}</span>

                                    <mat-form-field *ngIf="selectedRow == element">
                                        <mat-select  [disabled]="!element.checked" (selectionChange)="drugCellLocationChanged(element)" [(ngModel)]="element.canisterLocation">
                                            <mat-option   *ngFor="let row of celLogList" [value]="row.value">{{row.value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <!-- pillForm Column -->
                            <ng-container matColumnDef="pillForm">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Pill Form</th>
                                <td mat-cell *matCellDef="let element"> {{element.pillForm}}</td>
                            </ng-container>

                            <!-- volume Column -->
                            <ng-container matColumnDef="volume">
                                <th mat-header-cell [hidden]="!isVolumeShow" *matHeaderCellDef mat-sort-header> Volume </th>
                                <td mat-cell [hidden]="!isVolumeShow" *matCellDef="let element"> {{element.volume}}</td>
                            </ng-container>

                            <!-- lot Column -->
                            <ng-container matColumnDef="lot">
                                <th mat-header-cell [hidden]="!isLotShow" *matHeaderCellDef mat-sort-header>Lot</th>
                                <td mat-cell [hidden]="!isLotShow" *matCellDef="let element"> {{element.lot}}</td>
                            </ng-container>

                            <!-- mfgExpiration Column -->
                            <ng-container matColumnDef="mfgExpiration">
                                <th mat-header-cell [hidden]="isHideMfgExpiration"  *matHeaderCellDef mat-sort-header> MFG Expiration </th>
                                <td mat-cell [hidden]="isHideMfgExpiration" *matCellDef="let element"> {{element.mfgExpiration}}</td>
                            </ng-container>

                            <!-- qtyInCanister Column -->
                            <ng-container matColumnDef="qtyInCanister">
                                <th mat-header-cell [hidden]="!isQtyInCanisterShow" *matHeaderCellDef mat-sort-header> QtyInCanister </th>
                                <td mat-cell [hidden]="!isQtyInCanisterShow" *matCellDef="let element"> {{element.qtyInCanister}} </td>
                            </ng-container>

                            <!-- color Column -->
                            <ng-container matColumnDef="color">
                                <th mat-header-cell [hidden]="!isColorShow" *matHeaderCellDef mat-sort-header> Color </th>
                                <td mat-cell [hidden]="!isColorShow" *matCellDef="let element"> {{element.color}}</td>
                            </ng-container>

                            <!-- shape Column -->
                            <ng-container matColumnDef="shape">
                                <th mat-header-cell [hidden]="!isShapeShow"  *matHeaderCellDef mat-sort-header> Shape </th>
                                <td mat-cell [hidden]="!isShapeShow" *matCellDef="let element"> {{element.shape}}</td>
                            </ng-container>

                            <!-- marking Column -->
                            <ng-container matColumnDef="marking">
                                <th mat-header-cell [hidden]="!isMarkingColumnShow" *matHeaderCellDef mat-sort-header>Marking</th>
                                <td mat-cell [hidden]="!isMarkingColumnShow" *matCellDef="let element"> {{element.marking}}</td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="description">
                                <th mat-header-cell [hidden]="!isDiscriptionShow" *matHeaderCellDef mat-sort-header> Description </th>
                                <td mat-cell [hidden]="!isDiscriptionShow" *matCellDef="let element"> {{element.description}}</td>
                            </ng-container>

                            <!-- packQuantity Column -->
                            <ng-container matColumnDef="packQuantity">
                                <th mat-header-cell [hidden]="!isPackQtyShow" *matHeaderCellDef mat-sort-header>PackQuantity</th>
                                <td mat-cell [hidden]="!isPackQtyShow" *matCellDef="let element"> {{element.packQuantity}}</td>
                            </ng-container>

                            <!-- CostPerPill Column -->
                            <ng-container matColumnDef="costPerPill">
                                <th mat-header-cell [hidden]="isHideCostPerPill" *matHeaderCellDef mat-sort-header> CostPerPill </th>
                                <td mat-cell [hidden]="isHideCostPerPill" *matCellDef="let element"> {{element.costPerPill}}</td>
                            </ng-container>

                            <!-- apw Column -->
                            <ng-container matColumnDef="apw">
                                <th mat-header-cell [hidden]="!isApwColumnShow" *matHeaderCellDef mat-sort-header> APW </th>
                                <td mat-cell [hidden]="!isApwColumnShow"  *matCellDef="let element">
                                    {{element.apw}}
                                </td>
                            </ng-container>

                              <!-- controlSched Column -->
                              <ng-container matColumnDef="controlSched">
                                <th mat-header-cell [hidden]="isHidecontrolSched" *matHeaderCellDef mat-sort-header>Control Sched</th>
                                <td mat-cell [hidden]="isHidecontrolSched" *matCellDef="let element"> {{element.controlSched}}</td>
                            </ng-container>

                            <!-- comment Column -->
                            <ng-container matColumnDef="comment">
                                <th mat-header-cell [hidden]="isHideComment" *matHeaderCellDef mat-sort-header> Comment </th>
                                <td mat-cell [hidden]="isHideComment" *matCellDef="let element"> {{element.comment}}</td>
                            </ng-container>

                            <!-- doNotMix Column -->
                            <ng-container matColumnDef="doNotMix">
                                <th mat-header-cell [hidden]="isHideDontMix" *matHeaderCellDef mat-sort-header>Do Not Mix</th>
                                <td mat-cell [hidden]="isHideDontMix"  *matCellDef="let element"> {{element.doNotMix}}</td>
                            </ng-container>

                            <!-- active Column -->
                            <ng-container matColumnDef="active">
                                <th mat-header-cell [hidden]="!isActiveShow" *matHeaderCellDef mat-sort-header> Active </th>
                                <td mat-cell [hidden]="!isActiveShow" *matCellDef="let element"> {{element.active}}</td>
                            </ng-container>

                            <!-- min Column -->
                            <ng-container matColumnDef="min">
                                <th mat-header-cell [hidden]="isHideMin" *matHeaderCellDef mat-sort-header> Min </th>
                                <td mat-cell [hidden]="isHideMin" *matCellDef="let element"> {{element.min}} </td>
                            </ng-container>

                            <!-- max Column -->
                            <ng-container matColumnDef="max">
                                <th mat-header-cell [hidden]="isHideMax" *matHeaderCellDef mat-sort-header> Max </th>
                                <td mat-cell [hidden]="isHideMax" *matCellDef="let element">
                                    {{element.max}}
                                </td>
                            </ng-container>

                            <!-- invoiceNumber Column -->
                            <ng-container matColumnDef="invoiceNumber">
                                <th mat-header-cell [hidden]="isHideInvoice" *matHeaderCellDef mat-sort-header>Invoice Number</th>
                                <td mat-cell [hidden]="isHideInvoice" *matCellDef="let element"> {{element.invoiceNumber}}</td>
                            </ng-container>

                            <!-- smartId Column -->
                            <ng-container matColumnDef="smartId">
                                <th mat-header-cell [hidden]="isHideSmartId" *matHeaderCellDef mat-sort-header> SmartId </th>
                                <td mat-cell [hidden]="isHideSmartId" *matCellDef="let element"> {{element.smartId}}</td>
                            </ng-container>

                            <!-- genericSequenceNumber Column -->
                            <ng-container matColumnDef="genericSequenceNumber">
                                <th mat-header-cell  *matHeaderCellDef mat-sort-header> GCN SEQ </th>
                                <td mat-cell *matCellDef="let element"> {{element.genericSequenceNumber}}</td>
                            </ng-container>

                            <!-- assignedLot Column -->
                            <ng-container matColumnDef="assignedLot">
                                <th mat-header-cell [hidden]="isHideAssignedLot" *matHeaderCellDef mat-sort-header> Assigned Lot </th>
                                <td mat-cell [hidden]="isHideAssignedLot" *matCellDef="let element"> {{element.assignedLot}} </td>
                            </ng-container>

                            <!-- assignedExpiration Column -->
                            <ng-container matColumnDef="assignedExpiration">
                                <th mat-header-cell [hidden]="isHideAssignedExp" *matHeaderCellDef mat-sort-header> Assigned Expiration </th>
                                <td mat-cell [hidden]="isHideAssignedExp" *matCellDef="let element">
                                    {{element.assignedExpiration}}
                                </td>
                            </ng-container>

                            <!-- distributor Column -->
                            <ng-container matColumnDef="distributor">
                                <th mat-header-cell [hidden]="isHideDistributor"  *matHeaderCellDef mat-sort-header> Distributor </th>
                                <td mat-cell [hidden]="isHideDistributor" *matCellDef="let element"> {{element.distributor}}</td>
                            </ng-container>

                            <!-- drawerType Column -->
                            <ng-container matColumnDef="drawerType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer Type </th>
                                <td mat-cell *matCellDef="let element"> {{element.drawerType}} </td>
                            </ng-container>

                            <!-- drawer Column -->
                            <ng-container matColumnDef="drawer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.drawer}}
                                </td>
                            </ng-container>

                            <!-- unitUsage Column -->
                            <ng-container matColumnDef="unitUsage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Usage </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.usage}}
                                </td>
                            </ng-container>

                             <!-- thirtyDramCapacity Column -->
                             <ng-container matColumnDef="thirtyDramCapacity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> 30 Dram </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.thirtyDramCapacity}}
                                </td>
                            </ng-container>

                            <!-- pillVolume Column -->
                            <ng-container matColumnDef="pillVolume">
                                <th mat-header-cell [hidden]="!isPillVolumeShow" *matHeaderCellDef mat-sort-header> Pill Volume</th>
                                <td mat-cell [hidden]="!isPillVolumeShow" *matCellDef="let element">
                                    {{element.pillVolume}}
                                </td>
                            </ng-container>

                            <!-- atpCanister Column -->
                            <ng-container matColumnDef="atpCanisterNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> #CItem</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.atpCanisterNumber}}
                                </td>
                            </ng-container>

                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                             <!-- Action Details -->
                             <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                        <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                    </mat-icon>
                                    <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="after">
                                        <div class="checkbox-container">
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isApwColumnShow" class="table-dynamic-column flex-row" >
                                            APW
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isMarkingColumnShow" class="table-dynamic-column flex-row" >
                                            Marking
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isQtyInCanisterShow" class="table-dynamic-column flex-row" >
                                            Qty In Canister
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isLotShow" class="table-dynamic-column flex-row" >
                                            Lot
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isStrengthShow" class="table-dynamic-column flex-row" >
                                            Strength
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isShapeShow" class="table-dynamic-column flex-row" >
                                            Shape
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPackQtyShow" class="table-dynamic-column flex-row" >
                                            Pack Qty
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isColorShow" class="table-dynamic-column flex-row" >
                                            Color
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isBarCodeShow" class="table-dynamic-column flex-row" >
                                            BarCode
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPillVolumeShow" class="table-dynamic-column flex-row" >
                                            PillVolme
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isVolumeShow" class="table-dynamic-column flex-row" >
                                            Volume
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isActiveShow" class="table-dynamic-column flex-row" >
                                            Active
                                        </mat-checkbox>
                                        <button style="margin: 1% 1% 5% 20%; height: 30px;" class="table-dyanamic-column btn btn-primary btn-sm">Ok</button>
                                        </div>
                                    </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                        <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" (mouseenter)="selectedRow = row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSource.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="New Drugs({{dataSourceNotAbailable.data.length}})">
                    <div class="mat-elevation-z8" #TABLE1>
                        <table mat-table #table1 [dataSource]="dataSourceNotAbailable" matSort>

                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef>
                                    <!-- <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected" [(ngModel)]="isAnyDrugSelected"></mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- topOrder Column -->
                            <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order</th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                            </ng-container>

                            <!-- ndc Name Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC</th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                            <!-- customerDrugNdc column-->
                            <ng-container matColumnDef="customerDrugNdc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> customer Drug Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugNdc}} </td>
                            </ng-container>

                            <!-- barcode Column -->
                            <ng-container matColumnDef="barcode">
                                <th mat-header-cell [hidden]="!isBarCodeShow" *matHeaderCellDef mat-sort-header>Barcode </th>
                                <td mat-cell [hidden]="!isBarCodeShow" *matCellDef="let element"> {{element.barcode}} </td>
                            </ng-container>

                            <!-- primaryDrugName Column -->
                            <ng-container matColumnDef="primaryDrugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Drug Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.primaryDrugName}} </td>
                            </ng-container>

                            <!-- secondaryDrugName Column -->
                            <ng-container matColumnDef="secondaryDrugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Drug Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.secondaryDrugName}} </td>
                            </ng-container>

                            <!-- strength Column -->
                            <ng-container matColumnDef="strength">
                                <th mat-header-cell [hidden]="!isStrengthShow" *matHeaderCellDef mat-sort-header>Strength </th>
                                <td mat-cell [hidden]="!isStrengthShow" *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>

                            <!-- location Column -->
                            <ng-container matColumnDef="location">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="selectedRow != element && element.checked">{{element.canisterLocation}}</span>
                                    <span *ngIf="selectedRow != element && !element.checked">{{element.canisterLocation}}</span>

                                    <mat-form-field *ngIf="selectedRow == element">
                                        <mat-select  [disabled]="!element.checked" (selectionChange)="drugCellLocationChanged(element)" [(ngModel)]="element.canisterLocation">
                                            <mat-option   *ngFor="let row of celLogList" [value]="row.value">{{row.value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <!-- pillForm Column -->
                            <ng-container matColumnDef="pillForm">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Pill Form</th>
                                <td mat-cell *matCellDef="let element"> {{element.pillForm}}</td>
                            </ng-container>

                            <!-- volume Column -->
                            <ng-container matColumnDef="volume">
                                <th mat-header-cell [hidden]="!isVolumeShow" *matHeaderCellDef mat-sort-header> Volume </th>
                                <td mat-cell [hidden]="!isVolumeShow" *matCellDef="let element"> {{element.volume}}</td>
                            </ng-container>

                            <!-- lot Column -->
                            <ng-container matColumnDef="lot">
                                <th mat-header-cell [hidden]="!isLotShow" *matHeaderCellDef mat-sort-header>Lot</th>
                                <td mat-cell [hidden]="!isLotShow" *matCellDef="let element"> {{element.lot}}</td>
                            </ng-container>

                            <!-- mfgExpiration Column -->
                            <ng-container matColumnDef="mfgExpiration">
                                <th mat-header-cell [hidden]="isHideMfgExpiration"  *matHeaderCellDef mat-sort-header> MFG Expiration </th>
                                <td mat-cell [hidden]="isHideMfgExpiration" *matCellDef="let element"> {{element.mfgExpiration}}</td>
                            </ng-container>

                            <!-- qtyInCanister Column -->
                            <ng-container matColumnDef="qtyInCanister">
                                <th mat-header-cell [hidden]="!isQtyInCanisterShow" *matHeaderCellDef mat-sort-header> QtyInCanister </th>
                                <td mat-cell [hidden]="!isQtyInCanisterShow" *matCellDef="let element"> {{element.qtyInCanister}} </td>
                            </ng-container>

                            <!-- color Column -->
                            <ng-container matColumnDef="color">
                                <th mat-header-cell [hidden]="!isColorShow" *matHeaderCellDef mat-sort-header> Color </th>
                                <td mat-cell [hidden]="!isColorShow" *matCellDef="let element"> {{element.color}}</td>
                            </ng-container>

                            <!-- shape Column -->
                            <ng-container matColumnDef="shape">
                                <th mat-header-cell [hidden]="!isShapeShow"  *matHeaderCellDef mat-sort-header> Shape </th>
                                <td mat-cell [hidden]="!isShapeShow" *matCellDef="let element"> {{element.shape}}</td>
                            </ng-container>

                            <!-- marking Column -->
                            <ng-container matColumnDef="marking">
                                <th mat-header-cell [hidden]="!isMarkingColumnShow" *matHeaderCellDef mat-sort-header>Marking</th>
                                <td mat-cell [hidden]="!isMarkingColumnShow" *matCellDef="let element"> {{element.marking}}</td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="description">
                                <th mat-header-cell [hidden]="!isDiscriptionShow" *matHeaderCellDef mat-sort-header> Description </th>
                                <td mat-cell [hidden]="!isDiscriptionShow" *matCellDef="let element"> {{element.description}}</td>
                            </ng-container>

                            <!-- packQuantity Column -->
                            <ng-container matColumnDef="packQuantity">
                                <th mat-header-cell [hidden]="!isPackQtyShow" *matHeaderCellDef mat-sort-header>PackQuantity</th>
                                <td mat-cell [hidden]="!isPackQtyShow" *matCellDef="let element"> {{element.packQuantity}}</td>
                            </ng-container>

                            <!-- CostPerPill Column -->
                            <ng-container matColumnDef="costPerPill">
                                <th mat-header-cell [hidden]="isHideCostPerPill" *matHeaderCellDef mat-sort-header> CostPerPill </th>
                                <td mat-cell [hidden]="isHideCostPerPill" *matCellDef="let element"> {{element.costPerPill}}</td>
                            </ng-container>

                            <!-- apw Column -->
                            <ng-container matColumnDef="apw">
                                <th mat-header-cell [hidden]="!isApwColumnShow" *matHeaderCellDef mat-sort-header> APW </th>
                                <td mat-cell [hidden]="!isApwColumnShow"  *matCellDef="let element">
                                    {{element.apw}}
                                </td>
                            </ng-container>

                              <!-- controlSched Column -->
                              <ng-container matColumnDef="controlSched">
                                <th mat-header-cell [hidden]="isHidecontrolSched" *matHeaderCellDef mat-sort-header>Control Sched</th>
                                <td mat-cell [hidden]="isHidecontrolSched" *matCellDef="let element"> {{element.controlSched}}</td>
                            </ng-container>

                            <!-- comment Column -->
                            <ng-container matColumnDef="comment">
                                <th mat-header-cell [hidden]="isHideComment" *matHeaderCellDef mat-sort-header> Comment </th>
                                <td mat-cell [hidden]="isHideComment" *matCellDef="let element"> {{element.comment}}</td>
                            </ng-container>

                            <!-- doNotMix Column -->
                            <ng-container matColumnDef="doNotMix">
                                <th mat-header-cell [hidden]="isHideDontMix" *matHeaderCellDef mat-sort-header>Do Not Mix</th>
                                <td mat-cell [hidden]="isHideDontMix"  *matCellDef="let element"> {{element.doNotMix}}</td>
                            </ng-container>

                            <!-- active Column -->
                            <ng-container matColumnDef="active">
                                <th mat-header-cell [hidden]="!isActiveShow" *matHeaderCellDef mat-sort-header> Active </th>
                                <td mat-cell [hidden]="!isActiveShow" *matCellDef="let element"> {{element.active}}</td>
                            </ng-container>

                            <!-- min Column -->
                            <ng-container matColumnDef="min">
                                <th mat-header-cell [hidden]="isHideMin" *matHeaderCellDef mat-sort-header> Min </th>
                                <td mat-cell [hidden]="isHideMin" *matCellDef="let element"> {{element.min}} </td>
                            </ng-container>

                            <!-- max Column -->
                            <ng-container matColumnDef="max">
                                <th mat-header-cell [hidden]="isHideMax" *matHeaderCellDef mat-sort-header> Max </th>
                                <td mat-cell [hidden]="isHideMax" *matCellDef="let element">
                                    {{element.max}}
                                </td>
                            </ng-container>

                            <!-- invoiceNumber Column -->
                            <ng-container matColumnDef="invoiceNumber">
                                <th mat-header-cell [hidden]="isHideInvoice" *matHeaderCellDef mat-sort-header>Invoice Number</th>
                                <td mat-cell [hidden]="isHideInvoice" *matCellDef="let element"> {{element.invoiceNumber}}</td>
                            </ng-container>

                            <!-- smartId Column -->
                            <ng-container matColumnDef="smartId">
                                <th mat-header-cell [hidden]="isHideSmartId" *matHeaderCellDef mat-sort-header> SmartId </th>
                                <td mat-cell [hidden]="isHideSmartId" *matCellDef="let element"> {{element.smartId}}</td>
                            </ng-container>

                            <!-- genericSequenceNumber Column -->
                            <ng-container matColumnDef="genericSequenceNumber">
                                <th mat-header-cell  *matHeaderCellDef mat-sort-header> GCN SEQ </th>
                                <td mat-cell *matCellDef="let element"> {{element.genericSequenceNumber}}</td>
                            </ng-container>

                            <!-- assignedLot Column -->
                            <ng-container matColumnDef="assignedLot">
                                <th mat-header-cell [hidden]="isHideAssignedLot" *matHeaderCellDef mat-sort-header> Assigned Lot </th>
                                <td mat-cell [hidden]="isHideAssignedLot" *matCellDef="let element"> {{element.assignedLot}} </td>
                            </ng-container>

                            <!-- assignedExpiration Column -->
                            <ng-container matColumnDef="assignedExpiration">
                                <th mat-header-cell [hidden]="isHideAssignedExp" *matHeaderCellDef mat-sort-header> Assigned Expiration </th>
                                <td mat-cell [hidden]="isHideAssignedExp" *matCellDef="let element">
                                    {{element.assignedExpiration}}
                                </td>
                            </ng-container>

                            <!-- distributor Column -->
                            <ng-container matColumnDef="distributor">
                                <th mat-header-cell [hidden]="isHideDistributor"  *matHeaderCellDef mat-sort-header> Distributor </th>
                                <td mat-cell [hidden]="isHideDistributor" *matCellDef="let element"> {{element.distributor}}</td>
                            </ng-container>

                            <!-- drawerType Column -->
                            <ng-container matColumnDef="drawerType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer Type </th>
                                <td mat-cell *matCellDef="let element"> {{element.drawerType}} </td>
                            </ng-container>

                            <!-- drawer Column -->
                            <ng-container matColumnDef="drawer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.drawer}}
                                </td>
                            </ng-container>

                            <!-- unitUsage Column -->
                            <ng-container matColumnDef="unitUsage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Usage </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.usage}}
                                </td>
                            </ng-container>

                             <!-- thirtyDramCapacity Column -->
                             <ng-container matColumnDef="thirtyDramCapacity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> 30 Dram </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.thirtyDramCapacity}}
                                </td>
                            </ng-container>

                            <!-- pillVolume Column -->
                            <ng-container matColumnDef="pillVolume">
                                <th mat-header-cell [hidden]="!isPillVolumeShow" *matHeaderCellDef mat-sort-header> Pill Volume</th>
                                <td mat-cell [hidden]="!isPillVolumeShow" *matCellDef="let element">
                                    {{element.pillVolume}}
                                </td>
                            </ng-container>

                            <!-- atpCanisterNumber Column -->
                            <ng-container matColumnDef="atpCanisterNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> #CItem</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.atpCanisterNumber}}
                                </td>
                            </ng-container>

                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                             <!-- Action Details -->
                             <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                        <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                    </mat-icon>
                                    <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="after">
                                        <div class="checkbox-container">
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isApwColumnShow" class="table-dynamic-column flex-row" >
                                            APW
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isMarkingColumnShow" class="table-dynamic-column flex-row" >
                                            Marking
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isQtyInCanisterShow" class="table-dynamic-column flex-row" >
                                            Qty In Canister
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isLotShow" class="table-dynamic-column flex-row" >
                                            Lot
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isStrengthShow" class="table-dynamic-column flex-row" >
                                            Strength
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isShapeShow" class="table-dynamic-column flex-row" >
                                            Shape
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPackQtyShow" class="table-dynamic-column flex-row" >
                                            Pack Qty
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isColorShow" class="table-dynamic-column flex-row" >
                                            Color
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isBarCodeShow" class="table-dynamic-column flex-row" >
                                            BarCode
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPillVolumeShow" class="table-dynamic-column flex-row" >
                                            PillVolme
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isVolumeShow" class="table-dynamic-column flex-row" >
                                            Volume
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isActiveShow" class="table-dynamic-column flex-row" >
                                            Active
                                        </mat-checkbox>
                                        <button style="margin: 1% 1% 5% 20%; height: 30px;" class="table-dyanamic-column btn btn-primary btn-sm">Ok</button>
                                        </div>
                                    </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                        <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSourceNotAbailable.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Excluded({{dataSourceExcluded.data.length}})">
                    <div class="mat-elevation-z8" #TABLE2>
                        <table mat-table #table2 class="mat-elevation-z8" [dataSource]="dataSourceExcluded" matSort>

                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef>
                                    <!-- <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected" [(ngModel)]="isAnyDrugSelected"></mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- topOrder Column -->
                            <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order</th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                            </ng-container>

                             <!-- excludedReason Column -->
                             <ng-container matColumnDef="excludedReason">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Excluded Reason</th>
                                <td mat-cell *matCellDef="let element"> {{element.excludedReason}} </td>
                            </ng-container>

                            <!-- ndc Name Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC</th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                            <!-- customerDrugNdc column-->
                            <ng-container matColumnDef="customerDrugNdc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> customer Drug Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugNdc}} </td>
                            </ng-container>

                            <!-- barcode Column -->
                            <ng-container matColumnDef="barcode">
                                <th mat-header-cell [hidden]="!isBarCodeShow" *matHeaderCellDef mat-sort-header>Barcode </th>
                                <td mat-cell [hidden]="!isBarCodeShow" *matCellDef="let element"> {{element.barcode}} </td>
                            </ng-container>

                            <!-- primaryDrugName Column -->
                            <ng-container matColumnDef="primaryDrugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Drug Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.primaryDrugName}} </td>
                            </ng-container>

                            <!-- secondaryDrugName Column -->
                            <ng-container matColumnDef="secondaryDrugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Drug Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.secondaryDrugName}} </td>
                            </ng-container>

                            <!-- strength Column -->
                            <ng-container matColumnDef="strength">
                                <th mat-header-cell [hidden]="!isStrengthShow" *matHeaderCellDef mat-sort-header>Strength </th>
                                <td mat-cell [hidden]="!isStrengthShow" *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>

                            <!-- location Column -->
                            <ng-container matColumnDef="location">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="selectedRow != element && element.checked">{{element.canisterLocation}}</span>
                                    <span *ngIf="selectedRow != element && !element.checked">{{element.canisterLocation}}</span>

                                    <mat-form-field *ngIf="selectedRow == element">
                                        <mat-select  [disabled]="!element.checked" (selectionChange)="drugCellLocationChanged(element)" [(ngModel)]="element.canisterLocation">
                                            <mat-option   *ngFor="let row of celLogList" [value]="row.value">{{row.value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <!-- pillForm Column -->
                            <ng-container matColumnDef="pillForm">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Pill Form</th>
                                <td mat-cell *matCellDef="let element"> {{element.pillForm}}</td>
                            </ng-container>

                            <!-- volume Column -->
                            <ng-container matColumnDef="volume">
                                <th mat-header-cell [hidden]="!isVolumeShow" *matHeaderCellDef mat-sort-header> Volume </th>
                                <td mat-cell [hidden]="!isVolumeShow" *matCellDef="let element"> {{element.volume}}</td>
                            </ng-container>

                            <!-- lot Column -->
                            <ng-container matColumnDef="lot">
                                <th mat-header-cell [hidden]="!isLotShow" *matHeaderCellDef mat-sort-header>Lot</th>
                                <td mat-cell [hidden]="!isLotShow" *matCellDef="let element"> {{element.lot}}</td>
                            </ng-container>

                            <!-- mfgExpiration Column -->
                            <ng-container matColumnDef="mfgExpiration">
                                <th mat-header-cell [hidden]="isHideMfgExpiration"  *matHeaderCellDef mat-sort-header> MFG Expiration </th>
                                <td mat-cell [hidden]="isHideMfgExpiration" *matCellDef="let element"> {{element.mfgExpiration}}</td>
                            </ng-container>

                            <!-- qtyInCanister Column -->
                            <ng-container matColumnDef="qtyInCanister">
                                <th mat-header-cell [hidden]="!isQtyInCanisterShow" *matHeaderCellDef mat-sort-header> QtyInCanister </th>
                                <td mat-cell [hidden]="!isQtyInCanisterShow" *matCellDef="let element"> {{element.qtyInCanister}} </td>
                            </ng-container>

                            <!-- color Column -->
                            <ng-container matColumnDef="color">
                                <th mat-header-cell [hidden]="!isColorShow" *matHeaderCellDef mat-sort-header> Color </th>
                                <td mat-cell [hidden]="!isColorShow" *matCellDef="let element"> {{element.color}}</td>
                            </ng-container>

                            <!-- shape Column -->
                            <ng-container matColumnDef="shape">
                                <th mat-header-cell [hidden]="!isShapeShow"  *matHeaderCellDef mat-sort-header> Shape </th>
                                <td mat-cell [hidden]="!isShapeShow" *matCellDef="let element"> {{element.shape}}</td>
                            </ng-container>

                            <!-- marking Column -->
                            <ng-container matColumnDef="marking">
                                <th mat-header-cell [hidden]="!isMarkingColumnShow" *matHeaderCellDef mat-sort-header>Marking</th>
                                <td mat-cell [hidden]="!isMarkingColumnShow" *matCellDef="let element"> {{element.marking}}</td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="description">
                                <th mat-header-cell [hidden]="!isDiscriptionShow" *matHeaderCellDef mat-sort-header> Description </th>
                                <td mat-cell [hidden]="!isDiscriptionShow" *matCellDef="let element"> {{element.description}}</td>
                            </ng-container>

                            <!-- packQuantity Column -->
                            <ng-container matColumnDef="packQuantity">
                                <th mat-header-cell [hidden]="!isPackQtyShow" *matHeaderCellDef mat-sort-header>PackQuantity</th>
                                <td mat-cell [hidden]="!isPackQtyShow" *matCellDef="let element"> {{element.packQuantity}}</td>
                            </ng-container>

                            <!-- CostPerPill Column -->
                            <ng-container matColumnDef="costPerPill">
                                <th mat-header-cell [hidden]="isHideCostPerPill" *matHeaderCellDef mat-sort-header> CostPerPill </th>
                                <td mat-cell [hidden]="isHideCostPerPill" *matCellDef="let element"> {{element.costPerPill}}</td>
                            </ng-container>

                            <!-- apw Column -->
                            <ng-container matColumnDef="apw">
                                <th mat-header-cell [hidden]="!isApwColumnShow" *matHeaderCellDef mat-sort-header> APW </th>
                                <td mat-cell [hidden]="!isApwColumnShow"  *matCellDef="let element">
                                    {{element.apw}}
                                </td>
                            </ng-container>

                              <!-- controlSched Column -->
                              <ng-container matColumnDef="controlSched">
                                <th mat-header-cell [hidden]="isHidecontrolSched" *matHeaderCellDef mat-sort-header>Control Sched</th>
                                <td mat-cell [hidden]="isHidecontrolSched" *matCellDef="let element"> {{element.controlSched}}</td>
                            </ng-container>

                            <!-- comment Column -->
                            <ng-container matColumnDef="comment">
                                <th mat-header-cell [hidden]="isHideComment" *matHeaderCellDef mat-sort-header> Comment </th>
                                <td mat-cell [hidden]="isHideComment" *matCellDef="let element"> {{element.comment}}</td>
                            </ng-container>

                            <!-- doNotMix Column -->
                            <ng-container matColumnDef="doNotMix">
                                <th mat-header-cell [hidden]="isHideDontMix" *matHeaderCellDef mat-sort-header>Do Not Mix</th>
                                <td mat-cell [hidden]="isHideDontMix"  *matCellDef="let element"> {{element.doNotMix}}</td>
                            </ng-container>

                            <!-- active Column -->
                            <ng-container matColumnDef="active">
                                <th mat-header-cell [hidden]="!isActiveShow" *matHeaderCellDef mat-sort-header> Active </th>
                                <td mat-cell [hidden]="!isActiveShow" *matCellDef="let element"> {{element.active}}</td>
                            </ng-container>

                            <!-- min Column -->
                            <ng-container matColumnDef="min">
                                <th mat-header-cell [hidden]="isHideMin" *matHeaderCellDef mat-sort-header> Min </th>
                                <td mat-cell [hidden]="isHideMin" *matCellDef="let element"> {{element.min}} </td>
                            </ng-container>

                            <!-- max Column -->
                            <ng-container matColumnDef="max">
                                <th mat-header-cell [hidden]="isHideMax" *matHeaderCellDef mat-sort-header> Max </th>
                                <td mat-cell [hidden]="isHideMax" *matCellDef="let element">
                                    {{element.max}}
                                </td>
                            </ng-container>

                            <!-- invoiceNumber Column -->
                            <ng-container matColumnDef="invoiceNumber">
                                <th mat-header-cell [hidden]="isHideInvoice" *matHeaderCellDef mat-sort-header>Invoice Number</th>
                                <td mat-cell [hidden]="isHideInvoice" *matCellDef="let element"> {{element.invoiceNumber}}</td>
                            </ng-container>

                            <!-- smartId Column -->
                            <ng-container matColumnDef="smartId">
                                <th mat-header-cell [hidden]="isHideSmartId" *matHeaderCellDef mat-sort-header> SmartId </th>
                                <td mat-cell [hidden]="isHideSmartId" *matCellDef="let element"> {{element.smartId}}</td>
                            </ng-container>

                            <!-- genericSequenceNumber Column -->
                            <ng-container matColumnDef="genericSequenceNumber">
                                <th mat-header-cell  *matHeaderCellDef mat-sort-header> GCN SEQ </th>
                                <td mat-cell *matCellDef="let element"> {{element.genericSequenceNumber}}</td>
                            </ng-container>

                            <!-- assignedLot Column -->
                            <ng-container matColumnDef="assignedLot">
                                <th mat-header-cell [hidden]="isHideAssignedLot" *matHeaderCellDef mat-sort-header> Assigned Lot </th>
                                <td mat-cell [hidden]="isHideAssignedLot" *matCellDef="let element"> {{element.assignedLot}} </td>
                            </ng-container>

                            <!-- assignedExpiration Column -->
                            <ng-container matColumnDef="assignedExpiration">
                                <th mat-header-cell [hidden]="isHideAssignedExp" *matHeaderCellDef mat-sort-header> Assigned Expiration </th>
                                <td mat-cell [hidden]="isHideAssignedExp" *matCellDef="let element">
                                    {{element.assignedExpiration}}
                                </td>
                            </ng-container>

                            <!-- distributor Column -->
                            <ng-container matColumnDef="distributor">
                                <th mat-header-cell [hidden]="isHideDistributor"  *matHeaderCellDef mat-sort-header> Distributor </th>
                                <td mat-cell [hidden]="isHideDistributor" *matCellDef="let element"> {{element.distributor}}</td>
                            </ng-container>

                            <!-- drawerType Column -->
                            <ng-container matColumnDef="drawerType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer Type </th>
                                <td mat-cell *matCellDef="let element"> {{element.drawerType}} </td>
                            </ng-container>

                            <!-- drawer Column -->
                            <ng-container matColumnDef="drawer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.drawer}}
                                </td>
                            </ng-container>

                            <!-- unitUsage Column -->
                            <ng-container matColumnDef="unitUsage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Usage </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.usage}}
                                </td>
                            </ng-container>

                             <!-- thirtyDramCapacity Column -->
                             <ng-container matColumnDef="thirtyDramCapacity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> 30 Dram </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.thirtyDramCapacity}}
                                </td>
                            </ng-container>

                            <!-- pillVolume Column -->
                            <ng-container matColumnDef="pillVolume">
                                <th mat-header-cell [hidden]="!isPillVolumeShow" *matHeaderCellDef mat-sort-header> Pill Volume</th>
                                <td mat-cell [hidden]="!isPillVolumeShow" *matCellDef="let element">
                                    {{element.pillVolume}}
                                </td>
                            </ng-container>

                            <!-- atpCanister Column -->
                            <ng-container matColumnDef="atpCanisterNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> #CItem</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.atpCanisterNumber}}
                                </td>
                            </ng-container>

                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                             <!-- Action Details -->
                             <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                        <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                    </mat-icon>
                                    <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="after">
                                        <div class="checkbox-container">
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isApwColumnShow" class="table-dynamic-column flex-row" >
                                            APW
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isMarkingColumnShow" class="table-dynamic-column flex-row" >
                                            Marking
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isQtyInCanisterShow" class="table-dynamic-column flex-row" >
                                            Qty In Canister
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isLotShow" class="table-dynamic-column flex-row" >
                                            Lot
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isStrengthShow" class="table-dynamic-column flex-row" >
                                            Strength
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isShapeShow" class="table-dynamic-column flex-row" >
                                            Shape
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPackQtyShow" class="table-dynamic-column flex-row" >
                                            Pack Qty
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isColorShow" class="table-dynamic-column flex-row" >
                                            Color
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isBarCodeShow" class="table-dynamic-column flex-row" >
                                            BarCode
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPillVolumeShow" class="table-dynamic-column flex-row" >
                                            PillVolme
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isVolumeShow" class="table-dynamic-column flex-row" >
                                            Volume
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isActiveShow" class="table-dynamic-column flex-row" >
                                            Active
                                        </mat-checkbox>
                                        <button style="margin: 1% 1% 5% 20%; height: 30px;" class="table-dyanamic-column btn btn-primary btn-sm">Ok</button>
                                        </div>
                                    </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                        <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsExcluded"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" mat-row *matRowDef="let row; columns: displayedColumnsExcluded;"></tr>

                        </table>
                        <table>
                            <tr *ngIf="dataSourceExcluded.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumnsExcluded.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Not Oral Solid({{dataSourceOral.data.length}})">
                    <div class="mat-elevation-z8" #TABLE3>
                        <table mat-table #table3 [dataSource]="dataSourceOral" matSort>


                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox" >
                                <th mat-header-cell *matHeaderCellDef >
                                    <!-- <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected" [(ngModel)]="isAnyDrugSelected"></mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- topOrder Column -->
                            <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order</th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                            </ng-container>

                            <!-- ndc Name Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC</th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                              <!-- dosageForm Column -->
                              <ng-container matColumnDef="dosageForm">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dosage Form</th>
                                <td mat-cell *matCellDef="let element"> {{element.dosageForm}} </td>
                            </ng-container>

                            <!-- customerDrugNdc column-->
                            <ng-container matColumnDef="customerDrugNdc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> customer Drug Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.customerDrugNdc}} </td>
                            </ng-container>

                            <!-- barcode Column -->
                            <ng-container matColumnDef="barcode">
                                <th mat-header-cell [hidden]="!isBarCodeShow" *matHeaderCellDef mat-sort-header>Barcode </th>
                                <td mat-cell [hidden]="!isBarCodeShow" *matCellDef="let element"> {{element.barcode}} </td>
                            </ng-container>

                            <!-- primaryDrugName Column -->
                            <ng-container matColumnDef="primaryDrugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Drug Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.primaryDrugName}} </td>
                            </ng-container>

                            <!-- secondaryDrugName column-->
                            <ng-container matColumnDef="secondaryDrugName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Drug Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.secondaryDrugName}} </td>
                            </ng-container>

                            <!-- strength Column -->
                            <ng-container matColumnDef="strength">
                                <th mat-header-cell [hidden]="!isStrengthShow" *matHeaderCellDef mat-sort-header>Strength </th>
                                <td mat-cell [hidden]="!isStrengthShow" *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>

                            <!-- location Column -->
                            <ng-container matColumnDef="location">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="selectedRow != element && element.checked">{{element.canisterLocation}}</span>
                                    <span *ngIf="selectedRow != element && !element.checked">{{element.canisterLocation}}</span>

                                    <mat-form-field *ngIf="selectedRow == element">
                                        <mat-select  [disabled]="!element.checked" (selectionChange)="drugCellLocationChanged(element)" [(ngModel)]="element.canisterLocation">
                                            <mat-option   *ngFor="let row of celLogList" [value]="row.value">{{row.value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <!-- pillForm Column -->
                            <ng-container matColumnDef="pillForm">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Pill Form</th>
                                <td mat-cell *matCellDef="let element"> {{element.pillForm}}</td>
                            </ng-container>

                            <!-- volume Column -->
                            <ng-container matColumnDef="volume">
                                <th mat-header-cell [hidden]="!isVolumeShow" *matHeaderCellDef mat-sort-header> Volume </th>
                                <td mat-cell [hidden]="!isVolumeShow" *matCellDef="let element"> {{element.volume}}</td>
                            </ng-container>

                            <!-- lot Column -->
                            <ng-container matColumnDef="lot">
                                <th mat-header-cell [hidden]="!isLotShow" *matHeaderCellDef mat-sort-header>Lot</th>
                                <td mat-cell [hidden]="!isLotShow" *matCellDef="let element"> {{element.lot}}</td>
                            </ng-container>

                            <!-- mfgExpiration Column -->
                            <ng-container matColumnDef="mfgExpiration">
                                <th mat-header-cell [hidden]="isHideMfgExpiration"  *matHeaderCellDef mat-sort-header> MFG Expiration </th>
                                <td mat-cell [hidden]="isHideMfgExpiration" *matCellDef="let element"> {{element.mfgExpiration}}</td>
                            </ng-container>

                            <!-- qtyInCanister Column -->
                            <ng-container matColumnDef="qtyInCanister">
                                <th mat-header-cell [hidden]="!isQtyInCanisterShow" *matHeaderCellDef mat-sort-header> QtyInCanister </th>
                                <td mat-cell [hidden]="!isQtyInCanisterShow" *matCellDef="let element"> {{element.qtyInCanister}} </td>
                            </ng-container>

                            <!-- color Column -->
                            <ng-container matColumnDef="color">
                                <th mat-header-cell [hidden]="!isColorShow" *matHeaderCellDef mat-sort-header> Color </th>
                                <td mat-cell [hidden]="!isColorShow" *matCellDef="let element"> {{element.color}}</td>
                            </ng-container>

                            <!-- shape Column -->
                            <ng-container matColumnDef="shape">
                                <th mat-header-cell [hidden]="!isShapeShow"  *matHeaderCellDef mat-sort-header> Shape </th>
                                <td mat-cell [hidden]="!isShapeShow" *matCellDef="let element"> {{element.shape}}</td>
                            </ng-container>

                            <!-- marking Column -->
                            <ng-container matColumnDef="marking">
                                <th mat-header-cell [hidden]="!isMarkingColumnShow" *matHeaderCellDef mat-sort-header>Marking</th>
                                <td mat-cell [hidden]="!isMarkingColumnShow" *matCellDef="let element"> {{element.marking}}</td>
                            </ng-container>

                            <!-- description Column -->
                            <ng-container matColumnDef="description">
                                <th mat-header-cell [hidden]="!isDiscriptionShow" *matHeaderCellDef mat-sort-header> Description </th>
                                <td mat-cell [hidden]="!isDiscriptionShow" *matCellDef="let element"> {{element.description}}</td>
                            </ng-container>

                            <!-- packQuantity Column -->
                            <ng-container matColumnDef="packQuantity">
                                <th mat-header-cell [hidden]="!isPackQtyShow" *matHeaderCellDef mat-sort-header>PackQuantity</th>
                                <td mat-cell [hidden]="!isPackQtyShow" *matCellDef="let element"> {{element.packQuantity}}</td>
                            </ng-container>

                            <!-- CostPerPill Column -->
                            <ng-container matColumnDef="costPerPill">
                                <th mat-header-cell [hidden]="isHideCostPerPill" *matHeaderCellDef mat-sort-header> CostPerPill </th>
                                <td mat-cell [hidden]="isHideCostPerPill" *matCellDef="let element"> {{element.costPerPill}}</td>
                            </ng-container>

                            <!-- apw Column -->
                            <ng-container matColumnDef="apw">
                                <th mat-header-cell [hidden]="!isApwColumnShow" *matHeaderCellDef mat-sort-header> APW </th>
                                <td mat-cell [hidden]="!isApwColumnShow"  *matCellDef="let element">
                                    {{element.apw}}
                                </td>
                            </ng-container>

                              <!-- controlSched Column -->
                              <ng-container matColumnDef="controlSched">
                                <th mat-header-cell [hidden]="isHidecontrolSched" *matHeaderCellDef mat-sort-header>Control Sched</th>
                                <td mat-cell [hidden]="isHidecontrolSched" *matCellDef="let element"> {{element.controlSched}}</td>
                            </ng-container>

                            <!-- comment Column -->
                            <ng-container matColumnDef="comment">
                                <th mat-header-cell [hidden]="isHideComment" *matHeaderCellDef mat-sort-header> Comment </th>
                                <td mat-cell [hidden]="isHideComment" *matCellDef="let element"> {{element.comment}}</td>
                            </ng-container>

                            <!-- doNotMix Column -->
                            <ng-container matColumnDef="doNotMix">
                                <th mat-header-cell [hidden]="isHideDontMix" *matHeaderCellDef mat-sort-header>Do Not Mix</th>
                                <td mat-cell [hidden]="isHideDontMix"  *matCellDef="let element"> {{element.doNotMix}}</td>
                            </ng-container>

                            <!-- active Column -->
                            <ng-container matColumnDef="active">
                                <th mat-header-cell [hidden]="!isActiveShow"  *matHeaderCellDef mat-sort-header> Active </th>
                                <td mat-cell [hidden]="!isActiveShow" *matCellDef="let element"> {{element.active}}</td>
                            </ng-container>

                            <!-- min Column -->
                            <ng-container matColumnDef="min">
                                <th mat-header-cell [hidden]="isHideMin" *matHeaderCellDef mat-sort-header> Min </th>
                                <td mat-cell [hidden]="isHideMin" *matCellDef="let element"> {{element.min}} </td>
                            </ng-container>

                            <!-- max Column -->
                            <ng-container matColumnDef="max">
                                <th mat-header-cell [hidden]="isHideMax" *matHeaderCellDef mat-sort-header> Max </th>
                                <td mat-cell [hidden]="isHideMax" *matCellDef="let element">
                                    {{element.max}}
                                </td>
                            </ng-container>

                            <!-- invoiceNumber Column -->
                            <ng-container matColumnDef="invoiceNumber">
                                <th mat-header-cell [hidden]="isHideInvoice" *matHeaderCellDef mat-sort-header>Invoice Number</th>
                                <td mat-cell [hidden]="isHideInvoice" *matCellDef="let element"> {{element.invoiceNumber}}</td>
                            </ng-container>

                            <!-- smartId Column -->
                            <ng-container matColumnDef="smartId">
                                <th mat-header-cell [hidden]="isHideSmartId" *matHeaderCellDef mat-sort-header> SmartId </th>
                                <td mat-cell [hidden]="isHideSmartId" *matCellDef="let element"> {{element.smartId}}</td>
                            </ng-container>

                            <!-- genericSequenceNumber Column -->
                            <ng-container matColumnDef="genericSequenceNumber">
                                <th mat-header-cell  *matHeaderCellDef mat-sort-header> GCN SEQ </th>
                                <td mat-cell *matCellDef="let element"> {{element.genericSequenceNumber}}</td>
                            </ng-container>

                            <!-- assignedLot Column -->
                            <ng-container matColumnDef="assignedLot">
                                <th mat-header-cell [hidden]="isHideAssignedLot" *matHeaderCellDef mat-sort-header> Assigned Lot </th>
                                <td mat-cell [hidden]="isHideAssignedLot" *matCellDef="let element"> {{element.assignedLot}} </td>
                            </ng-container>

                            <!-- assignedExpiration Column -->
                            <ng-container matColumnDef="assignedExpiration">
                                <th mat-header-cell [hidden]="isHideAssignedExp" *matHeaderCellDef mat-sort-header> Assigned Expiration </th>
                                <td mat-cell [hidden]="isHideAssignedExp" *matCellDef="let element">
                                    {{element.assignedExpiration}}
                                </td>
                            </ng-container>

                            <!-- distributor Column -->
                            <ng-container matColumnDef="distributor">
                                <th mat-header-cell [hidden]="isHideDistributor"  *matHeaderCellDef mat-sort-header> Distributor </th>
                                <td mat-cell [hidden]="isHideDistributor" *matCellDef="let element"> {{element.distributor}}</td>
                            </ng-container>

                            <!-- drawerType Column -->
                            <ng-container matColumnDef="drawerType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer Type </th>
                                <td mat-cell *matCellDef="let element"> {{element.drawerType}} </td>
                            </ng-container>

                            <!-- drawer Column -->
                            <ng-container matColumnDef="drawer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drawer </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.drawer}}
                                </td>
                            </ng-container>

                            <!-- unitUsage Column -->
                            <ng-container matColumnDef="unitUsage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Usage </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.usage}}
                                </td>
                            </ng-container>

                             <!-- thirtyDramCapacity Column -->
                             <ng-container matColumnDef="thirtyDramCapacity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> 30 Dram </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.thirtyDramCapacity}}
                                </td>
                            </ng-container>

                            <!-- pillVolume Column -->
                            <ng-container matColumnDef="pillVolume">
                                <th mat-header-cell [hidden]="!isPillVolumeShow" *matHeaderCellDef mat-sort-header> Pill Volume</th>
                                <td mat-cell [hidden]="!isPillVolumeShow" *matCellDef="let element">
                                    {{element.pillVolume}}
                                </td>
                            </ng-container>

                            <!-- atpCanister Column -->
                            <ng-container matColumnDef="atpCanisterNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> #CItem</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.atpCanisterNumber}}
                                </td>
                            </ng-container>

                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                             <!-- Action Details -->
                             <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                        <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                    </mat-icon>
                                    <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="after">
                                        <div class="checkbox-container">
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isApwColumnShow" class="table-dynamic-column flex-row" >
                                            APW
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isMarkingColumnShow" class="table-dynamic-column flex-row" >
                                            Marking
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isQtyInCanisterShow" class="table-dynamic-column flex-row" >
                                            Qty In Canister
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isLotShow" class="table-dynamic-column flex-row" >
                                            Lot
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isStrengthShow" class="table-dynamic-column flex-row" >
                                            Strength
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isShapeShow" class="table-dynamic-column flex-row" >
                                            Shape
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPackQtyShow" class="table-dynamic-column flex-row" >
                                            Pack Qty
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isColorShow" class="table-dynamic-column flex-row" >
                                            Color
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isBarCodeShow" class="table-dynamic-column flex-row" >
                                            BarCode
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPillVolumeShow" class="table-dynamic-column flex-row" >
                                            PillVolme
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isVolumeShow" class="table-dynamic-column flex-row" >
                                            Volume
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isActiveShow" class="table-dynamic-column flex-row" >
                                            Active
                                        </mat-checkbox>
                                        <button style="margin: 1% 1% 5% 20%; height: 30px;" class="table-dyanamic-column btn btn-primary btn-sm">Ok</button>
                                        </div>
                                    </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                        <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsNotOral"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" mat-row *matRowDef="let row; columns: displayedColumnsNotOral;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSourceOral.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumnsNotOral.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </section>
</app-card>
