import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DeploymentTypeMetadata } from '../../models/deploymentForm.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { FleetManagerActions } from 'src/app/store/actions';
import { FleetConfigurationService } from '../../services/fleet-configuration.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { SystemFormService } from '../../services/system-form.service';
import { FleetManagementService } from '../../services/fleet-management.service';
import { MatAccordion } from '@angular/material/expansion';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-deployment-form-dialog',
  templateUrl: './deployment-form-dialog.component.html',
  styleUrls: ['./deployment-form-dialog.component.scss']
})
export class DeploymentFormDialogComponent implements OnInit {
  static systemFormMetadata: DeploymentTypeMetadata[];

  @ViewChild(MatAccordion) accordion: MatAccordion;

  provisioned: boolean;
  selectedDeploymentType: string;
  systemConfigForm: FormGroup;
  accordionCollapsed: boolean = false;
  canEditAdvancedOptions: boolean = false;

  get organizationId() { return this.systemConfigForm.get('organizationId').value; }
  get deploymentLevelParameters(): FormGroup { return this.systemConfigForm.get('deploymentLevelParameters') as FormGroup; }
  get systems(): FormArray { return this.systemConfigForm.get('systems') as FormArray; }
  get formMetadata(): DeploymentTypeMetadata[] { return DeploymentFormDialogComponent.systemFormMetadata }
  get distinctSystemTypes() {
    let names = this.systems.controls.map(control => control.get('name').value);
    return [...new Set(names)];
  }
  get distinctEnabledFeatures() {
    let enabledFeatures = new Set();

    function aggregateFeatureNames(features: any[]) {
      features.forEach(feature => {
        enabledFeatures.add(feature.name);

        if (feature.optionalFeatures) {
          aggregateFeatureNames(feature.optionalFeatures);
        }
      });
    }

    aggregateFeatureNames(this.systems.value);
    return Array.from(enabledFeatures);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public parentCompData: any,
    public dialogRef: MatDialogRef<DeploymentFormDialogComponent>,
    public store: Store<State>,
    public systemFormService: SystemFormService,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private fleetManagementService: FleetManagementService,
    private fleetConfigService: FleetConfigurationService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.provisioned = this.parentCompData.provisioned;
    let edgeDeviceId = this.parentCompData.edgeDeviceId;

    this.authService.profile$.subscribe({
      next: (profile) => {
        this.canEditAdvancedOptions = profile.hasRole("Parata Dev");
      },
      error: (err) => {
        console.error('Could not determine if current user has Parata Dev role, removing advanced options.');
        console.error(err);
        this.canEditAdvancedOptions = false;
      }
    });

    this.systemConfigForm = this.fb.group({
      edgeDeviceId: [edgeDeviceId, Validators.required],
      organizationId: ['', Validators.required],
      edgeDeviceFriendlyName: [''],
      deploymentLevelProperties: this.fb.group({}),
      systems: this.fb.array([])
    });

    // Use local cache for metadata, this will change very rarely
    if (!DeploymentFormDialogComponent.systemFormMetadata) {
      const loadingKey = 'LoadFormMetadata';
      this.ngxLoader.start(loadingKey);

      this.fleetConfigService.getConfigTemplates().subscribe({
        next: metadata => {
          DeploymentFormDialogComponent.systemFormMetadata = metadata;
          this.ngxLoader.stop(loadingKey);
          this.loadDevice(edgeDeviceId);
        },
        error: err => {
          console.error(err);
          this.ngxLoader.stop(loadingKey);
          this.toastService.openToast('Error fetching system form data, cannot configure the device.', constants.ToastPanelType.error);
          this.dialogRef.close(false);
        }
      });
    }
    else {
      this.loadDevice(edgeDeviceId);
    }
  }

  loadDevice(edgeDeviceId: string): void {
    if (!this.provisioned) {
      return;
    }

    const loadingKey = 'LoadProvisionedDevice';
    this.ngxLoader.start(loadingKey);

    this.fleetManagementService.getEdgeDeviceById(edgeDeviceId).subscribe({
      next: edgeDevice => {
        if (!edgeDevice) {
          console.error(`No provisioned device could be located with device ID ${edgeDeviceId}.`);
          this.toastService.openToast(`Provisioned device ${edgeDeviceId} could not be found.`, constants.ToastPanelType.error);
          this.dialogRef.close(false);
        }

        this.systemConfigForm.patchValue({
          organizationId: edgeDevice.organizationId,
          edgeDeviceFriendlyName: edgeDevice.edgeDeviceFriendlyName,
        });

        edgeDevice.systems.forEach(system => {
          let systemForm = this.systemFormService.bindSystemForm(this.getSystemFormMetadata(system.name), system);
          systemForm.addControl('systemFriendlyName', new FormControl(system.systemFriendlyName));
          this.addSystem(systemForm, false);
        });

        this.ngxLoader.stop(loadingKey);
      },
      error: err => {
        console.error(err);
        this.ngxLoader.stop(loadingKey);
        this.toastService.openToast(`Unexpected error fetching provisioned device ${edgeDeviceId}.`, constants.ToastPanelType.error);
        this.dialogRef.close(false);
      }
    });
  }

  getFeatureList(systemType: string, features: Array<any>): string {
    return features.map(feature => {
      let systemFormMetadata = this.getSystemFormMetadata(systemType);
      let featureMetadata = systemFormMetadata.optionalFeatures.find(optionalFeature => optionalFeature.name === feature.name);
      return featureMetadata.friendlyName;
    }).join(', ') || 'N/A';
  }

  getSystemFormMetadata(systemType: string): DeploymentTypeMetadata {
    return DeploymentFormDialogComponent.systemFormMetadata.find(data => data.name === systemType);
  }

  getSystemTypeCount(systemType: string): number {
    return this.systems.value.filter(system => system.name === systemType).length;
  }

  createNewSystem(systemType: string, event: Event) {
    if (event) {
      event.stopPropagation();
    }

    let form = this.getSystemFormMetadata(systemType);

    this.addSystem(this.systemFormService.getSystemForm(form), true);
  }

  addSystem(systemForm: FormGroup, editMode: boolean) {
    systemForm.addControl('isEditMode', new FormControl(editMode));
    this.systems.push(systemForm);
  }

  editSystem(systemFormGroup: FormGroup): void {
    systemFormGroup.patchValue({
      isEditMode: true
    });
  }

  saveSystem(systemFormGroup: FormGroup): void {
    systemFormGroup.markAllAsTouched();

    if (systemFormGroup.valid) {
      systemFormGroup.patchValue({
        isEditMode: false
      });
    }
  }

  deleteSystem(systemIndex: number): void {
    this.systems.removeAt(systemIndex);
  }

  toggleAccordion(): void {
    if (this.accordionCollapsed) {
      this.accordionCollapsed = false;
      this.accordion.openAll();
    }
    else {
      this.accordionCollapsed = true;
      this.accordion.closeAll();
    }
  }

  submit(): void {
    if (!this.systemConfigForm.valid) {
      this.systemConfigForm.markAllAsTouched();
      return;
    }

    let deploymentForm = this.systemConfigForm.getRawValue();
    deploymentForm.systems.forEach(system => {
      this.systemFormService.setFeatureReferences(this.getSystemFormMetadata(system.name), system);
      delete system.isEditMode;
    });

    const loadingKey = 'SystemConfigurationSubmit';
    this.ngxLoader.start(loadingKey);

    let submit$ = this.provisioned 
      ? this.fleetConfigService.putConfigTemplate(deploymentForm) 
      : this.fleetConfigService.postConfigTemplate(deploymentForm);

    submit$.subscribe({
      next: () => {
        setTimeout(() => {
          this.ngxLoader.stop(loadingKey);
          this.toastService.openToast('Systems configured successfully.', constants.ToastPanelType.done);
          this.store.dispatch(FleetManagerActions.getIOTDeviceDetails());
          this.dialogRef.close(true);
        }, 5000);
      },
      error: error => {
        this.ngxLoader.stop(loadingKey);
        console.error(error);

        if (error.status === 500) {
          this.toastService.openToast('Unable to configure systems.', constants.ToastPanelType.error);
        }
        else {
          this.toastService.openToast(error.error, constants.ToastPanelType.error);
        }
      }
    });
  }
}
