<mat-form-field class="flex flex-row xs:flex-1 gt-xs:basis-1/4" floatLabel="never" [formGroup]="form">
  <label for="SystemId" (click)="$event.stopPropagation()">
    <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
    Serial Number
    <custom-tooltip text="Device serial number, found on the nameplate attached to the chassis"></custom-tooltip>
  </label>
  <div class="autocomplete-input flex flex-row">
    <input type="text" matInput appNoHtmlTags trim [formControlName]="controlName" [matAutocomplete]="auto">
    <button mat-button [ngStyle]="{'visibility': (systemSelectControl.value) ? 'visible' : 'hidden'}" mat-icon-button
      aria-label="" (click)="systemSelectControl.setValue('')" [disabled]="systemSelectControl.disabled">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySystem.bind(this)">
    <mat-option class="autoCompleteSystem" *ngFor="let system of filteredSystems$ | async" [value]="system.machineNo" matTooltip="{{system.name}}"
      [disabled]="system.isProvisioned" matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
      <span *ngIf="system.isProvisioned">{{system.name}} ({{system.machineNo}})
        &nbsp;&nbsp;&nbsp;&nbsp;CONFIGURED</span>
      <span *ngIf="!system.isProvisioned">{{system.name}} ({{system.machineNo}})</span>
    </mat-option>
  </mat-autocomplete>
  <mat-error>
    <span *ngIf="systemSelectControl.errors?.['NoOptionsError']">No serial number to provision. </span>
    <span *ngIf="systemSelectControl.errors?.['required']">Serial number required. </span>
    <span *ngIf="systemSelectControl.errors?.['InvalidOptionError']">Serial number is invalid. </span>
  </mat-error>
</mat-form-field>