import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Organization } from 'src/app/internal-user/customer-management/models/organization';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { selectParentOrganizations } from 'src/app/store/selectors/organizations.selector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrls: ['./organization-select.component.scss']
})
export class OrganizationSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup;
  @Input() orgControlName: string;
  @Input() disabled: boolean;

  constructor(
    private store: Store<State>,
    private toastService: ToastService
  ) { }

  orgSubscription: Subscription;
  organizations: Organization[];
  filteredOrg: Organization[];

  get orgSelectControl() { return this.form.get(this.orgControlName) as FormControl<string>; }

  ngOnInit(): void {
    this.orgSubscription = this.store.select(selectParentOrganizations).subscribe({
      next: (parentOrganizations) => {
        this.organizations = parentOrganizations;

        this.orgSelectControl.addValidators(this.validateOrg().bind(this));

        this.orgSelectControl.valueChanges.subscribe({
          next: value => {
            this.filteredOrg = this.filterItems(value || '');
          }
        });

        if (this.orgSelectControl.value) {
          this.orgSelectControl.setValue(this.orgSelectControl.value);
        }
        else {
          this.filteredOrg = this.filterItems('');
        }
      },
      error: (err) => {
        console.error(err);
        this.toastService.openToast('Error loading organization list.', constants.ToastPanelType.error);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.disabled ? this.orgSelectControl.disable() : this.orgSelectControl.enable();
    }
  }

  ngOnDestroy(): void {
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }
  }

  validateOrg(): ValidatorFn {
    return (control: AbstractControl<string, string>): ValidationErrors | null => {
      const orgControlValue = control.value;

      if (!orgControlValue) {
        return null;
      }

      if (!this.organizations || this.organizations.length === 0) {
        return { NoOptionsError: true };
      }

      if (!this.organizations.find(org => org.customerNo === orgControlValue)) {
        return { InvalidOptionError: true };
      }

      return null;
    }
  }

  private filterItems(orgName: string): Organization[] {
    if (!orgName) {
      return this.organizations;
    }

    return this.organizations.filter(org => org.customerNo.toLowerCase().includes(orgName.toLowerCase()));
  }
}
