import { OnInit, Component, HostListener, ViewChild, } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatAccordion } from '@angular/material/expansion';
import { System, Device } from '../../models/device.model';
import { NoDataRow, TableGroup } from 'src/app/shared/models/ui/table-group';
import * as constants from 'src/app/config/app-constants';
import { SearchBarComponent } from 'src/app/shared/components/search-bar/search-bar.component';
import { Asset } from 'src/app/external-user/inventory/models/asset';
import { AssetService } from 'src/app/external-user/inventory/services/asset.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { getProvision } from 'src/app/store/selectors/fleet-manager.selector';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { getLogOptions } from '../../models/getLogOptions.model';
import { FleetManagementService } from '../../services/fleet-management.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastPanelType } from 'src/app/config/app-constants';
import { EdgeApplianceDetailsComponent } from '../edge-appliance-details/edge-appliance-details.component';
import { DeploymentFormDialogComponent } from '../deployment-form-dialog/deployment-form-dialog.component';
import { FleetConfigurationService } from '../../services/fleet-configuration.service';
import { FleetManagerActions } from 'src/app/store/actions';

interface TableRow extends TableGroup, NoDataRow, System {
  index?: number;
}
// basic select
interface SortItemMdl {
  active: string;
  fieldDispName: string;
  sortDirection: string;
  direction: string;
}

interface KeyValue {
  Key: string;
  Value: string;
}

@Component({
  selector: 'app-provisioned',
  templateUrl: './provisioned.component.html',
  styleUrls: ['./provisioned.component.scss']
})

export class ProvisionedComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(SearchBarComponent, { static: true }) childComponent: SearchBarComponent;
  filterText = '';
  displayedColumns: string[] = ['name', 'id', 'status', 'heartbeat', 'actions'];
  provisioned: any;
  selectedOrgId: string = '';
  selectedStatusId: string = '';
  provisionedList = new MatTableDataSource();
  packagerList = new MatTableDataSource<TableRow>([]);
  currentOrgElement: Device;
  currentOrgHeader: string;
  provisionedListData: Device[] = [];
  statusList: KeyValue[] = [];
  orgDDList: KeyValue[] = [];
  downloadingLogs = false;
  activeAssets: Asset[];
  windowWidth = window.innerWidth;
  accordionCollapsed = false;

  @HostListener('window:resize')
  onResize() {
    this.windowWidth = window.innerWidth;
    this.calculateOffset();
  }

  offsetFromBottom: number;

  private calculateOffset() {
    const windowHeight = window.innerHeight;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    // Set the offset value based on your calculation
    this.offsetFromBottom = windowHeight - scrollTop - 245;
  }

  constructor(public dialog: MatDialog,
    public store: Store<State>,
    public fleetManagementService: FleetManagementService,
    public fleetConfigurationService: FleetConfigurationService,
    public assetService: AssetService,
    public toastService: ToastService,
    private ngxLoader: NgxUiLoaderService) {
    this.loadProvisionedData();
  }

  ngOnInit() {
    this.calculateOffset();
    this.bindStatusDropdown();
    this.bindOrgDropdown();
  }

  loadProvisionedData() {
    this.store.select(getProvision).subscribe(res => {
      if (res) {
        if (res) {
          this.assetService.getActiveAssetsByType("5")
            .subscribe((response) => {
              this.activeAssets = response;
              res.forEach(data => {
                data.systems.forEach(system => {
                  system.systemFriendlyName = this.getSystemFriendlyName(system.systemId);
                });
              });
            }, (error) => {
              this.toastService.openToast('Error while getting assets!', constants.ToastPanelType.error);
            });

          this.provisioned = res.sort((a, b) => {
            if (a.organizationId?.toLowerCase() > b.organizationId?.toLowerCase())
              return 1;
            if (a.organizationId?.toLowerCase() < b.organizationId?.toLowerCase())
              return -1;
            return 0;
          });

          this.provisionedListData = this.provisioned;
          this.provisionedList.data = this.provisionedListData;
        }
      }
    });
  }

  toggleRow(element: Device) {
    this.packagerList.data = element.systems;
    this.currentOrgElement = element;
    this.currentOrgHeader = element.organizationId + "- [" + element.edgeDeviceId + "]";
    this.currentOrgElement.enabled = true;
  }

  applyRowClass(row: any): boolean {
    return row && row.status !== 'Online';
  }

  applyColumnClass(element: any): boolean {
    return element && element.status !== 'Online';
  }

  bindStatusDropdown() {
    this.statusList = [{ Key: "all", Value: "All Statuses" }];
    const uniqueStatusIds = new Set();
    this.provisionedListData.forEach((t) => {
      if (!uniqueStatusIds.has(t.connectionState)) {
        this.statusList.push({ Key: t.connectionState.toString(), Value: t.connectionState });
        uniqueStatusIds.add(t.connectionState);
        t.systems.forEach((t) => {
          if (!uniqueStatusIds.has(t.status)) {
            this.statusList.push({ Key: t.status.toString(), Value: t.status });
            uniqueStatusIds.add(t.status);
          }
        });
      }
    });
    // Sort the statusList alphabetically based on the 'Value' (except "All Statuses")
    this.statusList.sort((a, b) => {
      if (a.Value === "All Statuses") return -1; // "All Statuses" comes first
      if (b.Value === "All Statuses") return 1; // "All Statuses" comes first
      return a.Value.localeCompare(b.Value); // Sort other options alphabetically
    });

    this.selectedStatusId = this.statusList[0].Key;
  }

  bindOrgDropdown() {
    this.orgDDList = [{ Key: "all", Value: "All Organizations" }];
    const uniqueCustomerIds = new Set();
    this.provisionedListData.forEach((t) => {
      if (!uniqueCustomerIds.has(t.organizationId)) {
        this.orgDDList.push({ Key: t.organizationId.toString(), Value: t.organizationId });
        uniqueCustomerIds.add(t.organizationId);
      }
    });
    this.selectedOrgId = this.orgDDList[0]?.Key;
  }

  filterChange() {
    this.filterData(this.filterText);
  }

  filterData(filter) {
    this.filterText = filter?.toLowerCase();
    let filterLowerCase = this.filterText;
    this.provisionedListData = [...this.provisioned];
    let filteredData = [...this.provisionedListData];
    let selectedStatus = this.selectedStatusId?.toLowerCase();
    if (filterLowerCase != '' || selectedStatus != "all" || this.selectedOrgId != "all") {
      if (this.selectedOrgId != "all") {
        filteredData = filteredData?.filter((item) => (
          this.selectedOrgId == "all" || item.organizationId?.toString() == this.selectedOrgId?.toString()
        ));
      }
      if (selectedStatus != "all") {
        filteredData = filteredData?.map((item) => {

          const filteredSystems = item?.systems?.filter((child) => (
            selectedStatus != "all" && ((item?.connectionState?.toLowerCase().includes(selectedStatus) ||
              child?.status?.toLowerCase().includes(selectedStatus) || child?.status?.toLowerCase().includes(selectedStatus)))
          ));
          return { ...item, systems: filteredSystems };
        })?.filter(item => item !== null);
      }

      if (filterLowerCase != "") {
        filteredData = filteredData.map((item) => {
          const filteredSystems = item.systems?.filter((child) => (
            (filterLowerCase !== '' && (
              child.systemFriendlyName?.toLowerCase().includes(filterLowerCase) ||
              (child.systemId != null && child.systemId.toLowerCase().includes(filterLowerCase))
              || (item.edgeDeviceId?.toLowerCase().includes(filterLowerCase) || item.edgeDeviceFriendlyName?.toLowerCase().includes(filterLowerCase)
                || item.organizationId?.toLowerCase().includes(filterLowerCase))
            ))
          ));
          return { ...item, systems: filteredSystems };
        }).filter(item => item !== null);
      }
      filteredData = filteredData?.filter((item) => (
        (
          item?.systems?.length > 0)
      ));
    }
    this.provisionedListData = filteredData;
    this.provisionedList.data = this.provisionedListData;
  }

  compareAsc(a, b) {
    if (a && b) {
      if (a?.toLowerCase() > b?.toLowerCase()) { return 1; }
      if (a?.toLowerCase() < b?.toLowerCase()) { return -1; }
    }
    return 0;
  }

  addNewSystemDialog(deviceConfig: Device) {
    const dialogRef: MatDialogRef<DeploymentFormDialogComponent> =
      this.dialog.open(DeploymentFormDialogComponent,
        {
          disableClose: true,
          backdropClass: 'lgDialog',
          data: { edgeDeviceId: deviceConfig.edgeDeviceId, provisioned: true }
        });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== true) {
        const loadingKey = 'AddNewSystemClosed';
        this.ngxLoader.start(loadingKey);
        setTimeout(() => {
          this.filterChange();
          this.ngxLoader.stop(loadingKey);
        }, 1000);
      }
    });
  }

  showEdgeApplianceDetails(deviceConfig: Device) {
    const dialogRef: MatDialogRef<EdgeApplianceDetailsComponent> =
      this.dialog.open(EdgeApplianceDetailsComponent,
        {
          disableClose: true,
          maxWidth: '1024px',
          width: '95%',
          maxHeight: '95%',
          data: { edgeDeviceId: deviceConfig.edgeDeviceId }
        });
    let instance = dialogRef.componentInstance;
    instance.parentCompData = deviceConfig;

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.filterChange();
      }, 1000);
    });
  }

  restartModules(deviceId) {
    const ngxLoaderKey = 'restartModules';
    this.ngxLoader.start(ngxLoaderKey);
    this.fleetManagementService.getRestartModules(deviceId).subscribe(
      (result) => {
        this.ngxLoader.stop(ngxLoaderKey);
        console.log(result);
      },
      (error) => {
        this.toastService.stopLoadingAndShowError('error',
          'An error occurred while restarting modules', ngxLoaderKey);
      });
  }

  restartDevice(deviceId) {
    const ngxLoaderKey = 'restartDevice';
    this.ngxLoader.start(ngxLoaderKey);
    var device = this.provisionedListData.find(dev => dev.edgeDeviceId == deviceId);

    this.fleetManagementService.getRestartDevice(deviceId).subscribe(
      (result) => {
        this.ngxLoader.stop(ngxLoaderKey);
        console.log(result);
        this.toastService.openToast('Restarting Edge Appliance: ' + device.edgeDeviceFriendlyName + ' - ' + deviceId, ToastPanelType.done);
      },
      (error) => {
        this.toastService.stopLoadingAndShowError('error',
          'Unable to complete system restart: ' + device.edgeDeviceFriendlyName + ' - ' + deviceId, ngxLoaderKey);
      });
  }

  deprovisionDevice(deviceId) {
    const ngxLoaderKey = 'deprovisionDevice';
    this.ngxLoader.start(ngxLoaderKey);
    
    var device = this.provisionedListData.find(dev => dev.edgeDeviceId === deviceId);

    this.fleetConfigurationService.deprovisionDevice(deviceId).subscribe({
      next: () => {
        setTimeout(() => {
          this.ngxLoader.stop(ngxLoaderKey);
          this.toastService.openToast('Deprovisioned edge device: ' + device.edgeDeviceFriendlyName + ' - ' + deviceId, ToastPanelType.done);
          this.store.dispatch(FleetManagerActions.getIOTDeviceDetails());
        }, 5000);
      },
      error: error => {
        console.error(error);
        this.toastService.stopLoadingAndShowError('error',
          'Unable to deprovision device: ' + device.edgeDeviceFriendlyName + ' - ' + deviceId, ngxLoaderKey);
      }});
  }

  downloadLogs(deviceId) {
    if (this.downloadingLogs) { return; }
    this.downloadingLogs = true;
    const ngxLoaderKey = 'downloadLogs';
    this.ngxLoader.start(ngxLoaderKey);
    const logOptions: getLogOptions = { tail: '500' };
    this.fleetManagementService.getDeviceLog(deviceId, logOptions).subscribe((result: any) => {
      if (result.size == 0) {
        this.toastService.stopLoadingAndShowError('error',
          'Log file contains no information', ngxLoaderKey);
        this.downloadingLogs = false;
      }
      else {
        this.ngxLoader.stop(ngxLoaderKey);
        const blob = new Blob([result], { type: result.type });
        const blobUrl = window.URL.createObjectURL(blob);
        const fileAnchor = document.createElement('a');
        const filename = `${deviceId}_Logs.zip`;
        fileAnchor.download = decodeURIComponent(filename);
        fileAnchor.href = blobUrl;
        fileAnchor.click();
        this.downloadingLogs = false;
      }
    }, (error) => {
      console.log(error);
      let msgtext = 'An error has occurred';
      if (error.status == 504) {
        msgtext = 'A time out has occurred pulling logs';
      }
      else if (error.status == 500) {
        msgtext = 'Device not responding';
      }
      this.toastService.stopLoadingAndShowError('error',
        msgtext, ngxLoaderKey);
      this.downloadingLogs = false;
    });
  }

  getSystemFriendlyName(systemId: string): string {
    const system = this.activeAssets.find(system => system.machineNo === systemId);
    return system ? `${system.name}` : '';
  }

  toggleAccordion() {
    if (this.accordionCollapsed) {
      this.accordionCollapsed = false;
      this.accordion.openAll();
    }
    else {
      this.accordionCollapsed = true;
      this.accordion.closeAll();
    }
  }
}