import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { DeploymentTypeMetadata, InputType, UserInputPropertySource } from '../models/deploymentForm.model';

@Injectable({
  providedIn: 'root'
})
export class SystemFormService {
  constructor() { }

  getSystemForm(formMetadata: DeploymentTypeMetadata): FormGroup {
    let systemForm = new UntypedFormGroup({
      name: new FormControl(formMetadata.name, Validators.required)
    });

    formMetadata.requiredParameters.forEach(param => {
      let formControl = new FormControl(param.defaultValue, [Validators.required]);
      systemForm.addControl(param.name, formControl);
    });

    formMetadata.optionalParameters.forEach(param => {
      let formControl = new FormControl(param.defaultValue);
      systemForm.addControl(param.name, formControl);
    });

    systemForm.addControl('optionalFeatures', new FormArray([]));

    if (formMetadata.optionalFeatures && formMetadata.optionalFeatures?.length > 0) {
      let optionalFeaturesFormArray: FormArray = systemForm.get('optionalFeatures') as FormArray;
      
      formMetadata.optionalFeatures.forEach(optionalFeature => {        
        if (optionalFeature.enableByDefault) {
          let optionalFeatureMetadata = formMetadata.optionalFeatures.find(feature => feature.name === optionalFeature.name);
          optionalFeaturesFormArray.push(this.getSystemForm(optionalFeatureMetadata));
        }
      });
    }

    return systemForm;
  }

  bindSystemForm(formMetadata: DeploymentTypeMetadata, formModel: any): FormGroup {
    if (formMetadata.name !== formModel.name) {
      console.warn(`Binding failed - metadata key ${formMetadata.name} did not match with system form key ${formModel.name}.`);
    }

    let systemForm = this.getSystemForm(formMetadata);

    // This type conversion will eventually be handled on the server, and can then be removed
    formMetadata.requiredParameters.forEach(requiredParam => {
      if (requiredParam.userInputType === InputType.CheckBox && typeof formModel[requiredParam.name] === 'string') {
        formModel[requiredParam.name] = formModel[requiredParam.name].toLowerCase() === 'true';
      }
    });
    formMetadata.optionalParameters.forEach(optionalParam => {
      if (optionalParam.userInputType === InputType.CheckBox && typeof formModel[optionalParam.name] === 'string') {
        formModel[optionalParam.name] = formModel[optionalParam.name].toLowerCase() === 'true';
      }
    });

    if (formModel.optionalFeatures && formModel.optionalFeatures?.length > 0) {
      let optionalFeaturesFormArray: FormArray = systemForm.get('optionalFeatures') as FormArray;
      optionalFeaturesFormArray.clear();
      
      formModel.optionalFeatures.forEach(optionalFeature => {
        let optionalFeatureMetadata = formMetadata.optionalFeatures.find(feature => feature.name === optionalFeature.name);
        optionalFeaturesFormArray.push(this.bindSystemForm(optionalFeatureMetadata, optionalFeature));
      });
    }

    systemForm.patchValue(formModel);

    return systemForm;
  }

  getFeatureLevelProperties(formMetadata: DeploymentTypeMetadata[]): FormControl[] {
    const uniqueProperties = new Map<string, FormControl>();

    function aggregateFeatureLevelControls(metadata: DeploymentTypeMetadata[]) {
      metadata.forEach(metadatum => {
        metadatum.deploymentLevelRequiredParameters.forEach(source => {
          if (!uniqueProperties.has(source.name)) {
            uniqueProperties.set(source.name, new FormControl(source.defaultValue, [Validators.required]));
          }
        });

        metadatum.deploymentLevelRequiredParameters.forEach(source => {
          if (!uniqueProperties.has(source.name)) {
            uniqueProperties.set(source.name, new FormControl(source.defaultValue));
          }
        });

        if (metadatum.optionalFeatures) {
          aggregateFeatureLevelControls(metadatum.optionalFeatures);
        }
      });
    }

    aggregateFeatureLevelControls(formMetadata);
    return Array.from(uniqueProperties.values());
  }

  setFeatureReferences(formMetadata: DeploymentTypeMetadata, formModel: any): void {
    // Iterate through all params in the feature
    formMetadata.requiredParameters.forEach(param => this.setParameterReferences(param, formModel[param.name], formModel));
    formMetadata.optionalParameters.forEach(param => this.setParameterReferences(param, formModel[param.name], formModel));

    // Do it again recursively for each subfeature, if it is enabled on the form
    formMetadata.optionalFeatures.forEach(optionalFeatureMetadata => {
      let optionalFeatureModel = formModel.optionalFeatures.find(model => model.name === optionalFeatureMetadata.name);

      if (optionalFeatureModel) {
        this.setFeatureReferences(optionalFeatureMetadata, optionalFeatureModel);
      }
    });
  }

  // Take value and copy it recursively to all optional features on the form that need it
  private setParameterReferences(param: UserInputPropertySource, value: any, formModel: any) {
    if (!param.referencedByFeatures || param.referencedByFeatures.length === 0) {
      return;
    }

    if (value === undefined) {
      console.warn(`Parameter '${param.name}' is referenced by other features but not present on the form.`);
      return;
    }

    formModel.optionalFeatures.forEach(optionalFeature => {
      if (param.referencedByFeatures.includes(optionalFeature.name)) {
        optionalFeature[param.name] = value;
      }

      optionalFeature.optionalFeatures.forEach(subFeature => this.setParameterReferences(param, value, subFeature));
    });
  }
}
