import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {  CellLocatedSheet, CommonEntity, CustomerDrugCellLocation, CustomerDrugInfo, DrugCellLog, ListOfDrugLocation } from '../../models/customer-drug-info';
import { IMToolService } from '../../services/im-tool.service';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from "src/app/config/app-constants";
import { ImportWizardCddbComponent } from '../import-wizard-cddb/import-wizard-cddb.component';
import { ImportWizardGcnComponent } from '../import-wizard-gcn/import-wizard-gcn.component';
import { GcnDrugs } from '../../models/gcn-drugs';
import { ImportWizardComponent } from '../import-wizard/import-wizard.component';
import { ManufacturingAnalysisExcel } from '../../models/manufacture-analysis';
import { ExportFileInfo } from '../../models/export-file-info';
import { ExcelExportService } from '../../services/excel-export-service.service';
import { AllDrugs, AnalysisTabDrug, CellLocatorSheet, ExcludedDrug, NewTabDrug, NotOralSolidDrug } from '../../models/max-drugs';
import { ImportWizardDrugMovingComponent } from '../import-wizard-drug-moving/import-wizard-drug-moving.component';
import { MoveDrug } from '../../models/move-drugs';
import {CellConfigurationXml} from '../../models/cell-configuration';




@Component({
  selector: "app-pass-drug-list",
  templateUrl: "./pass-drug-list.component.html",
  styleUrls: ["./pass-drug-list.component.scss"],

})

export class PassDrugListComponent implements OnInit {
  manufacturingAnalyzeExcelModel:ManufacturingAnalysisExcel={};
  searchText:string='';
  drugList: CustomerDrugInfo;
  celLogList: DrugCellLog[] = [];
  drugCellSize:CommonEntity[]=[];
  drugCellLocationUpdateList: ListOfDrugLocation;
  drugCellLocationList: CustomerDrugCellLocation[] = [];
  analysisDrugList: AnalysisTabDrug[] = [];
  notAvailableDrugList: NewTabDrug[] = [];
  excludedDrugList: ExcludedDrug[] = [];
  notOralDrugList: NotOralSolidDrug[] = [];
  allDrugsList: AllDrugs[]= [];
  gcnDrugList: GcnDrugs[] = [];
  cellLocatorSheetList:CellLocatorSheet[]=[];
  excelExportFileInfo: ExportFileInfo;
  //#region  Table datasourse
  dataSource = new MatTableDataSource<AnalysisTabDrug>();
  datasourceNotAvailable = new MatTableDataSource<AnalysisTabDrug>();
  dataSourceOral = new MatTableDataSource<NotOralSolidDrug>();
  dataSourceExcluded = new MatTableDataSource<ExcludedDrug>();
  dataSourceNew = new MatTableDataSource<NewTabDrug>();
  dataSourceAll = new MatTableDataSource<AllDrugs>
  dataSourcecellLocatorSheet = new MatTableDataSource<CellLocatorSheet>();
  //#endregion
  @ViewChild(MatTable, { static: true }) drugTabletable: MatTable<any>;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("table", { read: MatSort, static: true }) sort: MatSort;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild("table2", { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild("table3", { read: MatSort, static: true }) sort3: MatSort;
  @ViewChild("table4", { read: MatSort, static: true }) sort4: MatSort;
  @ViewChild("table5", { read: MatSort, static: true }) sort5: MatSort;
  @ViewChild("TABLE") table: ElementRef;
  @ViewChild("TABLE1") table1: ElementRef;
  @ViewChild("TABLE2") table2: ElementRef;
  @ViewChild("TABLE3") table3: ElementRef;
  @ViewChild("TABLE4") table4: ElementRef;
  @ViewChild("table5") table5: ElementRef;
  @ViewChild("table6") table6: any;
  @Output() sortChange: EventEmitter<MatSort> = new EventEmitter<MatSort>();
  @ViewChild('worksheet') worksheet: ElementRef;
  selectedDrugSystem: number = 1;
  xmlAnalysisFormatData:CellConfigurationXml = new CellConfigurationXml();
  orderOrUsage:string='# of Orders';
  phllDispLable:string='Pills Disp';
  analysisData:number;
  //#region Table column showing or not showing boolean variables

  //Dynamic column show
  isCellCapShow: boolean = true;
  isDramShow: boolean = true;
  isSuperCellCapShow: boolean = false;
  isRatioShow: boolean = false;
  isPackageSizeShow: boolean = false;
  ismanufacturerShow: boolean = true;
  isTabShow: boolean = false;
  isautomateShow: boolean = false;
  isrecSuperCellShow: boolean = false;
  isrecLockingCellShow: boolean = false;
  ispackageSizefitstandardcellShow: boolean = false;
  isotcShow: boolean = false;
  isStrengthShow:boolean = false;

  // Not Available Table
  ismanufacturerNotAvailableShow: boolean = true;
  isNonCountReasonNotAvailableShow: boolean = false;
  isSCHNotAvailablehow: boolean = false;
  isStrengthNotAvailableShow:boolean = false;



  //Excluded Table//
  isExcludedReasonShow: boolean = false;
  isToOrderExcludeShow: boolean = true;
  isrecSuperCellExcludeShow: boolean = false;
  isRatioExcludeShow: boolean = false;
  ismanufacturerExcludeShow: boolean = true;
  isrecLockingCellExcludeShow: boolean = false;
  isSuperCellCapExcludeShow: boolean = false;
  isPackageSizeExcludeShow: boolean = false;
  isThirtyDramExcludeShow: boolean = true;
  isPackageSizeFitExcludeShow: boolean = false;
  isCellCapacityExcludeShow: boolean = true;
  isStrengthExcludeShow:boolean= false;
  isRxQtyShow:boolean= false;
  isUnitQty:boolean= false;
  //Not Oral Solid Table//
  ismanufacturerOralSolidShow: boolean = false;
  isDramOralSolidShow: boolean = false;
  isCellCapOralSolidShow: boolean = false;
  isSuperCellCapOralSolidShow: boolean = false;
  isPackageSizeOralSolidShow: boolean = false;
  isSchOralSolidShow: boolean = false;
  isrecLockingCellOralSolidShow: boolean = false;
  isStrengthNotOralShow:boolean = false
  //#endregion

  // Selected Row Color Change key
  selectedRow;

  isShowCellLocOnMouseHover: boolean = false;

  customerName: string = "";
  targetInstallDate: string = "";
  subDeviceTypeName: string = "";
  deviceId: number;
  deviceName: string = "";

  //#region Cell count variables
  largeCanisterCount: number;
  smallCanisterCount: number;

  //#endregion

  //#region Table colums Array
  displayedColumns: string[] = [
    "checkbox",
    "topOrder",
    // "cellLoc",
    "ndc",
    "drugName",
    'slotLocation',
    "strength",
    "thirtyDramCapacity",
    'schedule',
    "manufacturer",
    "rxQty",
    "unitQty",
    'canisterCapacity',
    'extenderCapacity',
    'canisterNo',
    'largeCanisterCapacity'
  ];
  displayColumnNotAvialable: string[] = [
    "checkbox",
    'topOrder',
    'ndc',
    'drugName',
    'strength',
    'manufacturer',
    'sch',
    'status',
    'nonCountReason',
    'rxQty',
    'unitQty',
  ];

  displayedColumnNewDrug: string[] = [
   "checkbox",
    'topOrder',
    'ndc',
    'drugName',
    'strength',
    'manufacturer',
    'sch',
    "status",
    'rxQty',
    'unitQty',
  ];
 
  displayColumnAll: string[] = [
    'tab',
    'topOrder',
    'ndc',
    'drugName',
    'slotLocation',
    'strength',
    'manufacturer',
    'schedule',
    'rxQty',
    'unitQty',
    'thirtyDramCapacity',
    'canisterCapacity',
    'extenderCapacity',
    'canisterNo',
    'largeCanisterCapacity'
  ];

  //#endregion

  loading: boolean = true;
  customerId: string;

  // current which tab selected
  tabSelected: number = 0;
  currentFilterSize:number = 0;


  // All selected checkbox propertities
  isAnyDrugSelected: boolean = false;


  moveDrug:MoveDrug = {} as MoveDrug;
  //isScrollButtonShow:boolean=false;

  @HostListener("window:resize")
  onResize() {
    if (window.innerWidth > 900) {
    }
  }
  constructor(
    private dialog: MatDialog,
    private imtoolService: IMToolService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private activateRoute: ActivatedRoute,
    private toastService: ToastService,
    private excelExportService: ExcelExportService,
  ) {

  }

  ngOnInit(): void {

    this.activateRoute.paramMap.subscribe((params) => {
      this.customerId = params.get("customerid");
    });
    this.getAvailableCellLocation(this.customerId);
    this.getRecommendedDrugData(this.customerId, 0);
    this.analysisData=this.dataSource.data.length;
  }

    ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.datasourceNotAvailable.sort = this.sort1;
    this.dataSourceNew.sort = this.sort2;
    this.dataSourceExcluded.sort = this.sort3;
    this.dataSourceOral.sort = this.sort4;
    this.dataSourceAll.sort = this.sort5;
  }




  onAllUserPaginateChange() {
    const matTable= document.getElementById('matTable');
    matTable.scrollIntoView();
  }

  allSelectedDrugUnchecked(){
    if (this.drugCellLocationList.length>0){
      for (let i = 0; i < this.drugCellLocationList.length; i++) {
        let index = this.dataSource.data.findIndex(
          (x) => x.fileUploadDrugId == this.drugCellLocationList[i].fileUploadDrugId
        );
        this.dataSource.data[index].checked = false;
      }
      this.drugCellLocationList=[];
    }
  }

  drugCellLocationChecked(row: any, isChecked: boolean) {
    if (isChecked) {
      this.drugCellLocationList.push({
        fileUploadDrugId: row.fileUploadDrugId,
        cellLoc: row.cellLoc,
        drugName: row.name,
        cellType: row.cellType,
        processedDrugId:row.processedDrugId
      });
      this.isAnyDrugSelected = true;
    } else {
      let index = this.drugCellLocationList.findIndex(
        (x) => x.fileUploadDrugId == row.fileUploadDrugId
      );
      this.drugCellLocationList.splice(index, 1);
      if(this.drugCellLocationList.length==0){
        this.isAnyDrugSelected = false;
      }
    }
  }

  getAvailableCellLocation(customerId) {
    const AVAILABLECELLLOCATION = 'Availablecelllocation'
    this.ngxLoader.start(AVAILABLECELLLOCATION);
    this.imtoolService.getAvailableCellLocation(customerId).subscribe(
      (response) => {
        let result = response.data;
        this.celLogList = [];
       result?.forEach(x=>{
        this.celLogList.push({
          id: x.cellLoc,
          value: x.cellLoc,
        });
      });
       this.ngxLoader.stop(AVAILABLECELLLOCATION);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(AVAILABLECELLLOCATION);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  drugCellLocationChanged(row: any) {

    if (!this.isAlreadyCellLocationUsed(row)) {
      for (let i = 0; i < this.drugCellLocationList.length; i++) {
        if (
          this.drugCellLocationList[i].fileUploadDrugId == row.fileUploadDrugId
        ) {
          this.drugCellLocationList[i].cellLoc = row.cellLoc;
          break;
        }
      }
    }
  }

  isAlreadyCellLocationUsed(row: any): boolean {
    for (let i = 0; i < this.drugCellLocationList.length; i++) {
      if (this.drugCellLocationList[i].cellLoc == row.cellLoc) {
        this.toastService.openToast(
          `drug (${this.drugCellLocationList[i].drugName}) has already used this cell location`,
          constants.ToastPanelType.warning,
          2
        );
        this.replaceTheOldCellLocation(row);
        return true;
      }
    }
    return false;
  }


  isDrugCellValidForThisRow(row: any):boolean{
    let cellType = row.cellLoc.substring(3);
     if(row.recSuperCell==="Yes" && cellType ==="Regular"){
      this.toastService.openToast(
        `Super cell can not be added to Regular Cell Location`,
        constants.ToastPanelType.warning,
        2
      );
     this.replaceTheOldCellLocation(row);
      return false;
     }
        else if(row.recSuperCell==="No" && cellType==="Super"){
         this.openAleftWizardForValidateDrug(row)
      }
      return true;




  }
openAleftWizardForValidateDrug(row:any){

  const dialogRef = this.dialog.open(AlertWizardComponent, {
    backdropClass: ['smDialog'],
    disableClose: true,
    data: {
      alertIcon: "/assets/img/icons/ico.16.warning.svg",
      title: 'Unoccupied Space Warning',
      bodyMessage: 'Are you sure you want to add a regular cell drug to a Super cell location',
      bodyMessage2: "",
      cancelBtnText: "Cancel",
      confirmBtnText: "Ok",
      confirmButtonColor: "btn-light-blue",
      cancelButtonColor: "btn-light-red",
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (!result.data) {
      this.replaceTheOldCellLocation(row);
    }
  });
}


  replaceTheOldCellLocation(row: any){
    let index = this.dataSource.data.findIndex(
      (x) => x.ndc == row.ndc
    );
    this.dataSource.data[index].cellLoc = row.cellLocOld;
    index = this.drugCellLocationList.findIndex((x)=> x.fileUploadDrugId== row.fileUploadDrugId)
    this.drugCellLocationList[index].cellLoc = row.cellLocOld;
  }



  getCurrentTabSelect(tab: any) {
    this.updateMainDataSourceArray(this.tabSelected)
    this.tabSelected = tab.index;
    this.drugCellLocationList = [];
  }
  updateMainDataSourceArray(tab:number){
    if(tab===0)
      {
          this.getTheSelectedDrugList().forEach(x=> {
            let index=  this.dataSource.data.findIndex(y=>y.processedDrugId == x)
            this.dataSource.data[index].checked = false;
            this.isAnyDrugSelected = false;
          } );
      }
      else if(tab === 1){
        this.getTheSelectedDrugList().forEach(x=> {
          let index=  this.dataSourceNew.data.findIndex(y=>y.processedDrugId == x)
          this.dataSourceNew.data[index].checked = false;
        } );
      }
      else if(tab === 2){
        this.getTheSelectedDrugList().forEach(x=> {
          let index=  this.dataSourceExcluded.data.findIndex(y=>y.processedDrugId == x)
          this.dataSourceExcluded.data[index].checked = false;
        } );
      }

        else if(tab === 3){
          this.getTheSelectedDrugList().forEach(x=> {
            let index=  this.dataSourceOral.data.findIndex(y=>y.processedDrugId == x)
            this.dataSourceOral.data[index].checked = false;
          } );
        }

  }

  getTheSelectedDrugList(): number[]{
    return this.drugCellLocationList.map((x) => x.processedDrugId)
  }

  downloadFile(data:number){
    if(data==1){
      this.ExportToExcelFromJSON();
    }

  }
  formateNdc(ndc: string):string{
    if(ndc?.length=== 11){
      return`${ndc.substring(0, 5)}-${ndc.substring(5, 9)}-${ndc.substring(9, 11)}`
    }
    return ndc;
  }

  configurTheUnconfiguredDrugandLocation(){
    let unconfiguredDrugs:CellLocatorSheet[] = [];
    let totalUnconfiguredDrug = this.manufacturingAnalyzeExcelModel.unConfiguredRegular + 
                                this.manufacturingAnalyzeExcelModel.unConfiguredRegularLocking + 
                                this.manufacturingAnalyzeExcelModel.unConfiguredSuper + 
                                this.manufacturingAnalyzeExcelModel.unConfiguredSuperLocking;
    
    let entriesToCreate = Math.max(totalUnconfiguredDrug, this.celLogList.length);
    for (let i = 0; i < entriesToCreate; i++) {
      unconfiguredDrugs.push({
          sl: null,
          drugName: '',
          baffle: null, 
          cell: this.celLogList[i] ? this.celLogList[i].value : '',
          height: null, 
          maxCapacity: null, 
          supercellMaxCapacity: null, 
          ndc:'DO NOT APPLY LABEL',
          pressure: null, 
          isSuperCell: '',
          thirtyDramCapacity: null, 
          width: '',
          cellType: '',
          drugStatus: '',
          labelName: 'UNCONFIGURED CELL',
          specialCell: ''
      });
    }

      return  unconfiguredDrugs 
  }



  ExportToExcelFromJSON(){
    const EXPORTEXCELFROMJSON_KEY = 'Exortexcelfromjsonkey'
    this.ngxLoader.start(EXPORTEXCELFROMJSON_KEY);
    const analysisData = [];

    this.analysisDrugList.forEach(x => {
      analysisData.push({
        'Top Order': x.topOrder,
        'Drug Number': x.ndc,
        'Drug Name': x.name,
        'Slot Location': x.cellLoc,
        'Strength': x.strength,
        'Manufacturer': x.manufacturer,
        'Schedule': x.sch,
        'Rx Qty': this.orderOrUsage === '# of Orders' ? x.noOfOrder : x.usage,
        'Unit Qty': this.phllDispLable === 'Pills Disp' ? x.usage : x.noOfOrder,
        'Thirty Dram Capacity': this.dcrRplace(x.thirtyDramCapacity),
        'Canister Capacity': x.cellCapacity,
        'Extender Capacity': x.extenderCapacity,
        'Canister No': x.ratio,
        'Large Canister Capacity': x.largeCanisterCapacity
      });
    });
    
    const notAvailableData = [];

    // Define an empty object with the specified column names
    const emptyObject = {
      'Top Order': '',
      'Drug Number': '',
      'Drug Name': '',
      'Strength': '',
      'Manufacturer': '',
      'SCH': '',
      'Status': '',
      'Non-Count Reason': '',
      'Rx Qty': '',
      'Unit Qty': ''
    };
    
    // Add the empty object to the notAvailableData array
    notAvailableData.push(emptyObject);
    let newDrugData: any[] = [];
    this.notAvailableDrugList.forEach(x => {
      newDrugData.push({
        'Top Order': x.topOrder,
        'Drug Number': x.ndc,  // Assuming 'Drug Number' is equivalent to 'NDC'
        'Drug Name': x.drugName,
        'Strength': x.strength,
        'Manufacturer': x.manufacturer,
        'SCH': x.sch,
        'Status': x.status,
        'Rx Qty': x.rxQty,
        'Unit Qty': x.unitQty
      });
    });
    
    let excludedData: any[] = [];
    this.excludedDrugList.forEach(x => {
      excludedData.push({
        'Top Order': x.topOrder,                  // Assuming this field exists in the data
        'Drug Number': x.ndc,                     // Assuming this is equivalent to 'NDC'
        'Drug Name': x.drugName,                  // Use 'Drug Name' directly if available
        'Strength': x.strength,
        'Manufacturer': x.manufacturer,
        'SCH': x.sch,
        'Status': x.status,
        'Rx Qty': x.rxQty,
        'Unit Qty': x.unitQty
      });
    });
    
    let notOralData: any[] = [];
    this.notOralDrugList.forEach(x => {
      notOralData.push({
        'Top Order': x.topOrder,
        'Drug Number': x.ndc,                     // Assuming 'Drug Number' is equivalent to 'NDC'
        'Drug Name': x.drugName,                  // Use 'Drug Name' directly if available
        'Strength': x.strength,
        'Manufacturer': x.manufacturer,
        'SCH': x.sch,
        'Status': x.status,
        'Rx Qty': x.rxQty,
        'Unit Qty': x.unitQty
      });
    });
    
    let allData:any[]=[];
    this.allDrugsList.forEach(x=>{
    allData.push({
      'Tab': x.tab,
      'Top Order': x.topOrder,
      'Drug Number': x.ndc,
      'Drug Name': x.drugName,
      'Slot Location': '',
      'Strength': x.strength,
      'Manufacturer': x.manufacturer,
      'Schedule': x.schedule,
      'Rx Qty': x.rxQty,
      'Unit Qty': x.unitQty,
      'Thirty Dram Capacity': x.thirtyDramCapacity,
      'Canister Capacity': x.cellCapacity,
      'Extender Capacity': x.extenderCapacity,
      'Canister No': x.cellLoc,
      'Large Canister Capacity': x.largeCanisterCapacity
    });
  })
    this.excelExportFileInfo={
      fileName:`Druglist-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
       this.getCurrentDate()
      }.xlsx`,
      templates:[
        {
          data: analysisData,
          sheetName:"Available"
        },
        {
          data: notAvailableData,
          sheetName:"Not Available"
        },
        {
          data: newDrugData,
          sheetName:"New Drugs"
        },
        {
          data: notOralData,
          sheetName:"Not Oral Solid"
        },
        {
          data: excludedData,
          sheetName:"Excluded"
        },
     
        {
          data: allData,
          sheetName:"All"
        }
      ]
    }
    this.excelExportService.exportAsExcelFile(this.excelExportFileInfo);
    this.changeStatusToExport();
    this.ngxLoader.stop(EXPORTEXCELFROMJSON_KEY);
  }

  dcrRplace(actualValue):string{
    return actualValue === 'DCR'?'Configure on site': actualValue
  }


  defaultSortSet(columnName:string, dir:any){
    const sortState: Sort = {active: columnName, direction: dir};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  changeStatusToExport() {
    const CHANGESTATUSEXPORT = 'Changestatusexportkey'
    this.ngxLoader.start(CHANGESTATUSEXPORT);
    this.imtoolService.changeStatusToExport(this.customerId).subscribe(
      (result) => {
        this.ngxLoader.stop(CHANGESTATUSEXPORT);
        if (result.statusCode == 200) {
          this.toastService.openToast(
            `Downloaded Successfully. ${ result.message}`,
            constants.ToastPanelType.done,
            2
          );
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(CHANGESTATUSEXPORT);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }
  getRecommendedDrugData(customerId, cellSize) {
    this.currentFilterSize = cellSize;
    const LISTDATALOAD_KEY = 'Listdataloadkey'
    this.ngxLoader.start(LISTDATALOAD_KEY);
    this.xmlAnalysisFormatData = new CellConfigurationXml()
    this.imtoolService.getRecommendedDrugData(customerId, cellSize).subscribe(
      (response) => {

        let result = response.data;

        if(result == null){
          this.ngxLoader.stop(LISTDATALOAD_KEY);
          this.toastService.openToast(
            response.message,
            constants.ToastPanelType.error,
            2
          );
          return
          } ;


        this.manufacturingAnalyzeExcelModel.configuredRegular= result.configuredRegular;
        this.manufacturingAnalyzeExcelModel.configuredRegularLocking = result.configuredRegularLocking;
        this.manufacturingAnalyzeExcelModel.configuredSuper = result.configuredSuper;
        this.manufacturingAnalyzeExcelModel.configuredSuperLocking = result.configuredSuperLocking;
        this.manufacturingAnalyzeExcelModel.totalConfigured = result.totalConfigured;
        this.manufacturingAnalyzeExcelModel.unConfiguredRegular = result.unConfiguredRegular;
        this.manufacturingAnalyzeExcelModel.unConfiguredRegularLocking = result.unConfiguredRegularLocking;
        this.manufacturingAnalyzeExcelModel.unConfiguredSuper = result.unConfiguredSuper;
        this.manufacturingAnalyzeExcelModel.unConfiguredSuperLocking = result.unConfiguredSuperLocking;
        this.manufacturingAnalyzeExcelModel.shippingCity = result.shippingCity;
        this.manufacturingAnalyzeExcelModel.shippingPostalCode = result.shippingPostalCode;
        this.manufacturingAnalyzeExcelModel.shippingState = result.shippingState;
        this.manufacturingAnalyzeExcelModel.shippingStreet = result.shippingStreet;
        this.manufacturingAnalyzeExcelModel.accountNumber = result.accountNumber;

        this.orderOrUsage = result.orderOrUsage == true?'# of Orders': 'Pills Disp';
        this.phllDispLable = result.orderOrUsage == true?'Pills Disp': '# of Orders';
     

      
        this.customerName = result.customerName;
        this.targetInstallDate = result.targetInstallDate;
        this.subDeviceTypeName = result.deviceTypeName;
        this.deviceId= result.deviceId;
        this.deviceName = result.deviceName;

        this.smallCanisterCount = result.smallCanisterCount;
        this.largeCanisterCount = result.largeCanisterCount;

        this.analysisDrugList = [];
        this.notAvailableDrugList = [];
        this.excludedDrugList =[];
        this.notOralDrugList =[];
        this.gcnDrugList=[];
        this.cellLocatorSheetList = [];

        result.drugs?.forEach((x) => {
          this.analysisDrugList.push({
            topOrder:x.topOrder,
            checked: false,
            fileUploadDrugId: x.processedDrugId, // after discussion change this code( old code): x.fileUploadDrugId
            ndc: x.ndc,
            name:x.name,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            schedule: x.schedule,
            rxQty: x.noOfOrder,
            unitQty: x.usage,
            thirtyDramCapacity: x.thirtyDramCapacity,
            cellCapacity: x.cellCapacity,
            superCellCapacity: x.superCellCapacity,
            ratio: x.ratio,
            packageSizefitstandardcell: x.packageSizefitstandardcell,
            packageSize: x.packageSize,
            recSuperCell: x.recSuperCell,
            recLockingCell: x.recLockingCell,
            tab: x.tab,
            automate: x.automate,
            cellLoc: x.cellLoc,
            cellLocOld: x.cellLoc,
            cellType: x.cellType,
            drugName: x.drugName,
            strength: x.strength,
            manufacturer: x.manufacturer,
            sch: x.sch,
            status:x.status,
            processedDrugType:x.processedDrugType,
            drugStatus: x.drugStatus,
            genericSequenceNumber:x.genericSequenceNumber,
            processedDrugId:x.processedDrugId,
            slotLocation:x.slotLocation,
            canisterCapacity:x.cellCapacity,
            extenderCapacity:x.extenderCapacity,
            canisterNo:x.passCanisterNo,
            largeCanisterCapacity:x.superCellCapacity
          });
        });


        result.notAvailableDrugs?.forEach((x) => {
          this.notAvailableDrugList.push({
            sl:x.topOrder,
            topOrder:x.topOrder,
            fileUploadDrugId: x.processedDrugId, // after discussion change this code( old code): x.fileUploadDrugId
            ndc: x.ndc,
            checked: false,
            drugName: x.drugName,
            status:x.drugStatus,
            name:x.name,
            rxQty: x.noOfOrder,
            unitQty: x.usage,
            strength: x.strength,
            manufacturer: x.manufacturer,
            sch: x.sch,
            nonCountReason:x.nonCountReasonForNotAvailable,
            drugStatus:x.drugStatus,
            genericSequenceNumber:x.genericSequenceNumber,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            schedule: x.schedule,
            thirtyDramCapacity: x.thirtyDramCapacity,
            cellCapacity: x.cellCapacity,
            superCellCapacity: x.superCellCapacity,
            ratio: x.ratio,
            packageSizefitstandardcell: x.packageSizefitstandardcell,
            packageSize: x.packageSize,
            recSuperCell: x.recSuperCell,
            recLockingCell: x.recLockingCell,
            tab: x.tab,
            automate: x.automate,
            processedDrugId:x.processedDrugId,
            slotLocation:x.slotLocation,
            canisterCapacity:x.cellCapacity,
            extenderCapacity:x.superCellCapacity,
            canisterNo:x.passCanisterNo
          });
        });

        result.excludedDrugs?.forEach((x)=>{

          this.excludedDrugList.push({
            sl:x.topOrder,
            fileUploadDrugId: x.processedDrugId, // after discussion change this code( old code): x.fileUploadDrugId
            ndc: x.ndc,
            checked: false,
            drugName: x.drugName,
            status:x.excludedReason,
            name:x.name,
            rxQty: x.noOfOrder,
            unitQty: x.usage,
            strength: x.strength,
            manufacturer: x.manufacturer,
            sch: x.sch,
            topOrder:x.topOrder,
            excludedReason:x.excludedReason,
            recLockingCell:x.recLockingCell,
            thirtyDramCapacity:x.thirtyDramCapacity,
            ratio:x.ratio,
            recSuperCell:x.recSuperCell,
            cellCapacity:x.cellCapacity,
            superCellCapacity:x.superCellCapacity,
            packageSize:x.packageSize,
            packageSizeFit:x.packageSizeFit,
            drugStatus:x.drugStatus,
            genericSequenceNumber:x.genericSequenceNumber,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            schedule: x.schedule,
            packageSizefitstandardcell: x.packageSizefitstandardcell,
            tab: x.tab,
            automate: x.automate,
            processedDrugId:x.processedDrugId,
            slotLocation:x.slotLocation,
            canisterCapacity:x.cellCapacity,
            extenderCapacity:x.superCellCapacity,
            canisterNo:x.passCanisterNo
          })
        })
        0
        result.notOralDrugs?.forEach((x)=>{

          this.notOralDrugList.push({
            sl:x.topOrder,
            checked: false,
            topOrder:x.topOrder,
            fileUploadDrugId: x.processedDrugId, // after discussion change this code( old code): x.fileUploadDrugId
            ndc: x.ndc,
            name:x.name,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            schedule: x.schedule,
            rxQty: x.noOfOrder,
            unitQty: x.usage,
            thirtyDramCapacity: x.thirtyDramCapacity,
            cellCapacity: x.cellCapacity,
            superCellCapacity: x.superCellCapacity,
            ratio: x.ratio,
            packageSizefitstandardcell: x.packageSizefitstandardcell,
            packageSize: x.packageSize,
            recSuperCell: x.recSuperCell,
            recLockingCell: x.recLockingCell,
            tab: x.tab,
            automate: x.automate,
            drugName: x.drugName,
            strength: x.strength,
            manufacturer: x.manufacturer,
            sch: x.sch,
            status:x.status,
            drugStatus: x.drugStatus,
            genericSequenceNumber:x.genericSequenceNumber,
            dosageForm:x.dosageForm,
            processedDrugId:x.processedDrugId,
            slotLocation:x.slotLocation,
            canisterCapacity:x.cellCapacity,
            extenderCapacity:x.superCellCapacity,
            canisterNo:x.passCanisterNo
          })
        })


        result.all?.forEach(x => {
            this.allDrugsList.push({
            topOrder:x.topOrder,
            checked: false,
            fileUploadDrugId: x.processedDrugId, // after discussion change this code( old code): x.fileUploadDrugId
            ndc: x.ndc,
            name:x.name,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            schedule: x.schedule,
            rxQty: x.noOfOrder,
            unitQty: x.usage,
            thirtyDramCapacity: x.thirtyDramCapacity,
            cellCapacity: x.cellCapacity,
            superCellCapacity: x.superCellCapacity,
            ratio: x.ratio,
            packageSizefitstandardcell: x.packageSizefitstandardcell,
            packageSize: x.packageSize,
            recSuperCell: x.recSuperCell,
            recLockingCell: x.recLockingCell,
            tab: x.tab,
            automate: x.automate,
            cellLoc: x.cellLoc,
            cellLocOld: x.cellLoc,
            cellType: x.cellType,
            drugName: x.drugName,
            strength: x.strength,
            manufacturer: x.manufacturer,
            sch: x.sch,
            status:x.status,
            processedDrugType:x.processedDrugType,
            drugStatus: x.drugStatus,
            genericSequenceNumber:x.genericSequenceNumber,
            processedDrugId:x.processedDrugId,
            slotLocation:x.slotLocation,
            canisterCapacity:x.cellCapacity,
            extenderCapacity:x.extenderCapacity,
            canisterNo:x.passCanisterNo,
            largeCanisterCapacity: x.superCellCapacity
          });
        });
        this.dataSource.data = this.analysisDrugList;

        this.dataSourceNew.data = this.notAvailableDrugList;
        this.dataSourceExcluded.data = this.excludedDrugList;
        this.dataSourceOral.data = this.notOralDrugList;
        this.dataSourceAll.data = this.allDrugsList;
        this.ngxLoader.stop(LISTDATALOAD_KEY);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(LISTDATALOAD_KEY);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );

      }
    );
  }
  removeDrugCellLocation() {
    const REMOVEDRUGCELLLOCATION_KEY = 'RemoveDrugCellLocationKey'
    this.ngxLoader.start(REMOVEDRUGCELLLOCATION_KEY);
    this.drugCellLocationUpdateList = {
      customerId: this.customerId,
      addRemoveDrugs: this.drugCellLocationList,
    };
    this.imtoolService.drugRemove(this.drugCellLocationUpdateList).subscribe(
      (result) => {
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );
          window.setTimeout(() => {
            window.location.reload();
          }, 2000);
          this.ngxLoader.stop(REMOVEDRUGCELLLOCATION_KEY);
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
          this.ngxLoader.stop(REMOVEDRUGCELLLOCATION_KEY);
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(REMOVEDRUGCELLLOCATION_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  isCellLocationNotEmpty():boolean{
    return this.drugCellLocationList.some(function (el) {
      return el.cellLoc === "" ||  el.cellLoc === null;
    });
  }

  addDrugCellLocation() {

    if(this.isCellLocationNotEmpty()){
      this.toastService.openToast(
        "Please select a cell location to Add!",
        constants.ToastPanelType.error,
        2
      );
    }
    else{
      const CELLLOCATIONADD_KEY = 'Celllocationaddkey' 
      this.ngxLoader.start(CELLLOCATIONADD_KEY);
      this.drugCellLocationUpdateList = {
        customerId: this.customerId,
        addRemoveDrugs: this.drugCellLocationList,
      };
      this.imtoolService.drugAdd(this.drugCellLocationUpdateList).subscribe(
        (result) => {
          if (result.statusCode == 200) {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.done,
              2
            );
            window.setTimeout(() => {
              window.location.reload();
            }, 2000);
            this.ngxLoader.stop(CELLLOCATIONADD_KEY);
          } else {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.error,
              2
            );
            this.ngxLoader.stop(CELLLOCATIONADD_KEY);
          }
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(CELLLOCATIONADD_KEY);
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
    }

  }

  openAlertWindowForDrugRemove() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Remove Drug ?",
        bodyMessage: "Are you sure, you want to remove this drug?",
        bodyMessage2: this.drugCellLocationList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Remove",
        confirmButtonColor: "btn-light-red",
        cancelButtonColor: "btn-light-blue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.removeDrugCellLocation();
      } else {
        console.log("not export", result);
      }
    });
  }

  openAlertWindowForDrugAdd() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Add Drug?",
        bodyMessage: "Are you sure you want to add this drug?",
        bodyMessage2: this.drugCellLocationList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Add",
        confirmButtonColor: "btn-light-red",
        cancelButtonColor: "btn-light-blue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.addDrugCellLocation();
      } else {
        console.log("not export", result);
      }
    });
  }

  openImportWizardCDDB() {
    const dialogRef = this.dialog.open(ImportWizardCddbComponent, {
      backdropClass: ['lgDialog'],
    disableClose: true,
      data: {
        customerId: this.customerId,
        deviceId: this.deviceId
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.getRecommendedDrugData(this.customerId, 0);
      } else {
        console.log("No change implement", result);
      }
    });
  }

  openImportWizardGCN() {
    const dialogRef = this.dialog.open(ImportWizardGcnComponent, {
    disableClose: true,
    backdropClass: ['lgDialog'],
      data: {
        gcnDrugList: this.gcnDrugList,
        customerId:this.customerId,
        customerName:this.customerName
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.getRecommendedDrugData(this.customerId, 0);
      } else {
        console.log("No change implement", result);
      }
    });
  }
  public doFilter = (value: string) => {
    if (this.tabSelected === 0)
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 1)
      this.datasourceNotAvailable.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 2)
      this.dataSourceNew.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 3)
     this.dataSourceExcluded.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 4)
      this.dataSourceOral.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 5)
      this.dataSourceAll.filter = value.trim().toLocaleLowerCase();
  };


  // openAlertWindow() {
  //   let title =
  //     this.remainingCellCount == 0
  //       ? "Confirm export"
  //       : "Export with empty cell";
  //   let message1 =
  //     this.remainingCellCount == 0
  //       ? "Are you sure you wish to export?"
  //       : "There are currently open Cell Locations for this configuration, are you sure you wish to export?";

  //   const dialogRef = this.dialog.open(AlertWizardComponent, {
  //     backdropClass: ['smDialog'],
  //     disableClose: true,
  //     data: {
  //       alertIcon: "/assets/img/icons/ico.16.warning.svg",
  //       title: title,
  //       bodyMessage: message1,
  //       bodyMessage2: "",
  //       cancelBtnText: "Cancel",
  //       confirmBtnText: "Export",
  //       confirmButtonColor: "btn-light-blue",
  //       cancelButtonColor: "btn-light-red",
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result.data) {
  //       this.ExportToExcelFromJSON();
  //     } else {
  //       console.log("not export", result);
  //     }
  //   });
  // }

  redirectCustomerList() {
    this.router.navigate(["/im-tool"]);
  }
  openImportWizard() {
    const dialogSiteRef = this.dialog.open(ImportWizardComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: { customer: {
        sl:1,
        customerId: this.customerId,
        customerName: this.customerName,
        caseNumber: '',
        customerNumber: '',
        deviceType: '',
        subDeviceTypeName:'',
        lastEdited:'',
        status:'',
        statusDescription: '',
        targetInstallDate: this.targetInstallDate,
      }, isparentnotassociated:2 },
    });
    dialogSiteRef.afterClosed().subscribe((result) => {
        this.getRecommendedDrugData(this.customerId, 0);
        this.getAvailableCellLocation(this.customerId);
    });
  }


  openImportWizardDrugMovingComponent() {

    const dialogRefMoving = this.dialog.open(ImportWizardDrugMovingComponent, {

      backdropClass: ['smDialog'],
      disableClose: true,
      data:{
          title:'',
          listOfData:[{ key:1, value:'Analysis', isSelected: this.tabSelected === 0 },
           { key:2, value:'New Drugs', isSelected: this.tabSelected === 1  },
           { key:3, value:'Excluded', isSelected: this.tabSelected === 2 },
           { key:4, value:'Not Oral Solid', isSelected: this.tabSelected === 3  },]
      }
    });
    dialogRefMoving.afterClosed().subscribe((result) => {
      if(result.data.isClose === true){
      this.onUpload(result.data.selectedTab, this.drugCellLocationList.map((x) => x.processedDrugId))
      }
    });
  }


  onUpload(selectedTab, drugs) {
    if(selectedTab === 0)
        {
          return
        }
  const MOVEDRUG_KEY = 'Movedrugkey'
    this.ngxLoader.start(MOVEDRUG_KEY);
    this.moveDrug = {
      customerId: this.customerId,
      deviceId: this.deviceId,
      processDrugType: selectedTab,
      drugId: drugs,
    }
    this.imtoolService.moveDrugs(this.moveDrug).subscribe(
      (result) => {
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );

          this.ngxLoader.stop(MOVEDRUG_KEY);
          setTimeout(()=>{
            location.reload();
          },2000)

        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
          this.ngxLoader.stop(MOVEDRUG_KEY);
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(MOVEDRUG_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }


  
  getCurrentDate(): string {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {  
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };
    return currentDate.toLocaleDateString('en-US',options);
  }

}
