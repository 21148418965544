<div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">
  <div class="inner-mat-dialog flex flex-col justify-between content-stretch items-stretch w-full">
    <div mat-dialog-title id="drug-details-header">
      <div class="flex justify-start content-center items-center flex-row">

        <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
          <h3>EDIT {{drugName}}</h3>
        </div>
        <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
          <mat-icon (click)="closeDialog(true)" class="cross-wizard">
            <img src="/assets/img/icons/ico.16.clear.svg" alt="">
          </mat-icon>
        </div>

      </div>
    </div>
    <form [formGroup]="saveModelForm" style="overflow:scroll">
      <div class="drug-info flex flex-row">

        <div class="drug-left-side">
          <div class="image-div flex justify-between content-center items-center flex-row">
            <div class="flex flex-row">
              <img (click)="openImportWizardImageSlide()" class="img" [src]="images.serialImage" alt="drug image">
            </div>

            <div class="flex flex-row justify-center content-center items-center h-full">
              <button *ngIf="this.drugCharacteristicsModel.quarantined"
                [style.background-color]="this.drugCharacteristicsModel.quarantinedColor" class="btn-status">
                {{this.drugCharacteristicsModel.quarantined}}</button>

              <button *ngIf="this.drugCharacteristicsModel.drugStatus"
                [style.background-color]="this.drugCharacteristicsModel.drugStatusColor" class="btn-status">
                {{this.drugCharacteristicsModel.drugStatus}}</button>

              <p class="country-value">{{drugCharacteristicsModel.countryCode}}
                <img class="flag-img" *ngIf="drugCharacteristicsModel.countryCode?.length>0"
                  [src]="drugCharacteristicsModel.countryCode === 'USA'? '/assets/img/country/usa.png': drugCharacteristicsModel.countryCode === 'CAN'? '/assets/img/country/canadian.webp':'/assets/img/no-image.png' "
                  style=" height: 25px; padding: 10px; width: 25px;" />
              </p>

            </div>

          </div>

          <div>
            <div class="flex flex-col">
              <h5>Characteristics</h5>
              <div *ngIf="source === 3">
                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[45%]">
                    <span class="label">Generic Name</span>
                    <input type="text" formControlName="genericName">
                  </div>

                  <div class="flex flex-col basis-[30%]">
                    <span class="label">Drug Number</span>
                    <input type="text" formControlName="drugId">
                  </div>

                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Strength</span>
                    <input type="text" formControlName="strength">
                  </div>

                </div>
                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Unit</span>
                    <mat-form-field>
                      <mat-select formControlName="unitId">
                        <mat-option *ngFor="let data of unitList" [value]="data.value">{{data?.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Active</span>
                    <mat-form-field>
                      <mat-select formControlName="drugStatus" ngDefaultControl>
                        <mat-option *ngFor="let data of listOfDrugStatus" [value]="data.value">{{data?.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Schedule</span>
                    <mat-form-field>
                      <mat-select formControlName="scheduleId">
                        <mat-option *ngFor="let data of scheduleList" [value]="data.key">{{data?.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Manufacturer</span>
                    <input type="text" formControlName="manufacturer">
                  </div>
                </div>
                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <!-- <div class="flex flex-col basis-[25%]">
                    <span class="label">ROA</span>
                    <input type="text" formControlName="roa" value="">
                  </div> -->

                  <div class="flex flex-col basis-[25%]">
                    <span class="label">ROA</span>
                    <mat-form-field class="w-full">
                      <input type="text" aria-label="Number" matInput [formControl]="routeOfAdminId"
                        [matAutocomplete]="autoRoa">

                      <mat-autocomplete #autoRoa="matAutocomplete" [displayWith]="displayFnRoa.bind(this)">
                        <mat-option *ngFor="let data of listOfRoa" [value]="data.value">
                          {{data.label}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">OTC</span>
                    <input type="text" formControlName="otc" value="">
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Dosage Form</span>
                    <mat-form-field class="w-full">
                      <input type="text" aria-label="Number" matInput [formControl]="dosageFormId"
                        [matAutocomplete]="autoDosageForm">

                      <mat-autocomplete #autoDosageForm="matAutocomplete"
                        [displayWith]="displayFnDosageForm.bind(this)">
                        <mat-option *ngFor="let data of listOfDosageForm" [value]="data.id">
                          {{data.description}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Niosh</span>
                    <input type="text" formControlName="niosh">
                  </div>
                </div>


                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">AntiBiotic</span>
                    <mat-form-field>
                      <mat-select formControlName="antibiotics" ngDefaultControl>
                        <mat-option [value]="true">true
                        </mat-option>
                        <mat-option [value]="false">false
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Distributor</span>
                    <mat-form-field class="w-full">
                      <input type="text" aria-label="Number" matInput [formControl]="distributorId"
                        [matAutocomplete]="autoDistributor">

                      <mat-autocomplete #autoDistributor="matAutocomplete"
                        [displayWith]="displayFnDistributor.bind(this)">
                        <mat-option *ngFor="let data of listOfDistributor" [value]="data.value">
                          {{data.label}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Unit of Use</span>
                    <mat-form-field>
                      <mat-select formControlName="unitOfUse" ngDefaultControl>
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Unit Dose</span>
                    <mat-form-field>
                      <mat-select formControlName="unitDose" ngDefaultControl>
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <hr />
                </div>

                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[50%]">
                    <span class="label">Storage Condtions</span>
                    <input type="text" formControlName="storageConditions">
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Updated</span>
                    <input type="text" formControlName="updated">
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">GCN #</span>
                    <input type="text" formControlName="gcn">
                    <span class="label-value">{{drugCharacteristicsModel.gcn}}</span>
                  </div>

                  <hr />
                </div>

                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">GPI #</span>
                    <input type="text" formControlName="gpi">
                  </div>

                  <div class="flex flex-col basis-[25%]">
                    <span class="label">FDB Shape</span>
                    <mat-form-field>
                      <mat-select formControlName="shapeId" ngDefaultControl>
                        <mat-option *ngFor="let data of shapeList" [value]="data.value">{{data?.label}}
                        </mat-option>

                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Quarantine Reason</span>
                    <mat-form-field>
                      <mat-select formControlName="quarantineId" ngDefaultControl>
                        <mat-option *ngFor="let data of quarantineReasonList" [value]="data.value">{{data?.label}}
                        </mat-option>

                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Oral Solid</span>
                    <mat-form-field>
                      <mat-select [disabled]="isRecommededShow" formControlName="oralSolid" ngDefaultControl>
                        <mat-option [value]="true">true
                        </mat-option>
                        <mat-option [value]="false">false
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <hr />
                </div>
              </div>              
              <div *ngIf="source != 3">
                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[23%]">
                    <span class="label">Generic Name</span>
                    <span class="label-value">{{drugCharacteristicsModel.genericName}}</span>
                  </div>
                  <div class="flex flex-col basis-[12%]">
                    <span class="label">Strength</span>
                    <span class="label-value">{{drugCharacteristicsModel.strength}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Unit</span>
                    <span class="label-value">{{drugCharacteristicsModel.unit}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Drug Number</span>
                    <span class="label-value">{{drugCharacteristicsModel.drugId}}</span>
                  </div>
                  <div class="flex flex-col basis-[10%]">
                    <span class="label">Active</span>
                    <span class="label-value">{{drugCharacteristicsModel.active}}</span>
                  </div>
                  <div class="flex flex-col basis-[10%]">
                    <span class="label">Schedule</span>
                    <span class="label-value">{{drugCharacteristicsModel.schedule}}</span>
                  </div>
                </div>
                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[23%]">
                    <span class="label">Manufacturer</span>
                    <span class="label-value">{{drugCharacteristicsModel.manufacturer}}</span>
                  </div>
                  <div class="flex flex-col basis-[12%]">
                    <span class="label">ROA</span>
                    <span class="label-value">{{drugCharacteristicsModel.roa}}</span>
                  </div>

                  <div class="flex flex-col basis-1/5">
                    <span class="label">OTC</span>
                    <span class="label-value">{{drugCharacteristicsModel.otc}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Dosage Form</span>
                    <span class="label-value">{{drugCharacteristicsModel.dosageForm}}</span>
                  </div>
                  <div class="flex flex-col basis-[10%]">
                    <span class="label">Niosh</span>
                    <span class="label-value">{{drugCharacteristicsModel.niosh}}</span>
                  </div>
                  <div class="flex flex-col basis-[10%]">
                    <span class="label">AntiBiotic</span>
                    <span class="label-value">{{drugCharacteristicsModel.antibiotics}}</span>
                  </div>
                </div>
                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[23%]">
                    <span class="label">Distributor</span>
                    <mat-form-field>
                      <input type="text" aria-label="Number" matInput [formControl]="distributorId"
                        [matAutocomplete]="autoDistributor">

                      <mat-autocomplete #autoDistributor="matAutocomplete"
                        [displayWith]="displayFnDistributor.bind(this)">
                        <mat-option *ngFor="let data of listOfDistributor" [value]="data.value">
                          {{data.label}}
                        </mat-option>
                        <!-- <mat-option *ngIf="isLoading" class="is-loading">
                        <mat-spinner diameter="25"></mat-spinner>
                      </mat-option> -->
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[12%]">
                    <span class="label">Unit of Use</span>
                    <span class="label-value">{{drugCharacteristicsModel.unitOfUse}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Unit Dose</span>
                    <span class="label-value">{{drugCharacteristicsModel.unitDose}}</span>
                  </div>
                  <!-- <div fxLayout="column" fxFlex="20">
                  <span class="label">Packaging</span>
                  <span class="label-value">{{drugCharacteristicsModel.packaging}}</span>
                </div>
                <div fxLayout="column" fxFlex="10">
                  <span class="label">Package Size</span>
                  <span class="label-value">{{drugCharacteristicsModel.packageSize}}</span>
                </div> -->
                  <div class="flex flex-col basis-[10%]">
                    <span class="label">Updated</span>
                    <span class="label-value">{{drugCharacteristicsModel.updated | date:'shortDate'}}</span>
                  </div>
                  <hr />
                </div>
                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[35%]">
                    <span class="label">Storage Condtions</span>
                    <span class="label-value">{{drugCharacteristicsModel.storageConditions}}</span>
                  </div>
                  <div class="flex flex-col basis-[12%]">
                    <span class="label">GPI #</span>
                    <span class="label-value">{{drugCharacteristicsModel.gpi}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">GCN #</span>
                    <span class="label-value">{{drugCharacteristicsModel.gcn}}</span>
                  </div>

                  <hr />
                </div>
                <div class="row-info flex flex-row gap-[1rem]" style="padding-left: 1rem;">
                  <div class="flex flex-col basis-[23%]">
                    <span class="label">FDB Shape</span>
                    <span class="label-value">{{drugCharacteristicsModel.shape}}</span>
                  </div>

                  <div class="flex flex-col basis-2/5">
                    <span class="label">Quarantine Reason</span>
                    <mat-form-field>
                      <mat-select formControlName="quarantineId" ngDefaultControl>
                        <mat-option *ngFor="let data of quarantineReasonList" [value]="data.value">{{data?.label}}
                        </mat-option>

                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-2/5">
                    <span class="label">Oral Solid</span>
                    <mat-form-field>
                      <mat-select [disabled]="isRecommededShow" formControlName="oralSolid" ngDefaultControl>
                        <mat-option [value]="true">true
                        </mat-option>
                        <mat-option [value]="false">false
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <hr />
                </div>
              </div>

              <h5>General Attributes</h5>
              <div class="row flex gap-[1rem]" style="padding-top:10px;padding-left: 1rem;">
                <div class="flex flex-col basis-[13%]">
                  <span class="label">Length</span>
                  <input type="number" [readonly]="isRecommededShow" formControlName="length">
                </div>
                <div class="flex flex-col basis-[13%]">
                  <span class="label">Width</span>
                  <input type="number" [readonly]="isRecommededShow" formControlName="width">
                </div>
                <div class="flex flex-col basis-[13%]">
                  <span class="label">Thickness</span>
                  <input type="number" [readonly]="isRecommededShow" min="0" [readonly]="isRecommededShow"
                    formControlName="thickness">
                </div>
                <div class="flex flex-col basis-[13%]">
                  <span class="label">Diameter</span>
                  <input type="number" [readonly]="isRecommededShow" formControlName="atpTcgDiameter">
                </div>

                <div class="flex flex-col basis-[13%]">
                  <span class="label">Piece Weight</span>
                  <input type="number" [readonly]="isRecommededShow" formControlName="pieceWeight">
                </div>
                <div class="flex flex-col basis-[13%]">
                  <span class="label">Diagonal</span>
                  <input type="number" min="0" [readonly]="isRecommededShow" formControlName="diagonal">
                </div>
              </div>
              <div class="row flex gap-[1rem]" style="padding-top:10px;padding-left: 1rem;">
                <div class="flex flex-col basis-[28%]">
                  <span class="label">Classification Shape</span>
                  <mat-form-field>
                    <mat-select [disabled]="isRecommededShow" formControlName="classificationShapeId" ngDefaultControl>
                      <mat-option *ngFor="let data of listOfClassificationShape" [value]="data.value">{{data?.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="flex flex-col basis-[16%]">
                  <span class="label">Pill Volume</span>
                  <input type="number" formControlName="atpTcgPillVolume" />
                </div>

                <div class="flex flex-col basis-[16%]">
                  <span class="label">T1</span>
                  <input type="number" formControlName="atpTcgT1">
                </div>

                <div class="flex flex-col basis-[16%]">
                  <span class="label">T2</span>
                  <input type="number" formControlName="atpTcgT2">
                </div>
              </div>


              <div style="padding-top:10px;padding-left: 1rem;" class="flex flex-row gap-5">
                <div class="flex flex-col basis-[23%]">
                  <span class="label">30 Dram Capacity</span>
                  <input type="number" formControlName="packageThirtyDramCapacity" value="" />
                </div>
                <div class="flex flex-col basis-1/5">
                  <div class="flex flex-row">
                    <span style="color:red">*</span><span class="label">Coatings</span>
                  </div>
                  <mat-form-field>
                    <mat-select formControlName="coatingId" ngDefaultControl>
                      <mat-option *ngFor="let data of listOfCoating" [value]="data.key">{{data?.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    *ngIf="saveModelForm.controls.coatingId.hasError('required') && saveModelForm.controls.coatingId.touched">
                    <mat-error *ngIf="saveModelForm.controls.coatingId.hasError('required')">Coating is
                      required</mat-error>
                  </div>
                </div>
                <div class="flex flex-col basis-[19%]">
                  <span class="label"> Imprint 1</span>
                  <input type="text" formControlName="packageImprint1" value="" />
                </div>
                <div class="flex flex-col basis-[19%]">
                  <span class="label"> Imprint 2</span>
                  <input type="text" formControlName="packageImprint2" value="" />
                </div>

              </div>
              <div style="padding-top: 10px; padding-left: 1rem;" class="flex flex-row">
                <div style="margin-right: 20px;" class="flex flex-col basis-[19%]">
                  <div class="flex flex-row">
                    <span style="color:red">*</span> <span class="label">Color</span>
                  </div>

                  <mat-form-field>
                    <input type="text" aria-label="Number" matInput [formControl]="colorId"
                      [matAutocomplete]="autoColor">
                    <mat-autocomplete #autoColor="matAutocomplete" [displayWith]="displayFnColor.bind(this)">
                      <mat-option *ngFor="let data of listOfColor" [value]="data.value">
                        {{data.label}}
                      </mat-option>
                      <mat-option *ngIf="isLoading" class="is-loading">
                        <mat-spinner diameter="25"></mat-spinner>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div
                    *ngIf="saveModelForm.controls.colorId.hasError('required') && saveModelForm.controls.colorId.touched">
                    <mat-error *ngIf="saveModelForm.controls.colorId.hasError('required')">Color is required</mat-error>
                  </div>
                </div>

                <div style="margin-right: 20px;" class="flex flex-col basis-1/5">
                  <div class="flex flex-row">
                    <span style="color:red">*</span><span class="label">Scoring</span>
                  </div>
                  <mat-form-field>
                    <mat-select formControlName="scoringId" ngDefaultControl>
                      <mat-option *ngFor="let data of listOfScoaring" [value]="data.key">{{data?.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    *ngIf="saveModelForm.controls.scoringId.hasError('required') && saveModelForm.controls.scoringId.touched">
                    <mat-error *ngIf="saveModelForm.controls.scoringId.hasError('required')">scoringId is
                      required</mat-error>
                  </div>
                </div>

                <div style="margin-right: 20px;" class="flex flex-col basis-[19%]">
                  <div class="flex flex-row">
                    <span style="color:red">*</span><span class="label">Country</span>
                  </div>
                  <mat-form-field>
                    <mat-select formControlName="country" ngDefaultControl [disabled]="isDisableEditForSource">
                      <mat-option *ngFor="let data of countryList" [value]="data.value">{{data?.label}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="flex flex-col basis-[23%]">
                  <div class="flex flex-row">
                    <span style="color:red">*</span><span class="label">Drug Classification Type</span>
                  </div>
                  <mat-form-field>
                    <mat-select formControlName="drugClassificationType" ngDefaultControl
                      [disabled]="isDisableEditForSource">
                      <mat-option *ngFor="let data of listOfDrugClassificationType"
                        [value]="data.value">{{data?.label}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <hr />
              <h5>Package</h5>
              <div style="padding-top:10px;padding-left: 1rem;" class="flex flex-row gap-5">
                <div class="flex flex-col basis-1/2">
                  <span class="label">Barcode</span>
                  <input type="text" formControlName="packageBarcode" value="" />
                </div>
                <div class="flex flex-col basis-1/2">
                  <span class="label">2 D Barcodes</span>
                  <input type="text" formControlName="packageBarcode2d" value="" />
                </div>

              </div>
              <div style="padding-top:10px;padding-left: 1rem;" class="flex flex-row gap-5">
                <div class="flex flex-col basis-[15%]">
                  <div class="flex flex-row">
                    <span style="color:red">*</span><span class="label">Capacity</span>
                  </div>
                  <input type="text" formControlName="packageSize" value="" />
                  <div
                    *ngIf="saveModelForm.controls.packageSize.hasError('required') && saveModelForm.controls.packageSize.touched">
                    <mat-error *ngIf="saveModelForm.controls.packageSize.hasError('required')">Capacity is
                      required</mat-error>
                  </div>
                </div>
                <div class="flex flex-col basis-1/5">
                  <div class="flex flex-row">
                    <span style="color:red">*</span> <span class="label">Package Type</span>
                  </div>

                  <mat-form-field>
                    <input type="text" aria-label="Number" matInput [formControl]="pacakgeTypeId"
                      [matAutocomplete]="autoPcakgeType">
                    <mat-autocomplete #autoPcakgeType="matAutocomplete" [displayWith]="displayFnPacakgeType.bind(this)">
                      <mat-option *ngFor="let data of listOfPackageType" [value]="data.value">
                        {{data.label}}
                      </mat-option>
                      <mat-option *ngIf="isLoading" class="is-loading">
                        <mat-spinner diameter="25"></mat-spinner>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                </div>

                <div class="flex flex-col basis-[15%]">
                  <span class="label">Width</span>
                  <input type="number" formControlName="packageWidth" value="" />

                </div>
                <div class="flex flex-col basis-[15%]">
                  <span class="label">Height</span>
                  <input type="number" formControlName="packageHeight" value="" />

                </div>
                <div class="flex flex-col basis-[15%]">
                  <span class="label">Depth</span>
                  <input type="number" formControlName="packageDepth" value="" />

                </div>


              </div>

              <div style="padding-top:10px;padding-left: 1rem;" class="flex flex-row">
                <h5 style="margin-right: 1rem;">Inventory</h5>
                <div style="margin-top:2rem" class="flex gap-5">
                  <button class="btn-add-new" (click)="openImportWizardInventory(1)">Add New</button>
                  <button [ngClass]="{'btn-add-new':inventoryId!==0, 'btn-disabled':inventoryId===0}"
                    class="btn-add-new btn btn-sm" [disabled]="inventoryId===0"
                    (click)="openImportWizardInventory(2)">Destroy</button>
                  <button class="btn-add-new" (click)="openImportWizardInventory(3)">History</button>
                </div>

              </div>
              <div *ngFor="let inventory of inventoryList">
                <div style="border-bottom:1px solid gray" *ngIf="!inventory.isDestroyed" class="flex flex-col">
                  <div class="flex flex-row">
                    <div class="flex flex-col basis-[10%]">
                      <span class="label">Action</span>
                      <mat-radio-button style="padding: 0.5rem 0rem 0rem 1rem" value="{{inventory.id}}"
                        [disabled]="inventory.isDestroyed" (change)="inventorySelect(inventory.id, $event.ehecked)">
                      </mat-radio-button>
                    </div>
                    <div class="flex flex-col basis-[23%]">
                      <span class="label">Inventory Number</span>
                      <span class="label-value">{{inventory.inventoryNumber}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Inventory</span>
                      <span class="label-value">{{inventory.inventoryQuantity}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Source</span>
                      <span class="label-value">{{inventory.source}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Added</span>
                      <span class="label-value">{{inventory.addedDate}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Owner</span>
                      <span class="label-value">{{inventory.owner}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Last Changed</span>
                      <span class="label-value">{{inventory.lastChangedDate}}</span>
                    </div>
                  </div>
                  <div class="flex flex-row">
                  </div>

                  <div class="flex flex-col basis-1/5">
                    <span class="label"> Inventory Comments</span>
                    <span class="label-value">{{inventory.comments}}</span>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="drug-right-side">
          <div>
            <table mat-table [dataSource]="dataSourceDevice" class="mat-elevation-z8" multiTemplateDataRows>
              <ng-container matColumnDef="deviceName">
                <th mat-header-cell *matHeaderCellDef class="w-100">Device Name</th>
                <td mat-cell *matCellDef="let element" class="w-100"><span style="font-weight: bold;">
                    <span *ngIf="element.isExpanded">
                      <mat-icon class="cross-wizard">
                        <img src="/assets/img/icons/ico.16.arwup.svg" alt="">
                      </mat-icon>
                    </span>
                    <span *ngIf="!element.isExpanded">
                      <mat-icon class="cross-wizard">
                        <img src="/assets/img/icons/ico.16.arwdown.svg" alt="">
                      </mat-icon>
                    </span>
                    {{element.deviceName}}</span> </td>
              </ng-container>

              <ng-container matColumnDef="drugStatus">
                <th mat-header-cell *matHeaderCellDef class="w-100">Drug Status</th>
                <td mat-cell *matCellDef="let element" class="w-100">
                  <!-- <button *ngIf="element.drugStatus" [style.background-color]="element.drugRecordColor"    class="btn-status"> {{element.drugStatus}}</button>
            </td> -->
              </ng-container>

              <ng-container matColumnDef="deviceStatus">
                <th mat-header-cell *matHeaderCellDef class="w-100">Device Status</th>
                <td mat-cell *matCellDef="let element" class="w-100">
                  <button *ngIf="element.deviceStatus" [style.background-color]="element.deviceStatusColor"
                    class="btn-status"> {{element.deviceStatus}}</button>
                </td>
              </ng-container>

              <ng-container matColumnDef="labOptimizedStatus">
                <th mat-header-cell *matHeaderCellDef class="w-100">lab status</th>
                <td mat-cell *matCellDef="let element" class="w-100">
                  <span class="lab-status">Lab Optimized
                    <span>{{element.labOptimizedStatus}}</span> </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="4">
                  <div class="row queue-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                    <div *ngIf="element.deviceName==='ATP'" style=" padding-bottom: .8rem;">
                      <div class="flex flex-col"
                        style="border-bottom: 1px solid rgb(127, 116, 116); padding-bottom: .8rem;">
                        <h5>Attributes</h5>

                        <div class="row row-info flex gap-[1rem]" style="border-bottom: 1px solid rgb(127, 116, 116);">

                          <div class="flex flex-col basis-[18%]">
                            <span class="label">TCG Pill Type</span>
                            <mat-form-field>
                              <mat-select formControlName="atpTcgPillType">
                                <mat-option *ngFor="let data of listOfPillType" [value]="data.label">{{data?.label}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Drug Status</span>
                            <mat-form-field>
                              <mat-select formControlName="atpDrugStatus" ngDefaultControl>
                                <mat-option *ngFor="let data of listOfATPDrugStatus" [value]="data.label">
                                  {{data?.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lab Optimize</span>
                            <mat-form-field>
                              <mat-select formControlName="atpLabOptimized" ngDefaultControl>
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                        </div>
                        <h5>Canister</h5>
                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Canister Number</span>
                            <input type="text" value="" formControlName="atpCanisterNumber" />
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <button mat-button class="btn-blue-light btn-sm" style="margin-top:1.5rem;"> Find
                              New</button>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Canister Capacity</span>
                            <!-- <span class="label-value">{{atpModel.canisterCapacity }}</span> -->
                            <input type="number" value="" formControlName="canisterCapacity" />

                          </div>
                          <div class="flex flex-col basis-[17%]">
                            <span class="label">High Cap Canister</span>
                            <!-- <span class="label-value">{{atpModel.highCapCanister }}</span> -->
                            <input type="number" value="" formControlName="highCapCapacity" />
                          </div>

                        </div>

                      </div>
                    </div>
                    <div *ngIf="element.deviceName==='PASS'" style=" padding-bottom: .8rem;">
                      <div class="flex flex-col"
                        style="border-bottom: 1px solid rgb(127, 116, 116);  padding-bottom: .8rem;">
                        <h5>Attributes</h5>

                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-1/5">
                            <span class="label">Drug Status</span>
                            <mat-form-field>
                              <mat-select [disabled]="isRecommededShow" formControlName="passDrugStatus"
                                ngDefaultControl>
                                <mat-option *ngFor="let data of listOfPASSDrugStatus" [value]="data.label">
                                  {{data?.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">DNU Reason</span>
                            <span class="label-value">{{this.passModel.dnuReason}}</span>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lab Optimize</span>
                            <mat-form-field>
                              <mat-select formControlName="passLabOptimized" ngDefaultControl>
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row row-info flex gap-[1rem]">
                          <mat-radio-group [disabled]="isRecommededShow" formControlName="recommendationType">
                            <mat-radio-button class="basis-[23%]" value="1"
                              [checked]="saveModelForm.value.recommendationType=='1'"> Canister ID</mat-radio-button>
                            <mat-radio-button class="basis-[23%]" value="2"
                              [checked]="saveModelForm.value.recommendationType=='2'"> Snap Canister ID
                            </mat-radio-button>
                          </mat-radio-group>

                        </div>

                        <div class="row flex gap-[1rem]">
                          <div class="flex flex-col basis-[23%]">
                            <span class="label-fixed-value">{{this.passModel.canisterId}}</span>
                          </div>
                          <div class="flex flex-col basis-[23%]">
                            <span class="label-fixed-value">{{this.passModel.snapCanisterId}}</span>
                          </div>

                          <div class="flex justify-center content-center items-center flex-col basis-[10%]">
                            <button mat-button class="btn-primary btn-sm" style="margin-top: 1rem;"
                              *ngIf="!isRecommededShow" (click)="getRecommendedDrug();"> Recommend New</button>
                          </div>
                        </div>

                      </div>
                      <div *ngIf="isRecommededShow" class="flex flex-col">
                        <h5 style="padding: 20px 0px 20px 0px;">Canister Recommendations</h5>
                        <table mat-table #outerSort="matSort" [dataSource]="dataSourceRecommendationDrug"
                          multiTemplateDataRows class="mat-elevation-z8 main-table" matSort>
                          <ng-container matColumnDef="isChecked">
                            <th mat-header-cell *matHeaderCellDef width="5px"> </th>
                            <td mat-cell *matCellDef="let element" width="5px">
                              <mat-radio-button (click)="canisterChecked(element, $event.checked)"></mat-radio-button>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="canisterRecipe">
                            <th mat-header-cell *matHeaderCellDef width="10px"> Canister Recipe </th>
                            <td mat-cell *matCellDef="let element" width="10px"><span style="font-weight: bold;">
                                <span *ngIf="expandedElement">
                                  <mat-icon class="cross-wizard">
                                    <img src="/assets/img/icons/ico.16.arwup.svg" alt="">
                                  </mat-icon>
                                </span>
                                <span *ngIf="!expandedElement">
                                  <mat-icon class="cross-wizard">
                                    <img src="/assets/img/icons/ico.16.arwdown.svg" alt="">
                                  </mat-icon>
                                </span>
                                {{element.canisterRecipe}}</span>

                            </td>
                          </ng-container>

                          <ng-container matColumnDef="matchLevel">
                            <th mat-header-cell *matHeaderCellDef width="10px"> Match Level </th>
                            <td mat-cell *matCellDef="let element" width="10px"> {{element.matchLevel}} </td>
                          </ng-container>
                          <ng-container matColumnDef="medName">
                            <th mat-header-cell *matHeaderCellDef width="10px"> Med Name(Drug Nummber) </th>
                            <td mat-cell *matCellDef="let element" width="10px"> {{element.medName}} </td>
                          </ng-container>

                          <ng-container matColumnDef="dItem">
                            <th mat-header-cell *matHeaderCellDef width="10px"> DItem # </th>
                            <td mat-cell *matCellDef="let element" width="10px"> {{element.dItem}} </td>
                          </ng-container>
                          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                          <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                              <div class="example-element-detail" *ngIf="element.canisters.data"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                                  <div *ngFor="let item of element.canisters.data">

                                    <div class="row row-info flex gap-[1rem]">
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Pill Type</span>
                                        <span class="label-value">{{item.pillType}}</span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Length</span>
                                        <span class="label-value"
                                          *ngIf="item.length!==this.saveModelForm.value.passLength">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span style="color:red">{{item.length}} </span>
                                        </span>
                                        <span class="label-value"
                                          *ngIf="item.length===this.saveModelForm.value.passLength">{{item.length}}
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Width</span>
                                        <span class="label-value"
                                          *ngIf="item.width!==this.saveModelForm.value.passWidth">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span style="color:red">{{item.width}} </span>
                                        </span>

                                        <span class="label-value"
                                          *ngIf="item.width===this.saveModelForm.value.passWidth">{{item.width}}
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Thickness</span>
                                        <span class="label-value"
                                          *ngIf="item.thickness!==this.saveModelForm.value.passThickness">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span style="color:red">{{item.thickness}} </span>
                                        </span>
                                        <span class="label-value"
                                          *ngIf="item.thickness===this.saveModelForm.value.passThickness">{{item.thickness}}
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label"> Piece Weight</span>

                                        <span class="label-value"
                                          *ngIf="item.weight!==this.saveModelForm.value.passPieceWeight">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span style="color:red">{{item.weight}} </span>
                                        </span>
                                        <span class="label-value"
                                          *ngIf="item.weight===this.saveModelForm.value.passPieceWeight">{{item.weight}}
                                        </span>
                                      </div>

                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label"> Diagonal</span>

                                        <span class="label-value"
                                          *ngIf="item.diagonal!==this.saveModelForm.value.passDiagonal">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span style="color:red">{{item.diagonal}} </span>
                                        </span>
                                        <span class="label-value"
                                          *ngIf="item.diagonal===this.saveModelForm.value.passDiagonal">{{item.diagonal}}
                                        </span>
                                      </div>
                                    </div>
                                    <!--Snap Canister Id-->
                                    <div class="row row-info flex gap-[1rem]"
                                      *ngIf="saveModelForm.value.recommendationType=='2'">
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Snap Body</span>
                                        <span class="label-value">{{item.snapBody}}</span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">SnapDiv Block</span>
                                        <span class="label-value">{{item.snapDivBlock}} </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">SnapGuid Block</span>
                                        <span class="label-value">{{item.snapGuideBlock}} </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">SnapBrush Guide</span>
                                        <span class="label-value">{{item.snapBrushBlock}} </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Snap Brush</span>
                                        <span class="label-value">{{item.snapBrush}} </span>
                                      </div>
                                    </div>

                                    <!--Canister ID-->
                                    <div class="row row-info flex gap-[1rem]"
                                      *ngIf="saveModelForm.value.recommendationType=='1'">
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Guid Block</span>
                                        <span class="label-value">{{item.guideBlock}}</span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Division Block</span>
                                        <span class="label-value">{{item.divisionBlock}} </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Brush ID</span>
                                        <span class="label-value">{{item.brushId}} </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">GelPad ID</span>
                                        <span class="label-value">{{item.gelPadId}} </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label"> Handle ID</span>
                                        <span class="label-value">{{item.handleIdId}} </span>
                                      </div>

                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label"> Slot Location</span>
                                        <span class="label-value">{{item.slotLocation}} </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                            [class.example-element-row]="element.canisters"
                            [class.example-expanded-row]="expandedElement === element" (click)="toggleRow(element)">
                          </tr>

                          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row">
                          </tr>

                        </table>

                        <div
                          class="recommended-bottom flex flex-row justify-between content-stretch items-stretch w-full"
                          style="padding: 19px 0px 18px 0px;">
                          <div>
                            <span href="#">Show More ></span>
                          </div>
                          <div>
                            <span (click)="cancelSletectedCanisterId()"
                              style="color:red; padding-right:1rem;">Cancel</span>
                            <span (click)="saveCanister()">Assign Selected Canister ></span>
                          </div>

                        </div>

                      </div>
                    </div>
                    <div *ngIf="element.deviceName==='MAX'" style="padding-bottom: .8rem;">
                      <div class="flex flex-col">
                        <h5>Attributes</h5>

                        <div class="row row-info flex gap-[1rem]" style="border-bottom: 1px solid rgb(127, 116, 116);">
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">13 Dram DNU</span>
                            <mat-form-field>
                              <mat-select formControlName="maxThirtyDramDnu">
                                <mat-option [value]="1">Yes</mat-option>
                                <mat-option [value]="0">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Do Not Use?</span>
                            <mat-form-field>
                              <mat-select formControlName="maxDoNotUse">
                                <mat-option [value]="1">Yes</mat-option>
                                <mat-option [value]="0">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Drug Status</span>
                            <mat-form-field>
                              <mat-select formControlName="maxDrugStatus" ngDefaultControl>
                                <mat-option *ngFor="let data of listOfMAXDrugStatus" [value]="data.label">
                                  {{data?.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lab Optimize</span>
                            <mat-form-field>
                              <mat-select formControlName="maxLabOptimized" ngDefaultControl>
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <h5>Cell Settings</h5>

                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Cell Height</span>
                            <input type="number" step="0.25" min="1.00" max="4.75" formControlName="maxCellHeight"
                              (change)="AttributeValidation('maxCellHeight', $event)">
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Max Cell Width</span>
                            <input type="text" formControlName="maxCellWidth"
                              (change)="AttributeValidation('maxCellWidth', $event)">
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Baffle</span>
                            <input type="number" onkeypress="return !(event.charCode == 46)" min="1" max="4" step="1"
                              formControlName="maxBaffle" (change)="AttributeValidation('maxBaffle', $event)">
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Pressure</span>
                            <input type="number" onkeypress="return !(event.charCode == 46)" step="5" min="15" max="60"
                              formControlName="maxPressure" (change)="AttributeValidation('maxPressure', $event)">
                          </div>

                        </div>
                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-[17%]">
                            <span class="label">Cell Capacity</span>
                            <input type="text" value="" formControlName="cellCapacity" />

                          </div>
                          <div class="flex flex-col basis-[17%]">
                            <span class="label">Super Cell Capacity</span>
                            <input type="text" value="" formControlName="superCellCapacity" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="element.deviceName==='SYNMED'">
                      <div class="flex flex-col" style="border-bottom: 1px solid rgb(127, 116, 116);">
                        <h5>Attributes</h5>

                        <div class="row row-info flex gap-[1rem]">

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Drug Status</span>
                            <mat-form-field>
                              <mat-select formControlName="synmedDrugStatus" ngDefaultControl>
                                <mat-option *ngFor="let data of listOfSynmedDrugStatus"
                                  [value]="data.label">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lab Optimize</span>
                            <mat-form-field>
                              <mat-select formControlName="synmedLabOptimized" ngDefaultControl>
                                <mat-option *ngFor="let data of synmedLabOptimizeds"
                                  [value]="data.value">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Fragile Flag</span>
                            <mat-form-field>
                              <mat-select formControlName="fragileFlag" ngDefaultControl>
                                <mat-option *ngFor="let data of fragileFlags"
                                  [value]="data.value">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">DNU Reason</span>
                            <mat-form-field>
                              <mat-select formControlName="nonCountReasonId" ngDefaultControl>
                                <mat-option *ngFor="let data of listOfDnuReason"
                                  [value]="data.value">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <h5>Settings</h5>
                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-[25%]">
                            <span class="label">Preferred Pipette Size</span>
                            <mat-form-field>
                              <mat-select formControlName="preferredPipetteSize" ngDefaultControl>
                                <mat-option *ngFor="let value of preferredPipetteSize"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lid Hole</span>
                            <mat-form-field>
                              <mat-select formControlName="lidHole" ngDefaultControl>
                                <mat-option *ngFor="let value of preferredLidHoleSize"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <!-- <div class="flex flex-col basis-[20%]">
                            <span class="label">Large Container Capacity</span>
                            <input type="number" onkeypress="return !(event.charCode == 46)" min="0" 
                            formControlName="largeCanisterCapacity">
                          </div> -->
                          <div class="flex flex-col basis-[20%]">
                            <span class="label">Lrg Pipette Drop Height</span>
                            <mat-form-field>
                              <mat-select formControlName="largePipetteDropHeight" ngDefaultControl>
                                <mat-option *ngFor="let value of largePipetteDropHeight"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Mass Index</span>
                            <mat-form-field>
                              <mat-select formControlName="massIndex" ngDefaultControl>
                                <mat-option *ngFor="let value of massIndex" [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row row-info flex gap-[1rem]">

                          <div class="flex flex-col basis-[25%]">
                            <span class="label">Preffered Container Size</span>
                            <mat-form-field>
                              <mat-select formControlName="preferredContainerSize" ngDefaultControl>
                                <mat-option *ngFor="let value of preferredContainerSize"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Preffered Lid Hole</span>
                            <mat-form-field>
                              <mat-select formControlName="preferredLidHoleSize" ngDefaultControl>
                                <mat-option *ngFor="let value of preferredLidHoleSize"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <!-- <div class="flex flex-col basis-[20%]">
                            <span class="label">Small Container Capacity</span>
                            <input type="number" onkeypress="return !(event.charCode == 46)" min="0" 
                            formControlName="smallCanisterCapacity">
                          </div> -->
                          <div class="flex flex-col basis-[20%]">
                            <span class="label">Sm Pipette Drop Height</span>
                            <mat-form-field>
                              <mat-select formControlName="smallPipetteDropHeight" ngDefaultControl>
                                <mat-option *ngFor="let value of smallPipetteDropHeight"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Toggle</span>
                            <mat-form-field>
                              <mat-select formControlName="toggle" ngDefaultControl>
                                <mat-option *ngFor="let data of toggleOptions"
                                  [value]="data.value">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="element.deviceName==='BEACON'">
                      <span>Coming soon....</span>
                    </div>
                  </div>
                </td>
              </ng-container>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="queue-element-row"
                [class.queue-expanded-row]="element.isExpanded" (click)="element.isExpanded = !element.isExpanded;">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="queue-detail-row"></tr>
            </table>
          </div>
        </div>


      </div>
    </form>
    <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full xs:flex xs:flex-col">
      <button mat-button class="btn-blue-light btn-md" (click)="closeDialog(true)" id="CancelNewUser"
        aria-label="Cancel" type="button">Cancel</button>
      <button mat-button aria-label="Done" (click)="updateDrug()"
        [ngClass]="{'btn-primary':saveModelForm?.valid, 'btn-disabled':!saveModelForm?.valid || isRecommededShow}"
        [disabled]="!saveModelForm?.valid" class="btn-primary btn-md" type="button">Done
      </button>
    </mat-dialog-actions>
  </div>
</div>