import { Component, Input } from '@angular/core';
import { InputType, UserInputPropertySource } from '../../models/deploymentForm.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dynamic-deployment-control',
  templateUrl: './dynamic-deployment-control.component.html',
  styleUrls: ['./dynamic-deployment-control.component.scss']
})
export class DynamicDeploymentControlComponent {
  @Input() organizationId: string;
  @Input() controlMetadata: UserInputPropertySource;
  @Input() form: FormGroup;

  // Expose enum type to HTML template
  public get InputType(): typeof InputType { return InputType; }

  get formControl(): FormControl { return this.form.get(this.controlMetadata.name) as FormControl; }

  get required(): boolean { return this.formControl.hasValidator(Validators.required); }
}
