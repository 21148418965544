import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Organization } from 'src/app/internal-user/customer-management/models/organization';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, startWith, Subscription, switchMap } from 'rxjs';
import { selectSitesByParentCustomerNo } from 'src/app/store/selectors/organizations.selector';

@Component({
  selector: 'app-site-select',
  templateUrl: './site-select.component.html',
  styleUrls: ['./site-select.component.scss']
})
export class SiteSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() organizationId: string;
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() disabled: boolean;

  constructor(private store: Store<State>) {
    this.parentOrgSubject = new BehaviorSubject<string>('');
  }

  parentOrgSubject: BehaviorSubject<string>;
  siteSubscription: Subscription;
  sites$: Observable<Organization[]>;
  sites: Organization[];
  filteredSites$: Observable<Organization[]>;
  initialized: boolean = false;

  get siteSelectControl() { return this.form.get(this.controlName) as FormControl<string>; }

  ngOnInit(): void {
    this.siteSelectControl.addValidators(this.validateSite().bind(this));

    this.sites$ = this.parentOrgSubject.asObservable().pipe(
      switchMap(orgId => this.store.select(selectSitesByParentCustomerNo(orgId))),
      shareReplay(1)
    );

    this.filteredSites$ = combineLatest([
      this.siteSelectControl.valueChanges.pipe(startWith('')),
      this.sites$
    ]).pipe(
      map(([controlValue, sites]) => sites.filter(site => {
        if (!site.customerNo) {
          return false;
        }

        if (site.customerNo?.toLowerCase()?.includes(controlValue.toLowerCase())) {
          return true;
        }
        if (site.name.toLowerCase().includes(controlValue.toLowerCase())) {
          return true;
        }
        return false;
      }))
    );

    this.siteSubscription = this.sites$.subscribe(sites => {
      this.sites = sites;
      this.siteSelectControl.setValue(this.siteSelectControl.value, { emitEvent: false });
    });

    if (this.organizationId) {
      this.parentOrgSubject.next(this.organizationId);
    }

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['organizationId'] && (changes['organizationId'].currentValue !== changes['organizationId'].previousValue)) {
      if (this.initialized) {
        this.siteSelectControl.setValue('');
        this.parentOrgSubject.next(changes['organizationId'].currentValue || '');
      }
    }

    if (changes['disabled']) {
      this.disabled ? this.siteSelectControl.disable() : this.siteSelectControl.enable();
    }
  }

  ngOnDestroy(): void {
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
  }

  displaySite(siteId: string): string {
    return this?.sites?.find(site => site.customerNo === siteId)?.name || siteId;
  }

  validateSite(): ValidatorFn {
    return (control: AbstractControl<string, string>): ValidationErrors | null => {
      const siteControlValue = control.value;

      if (!siteControlValue) {
        return null;
      }

      if (!this.sites || this.sites.length === 0) {
        return { NoOptionsError: true };
      }

      if (!this.sites.find(site => site.customerNo === siteControlValue)) {
        return { InvalidOptionError: true };
      }

      return null;
    }
  }
}
